import { createApp, App, h } from 'vue'
import { SToast } from '@shein-aidc/sui-toast/mobile'
import CustomerDrawerVue from './index.vue'

let CustomerDrawerInstance: App | undefined


function createCustomerDrawer(dom, customerInfo, close, resolve) {
  CustomerDrawerInstance = createApp({
    render() {
      return h(CustomerDrawerVue, {
          show: true,
          ...customerInfo,
          onClose: close,
          onConfirm: (data) => {
            CustomerDrawerInstance?.unmount?.()
            CustomerDrawerInstance = undefined
            resolve(data)
          },
          onError: (e) => {
          console.error(e)
          close()
          SToast({
            content: customerInfo?.errorTip,
            duration: 3000,
          })
        },
      })
    },
  })

  CustomerDrawerInstance.mount(dom)
}
const customerMade = async (customerInfo: {
  link: string;
  errorTip?: string;
}) => {
  return new Promise((resolve) => {
    // 挂在实例
    const dom = document.createElement('div')
    document.body.appendChild(dom)

    const close = () => {
      CustomerDrawerInstance?.unmount()
      CustomerDrawerInstance = undefined
      resolve(undefined)
    }
    createCustomerDrawer(dom, customerInfo, close, resolve)

  })
}

export default customerMade
