<template>
  <div class="quickPricePrefix">
    <p>{{ value }}</p>
  </div>
</template>

<script setup name="PricePrefix">
/**
 * @file 主价格 前缀
 */
const props = defineProps({
  value: {
    type: String,
    default: '',
  },
})
</script>

<style lang="less">
.quickPricePrefix {
  font-size: 0.293rem;
  line-height: 1;
  font-weight: 700;
}
</style>
