<template>
  <div
    v-if="source && source?.length > 0"
    :class="{ 'quick-atmosphere-flow': true, transparent: !show }"
    :style="{ bottom: `${positionBottom}px`, zIndex: quickAtomsZIndex, transform: `translateZ(${quickAtomsZIndex}px)` }"
  >
    <div
      v-if="!firstIsPaused"
      key="paly_one"
      :style="{ 'animation-play-state': firstIsPaused ? 'paused' : 'running' }"
      class="tag-item"
      :class="{ 'tag-item_white': showWhiteStyle }"
      @animationiteration="firstPlay"
    >
      <span>{{ source[currentIndex]?.content }} </span>
    </div>
    <div
      v-if="!secondIsPaused"
      key="paly_two"
      :style="{ 'animation-play-state': secondIsPaused ? 'paused' : 'running' }"
      class="tag-item"
      :class="{ 'tag-item_white': showWhiteStyle }"
      @animationiteration="secondPlay"
    >
      <span>{{ source[currentNextIndex]?.content }}</span>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { ref, onBeforeUnmount, watch, computed, toRefs, inject } from 'vue'
import { BQuickAtmosphereFlowProps } from './types'
import { AS_QuickAddCartStandard } from '@shein-aidc/bs-quick-add-cart-standard-mobile'
const props = defineProps(BQuickAtmosphereFlowProps)
const { productInfo, autoPlay, location, fsAbt, hotNews, willSoldOutTipsInfo } = toRefs(props)
const analysisInstance = inject<AS_QuickAddCartStandard.AnalysisInstance>('analysisInstance')
const REPORTED_LABELS = new Set()
const source = ref<any[]>([])
const displayInfoLabel = ref<any[]>([])
const positionBottom = ref(0)
const quickAtomsZIndex = ref(199909)
const currentIndex = ref(0)
const currentNextIndex = ref(1)
const playTimer = ref<any>(null)
const playNextTimer = ref<any>(null)
const firstIsPaused = ref(false)
const secondIsPaused = ref(true)
const maxLength = computed(() => source.value.length || 0)
const showWhiteStyle = computed(() => {
  const { toastcolor } = fsAbt.value?.hotnews?.param || {}
  return toastcolor === 'white'
})
// 是否展示，控制透明度，影响数据
const show = computed(() => {
  return fsAbt.value?.hotnews?.param?.quickaddtobag == 'on' && !forbidAtmosphereFlow.value
})
const forbidAtmosphereFlow = computed(() => {
  const Displaytiming = fsAbt.value?.Displaytiming?.param?.Displaytiming || 0
  if (Displaytiming && localStorage?.getItem('goods_detail_page_view')) {
    let pageView = JSON.parse(localStorage?.getItem('goods_detail_page_view') as string)?.value || 0
    // 用户24小时内访问商详次数大于等于X次，返回false，展示人气氛围
    return Displaytiming > pageView
  }
  return false
})
// 人气氛围配置
const atmosphereInfo = computed(() => {
  const userBehaviorSoldOutIndex = hotNews.value.findIndex((item: any) => Number(item.lowStockType))
  if (userBehaviorSoldOutIndex > -1 && Number(willSoldOutTipsInfo.value?.lowStockType && willSoldOutTipsInfo.value.tip)) {
    Object.assign(hotNews.value[userBehaviorSoldOutIndex], {
      content: willSoldOutTipsInfo.value.tip,
      label_type: 1,
    })
  }
  return hotNews.value || []
})
watch(
  () => productInfo.value,
  (newVal, oldVal) => {
    if (newVal?.goods_id && newVal?.goods_id !== oldVal?.goods_id) {
      displayInfoLabel.value = []
      setTimeout(() => {
        refreshAtmosphereData()
        refreshAtmosphereHeight()
        quickAtomsZIndex.value = document.body.dataset.zIndex
      }, 100)
    }
  },
  {
    immediate: true,
  },
)
// 拉取氛围信息
const refreshAtmosphereData = () => {
  // 默认不展示
  source.value = []

  try {
    if (Array.isArray(atmosphereInfo.value) && atmosphereInfo.value?.length) {
      source.value = atmosphereInfo.value
      // 开始播放
      if (autoPlay.value) {
        clearTimeout(playTimer.value)
        clearTimeout(playNextTimer.value)
        play()
        return
      }
    }
  } catch (err) {
    //
  }
}
const refreshAtmosphereHeight = () => {
  if (!source.value?.length) {
    return
  }
  const quickDom = document.querySelector('.bsc-quick-add-cart-standard-drawer') as HTMLElement
  const quickHeight = quickDom?.offsetHeight || 0
  if (quickHeight > 0) {
    positionBottom.value = quickHeight + 18
  }
}
const play = () => {
  currentIndex.value = 0
  playExpose(0)
  secondIsPaused.value = true
  firstIsPaused.value = false
  if (maxLength.value > 1) {
    playTimer.value = setTimeout(() => {
      currentNextIndex.value = 1
      playExpose(1)
      secondIsPaused.value = false
    }, 2500)
  }
}

const playExpose = (index: number) => {
  // 真曝光，不显示不曝光
  if (!show.value) return

  if (index > maxLength.value - 1) return
  // 当前 tag
  const { labelId, label_type } = source.value[index] || {}
  // --
  if (!source.value[index] || !labelId || REPORTED_LABELS.has(labelId)) {
    return
  }
  analysisInstance?.triggerNotice({
    id: 'expose_scroll_popup.comp_quick-add-cart-standard',
    data: {
      label_id: labelId,
      location: location.value,
      label_type,
    },
  })
  REPORTED_LABELS.add(labelId)
}

const firstPlay = () => {
  if (currentIndex.value < maxLength.value - 2) {
    currentIndex.value = currentIndex.value + 2
    playExpose(currentIndex.value)
  } else {
    firstIsPaused.value = true
    if (currentIndex.value === maxLength.value - 1) {
      playNextTimer.value = setTimeout(() => {
        play()
      }, 3000)
    }
  }
}

const secondPlay = () => {
  if (currentNextIndex.value + 2 < maxLength.value) {
    currentNextIndex.value = currentNextIndex.value + 2
    playExpose(currentNextIndex.value)
  } else {
    secondIsPaused.value = true
    if (currentNextIndex.value === maxLength.value - 1) {
      playNextTimer.value = setTimeout(() => {
        play()
      }, 3000)
    }
  }
}

onBeforeUnmount(() => {
  clearTimeout(playTimer.value)
  clearTimeout(playNextTimer.value)
  firstIsPaused.value = true
  secondIsPaused.value = true
})

defineExpose({
  refreshAtmosphereHeight,
})
</script>

<style lang="less" scoped>
.quick-atmosphere-flow {
  position: fixed;
  left: 0;
  width: 100%;
  height: 0;
  overflow: visible;
  opacity: 1;
  transition: opacity 0.1s;
  &.transparent {
    opacity: 0;
  }
  .tag-item {
    display: inline-block;
    height: 0.586667rem;
    max-width: calc(100% - 0.64rem);
    border-radius: 0.72rem;
    background-color: rgba(0, 0, 0, 0.7);
    padding: 0 0.213333rem;
    position: absolute;
    left: 0.32rem;
    top: 0rem;
    opacity: 0;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
    animation-name: scroll;
    animation-duration: 5.5s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    span {
      display: inline-block;
      max-width: 100%;
      font-size: 0.293333rem;
      line-height: 0.586667rem;
      color: #fff;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    &_white {
      background-color: rgba(255, 255, 255, 0.94);
      span {
        color: #000;
      }
    }
  }
}
@keyframes scroll {
  0% {
    opacity: 0;
    transform: translateY(-0px);
  }
  9% {
    opacity: 1;
    transform: translateY(-0.85rem);
  }
  45% {
    opacity: 1;
    transform: translateY(-0.85rem);
  }
  54% {
    opacity: 0;
    transform: translateY(-1.7rem);
  }
  100% {
    opacity: 0;
    transform: translateY(-1.7rem);
  }
}
</style>
