<template>
  <div
    class="bsc-size-table"
    :class="{'default-table': !sizeGuidePlanA}">
    <CommonSizeTable
      v-show="tableData.length"
      v-expose="{
        id: 'expose_size_guide_fit_well.comp_size-guide',
        data: {
          fit_data: comment_sizes?.length ? 1 : 0,
        },
        once: false,
      }"
      :is-sticky-first-col="sizeGuidePlanA"
      :need-empty-placeholder="false"
      :title="tableTitle"
      :tabs="tabs"
      :hidden-table-column-index="hiddenTableColumnIndex"
      :tableHeader="tableHeader"
      :tableData="tableData"
      :activeTab="currentTab"
      @tab-change="handleTabChange"
    >
      <template #desc>
        <ul
          v-if="multiPartInfo.length > 1"
          v-show="currentTab == 'Product'"
          class="bsc-multi-part-tab">
          <li
            v-for="item in multiPartInfo"
            :key="item.multiPartCode"
            :class="{ 'part-active': currentMultiPartCode === item.multiPartCode }"
            @click="userSelectedMultiPartCode = item.multiPartCode"
          >
            {{ item.multiPartName }}
          </li>
        </ul>
      </template>
      <template #table-bottom>
        <div class="bsc-size-table__notice">
          {{ sizeTipsText }}
        </div>
        <div
          v-show="isJapan"
          class="bsc-size-table__notice">
          {{ language.SHEIN_KEY_PWA_31609 }}
        </div>
        <SizeMeasureGuide
          :value="isCollapseMeasure"
          :size-template="sizeTemplate"
          :sizeInfoDesc="sizeInfoDesc"
          :tab="currentTab"
          :language="language"
        />
      </template>
    </CommonSizeTable>
  </div>
</template>

<script setup name="BSizeTable" lang="ts">
/**
 * @name BSizeTable
 * @description 普通尺码表格
 * @description 这是一个功能完备的业务组件，提供开箱即用体验。
 */
import { defineProps, ref, computed, inject, watch } from 'vue'
import { DS_SizeGuide, AS_SizeGuide } from '@shein-aidc/bs-size-guide-mobile'
import CommonSizeTable from '../CommonSizeTable.vue'
import { DS_QuickAddCartStandard } from '@shein-aidc/bs-quick-add-cart-standard-mobile'
import { useAppConfigs } from '@shein-aidc/bs-sdk-libs-manager'
import SizeMeasureGuide from './SizeMeasureGuide.vue'

const props = defineProps<{
  language: DS_SizeGuide.LANGUAGE
  localSizes?: Record<string, DS_QuickAddCartStandard.AttrValueLocalSize[] | undefined> | undefined
  country: string
  lengthUnit?: 'cm' | 'inch'
  weightUnit?: 'kg' | 'lbs'
  sizeSortMap?: Record<string, number> | undefined
  sizeInfoDesc?: DS_QuickAddCartStandard.SizeInfoDesc
  sizeTemplate?: DS_QuickAddCartStandard.SizeTemplate
  /**
   * 尺码展示优先级
   */
  sizePriorityAbt?: string
  visible: boolean
  sizeGuidePlanA?: boolean
  isCollapseMeasure: boolean
}>()

const analysisInstance = inject<AS_SizeGuide.AnalysisInstance>('analysisInstance')
const { vExpose } = analysisInstance || {}

const comment_sizes = ref<DS_SizeGuide.CommentSkuInfo[] | undefined>(undefined)
const currentTab = ref<'Product' | 'Base' | ''>('')
const userSelectedMultiPartCode = ref<string | undefined>(undefined)

/**
 * 隐藏的表格列索引集合
 * @description 用于隐藏表格中的无效列，如：'/'
 * @description 在genTableData方法中生成
 */
const hiddenTableColumnIndex = ref<number[]>([])

/**
 * 基码信息
 */
const baseSizeInfo = computed(() => {
  if (props.lengthUnit === 'cm') {
    return props.sizeInfoDesc?.basicAttribute?.base_code_info || []
  } else {
    return props.sizeInfoDesc?.basicAttribute?.base_code_info_inch || []
  }
})

/**
 * 商品尺码信息
 */
const sizeInfo = computed(() => {
  if (props.lengthUnit === 'cm') {
    return props.sizeInfoDesc?.sizeInfo
  } else {
    return props.sizeInfoDesc?.sizeInfoInch
  }
})

/**
 * 多部位尺码信息
 * @description 仅在多部位尺码时有效
 * @description 用于展示多部位尺码切换
 * @description 过滤无效的多部位尺码
 */
 const multiPartInfo = computed(() => {
  return props.sizeInfoDesc?.multiPartInfo?.filter(item => item?.dimensionAttrInfo?.length) || []
})

/**
 * 当前多部位尺码编码
 */
const currentMultiPartCode = computed(() => {
  return userSelectedMultiPartCode.value || multiPartInfo.value[0]?.multiPartCode
})

/**
 * 当前多部位尺码信息
 */
const currentMultiPartSizeInfo = computed(() => {
  const data = multiPartInfo.value.find(item => item.multiPartCode === currentMultiPartCode.value)
  if (props.lengthUnit === 'cm') {
    return data?.multiPartSizeInfo || []
  } else {
    return data?.multiPartSizeInfoInch || []
  }
})

const headName = computed(() => {
  return sizeInfo.value?.[0]?.attr_name || currentMultiPartSizeInfo.value?.[0]?.attr_name
})

/**
 * 判断基码是否完整
 */
const isBaseSizeComplete = computed(() => {
  return (
    baseSizeInfo.value.length !== 0 &&
    (baseSizeInfo.value?.length === sizeInfo.value?.length ||
      baseSizeInfo.value?.length === currentMultiPartSizeInfo.value?.length)
  )
})

/**
 * 尺码展示优先级
 * @description 0-优先展示商品尺码
 * @description 1-只展示基码
 * @description 2-只展示商品尺码
 * @description 3-优先展示基码
 * @description 4-基码和商品尺码都不展示
 */
const sizePriority = computed(() => {
  let mode = getSizePriorityMode(props.sizeInfoDesc?.basicAttribute?.base_code_show_mode, props.sizePriorityAbt)
  // 商品尺码优先
  if (mode === 0) {
    // 商品尺码＋基码，商品尺码优先，但基码缺数据则只展示商品尺码
    return isBaseSizeComplete.value ? 0 : 2
  } else if (mode === 1 || mode === 2) {
    // 只有基码或只有商品尺码
    return mode
  } else if (mode === 3) {
    // 基码＋商品尺码，基码优先，但基码缺数据则全部展示商品尺码
    return isBaseSizeComplete.value ? 3 : 2
  } else {
    // 不展示尺码信息
    return 4
  }
})

const tabs = computed(() => {
  const { language } = props
  if (sizePriority.value === 0) {
    return [
      {
        key: 'Product',
        text: language.SHEIN_KEY_PWA_17563 || 'Product Measurements',
      },
      {
        key: 'Base',
        text: language.SHEIN_KEY_PWA_17561 || 'Body Measurements',
      },
    ]
  } else if (sizePriority.value === 3) {
    return [
      {
        key: 'Base',
        text: language.SHEIN_KEY_PWA_17561 || 'Body Measurements',
      },
      {
        key: 'Product',
        text: language.SHEIN_KEY_PWA_17563 || 'Product Measurements',
      },
    ]
  } else {
    return []
  }
})

const tableTitle = computed(() => {
  if (sizePriority.value === 1) {
    return props.language?.SHEIN_KEY_PWA_17561 || 'Body Measurements'
  } else if (sizePriority.value === 2) {
    return props.language.SHEIN_KEY_PWA_17563 || 'Product Measurements'
  } else {
    return ''
  }
})

const defaultTab = computed(() => {
  if (sizePriority.value === 0 || sizePriority.value === 2) {
    return 'Product'
  } else if (sizePriority.value === 1 || sizePriority.value === 3) {
    return 'Base'
  } else {
    return ''
  }
})

watch(
  () => props.sizeInfoDesc,
  () => {
    // sizeInfoDesc 变化时，重置 tab 为默认值
    currentTab.value = defaultTab.value
    userSelectedMultiPartCode.value = undefined
  },
  { immediate: true },
)

/**
 * 获取尺码优先展示模式
 * @description pdc 的 mode 配置 0 或 3 时，且 sizePriorityAbt 不为 off 时，走abt逻辑
 * @description 0: 基码＋商品尺码，商品尺码优先
 * @description 1: 只有基码，没有商品尺码
 * @description 2: 只有商品尺码，没有基码
 * @description 3: 基码＋商品尺码，基码优先，但基码缺数据则全部展示商品尺码
 * @description 4: 不展示尺码信息
 * @param {number} mode 尺码优先展示模式（pdc配置）
 * @param {string} sizepriority 尺码优先级abt配置
 * @returns
 */
function getSizePriorityMode(mode = 0, sizePriorityAbt = 'off') {
  if ((mode === 0 || mode === 3) && sizePriorityAbt !== 'off') {
    if (sizePriorityAbt === 'product_size_first') {
      return 0
    }
    if (sizePriorityAbt === 'body_size_first') {
      return 3
    }
  }
  return mode
}

/**
 * 是否有本地尺码
 */
const hasLocalSizes = computed(() => {
  if(props.localSizes && props.country) {
    for (const key in props.localSizes) {
      if (props.localSizes[key]?.find(item => item.country_code === props.country && item.attr_local_size_value)) {
        return true
      }
    }
  }
  return false
})

const tableHeader = computed(() => {
  const { sizeInfoDesc, language } = props
  let sortKeys: string[] = []
  if (currentTab.value === 'Product') {
    let dimensionAttrInfo: DS_QuickAddCartStandard.DimensionAttributeInfo[] = []
    if (sizeInfoDesc?.multiPartFlag === '1') {
      dimensionAttrInfo =
        multiPartInfo.value?.find(item => item.multiPartCode === currentMultiPartCode.value)?.dimensionAttrInfo || []
    } else {
      dimensionAttrInfo = sizeInfoDesc?.dimensionAttrInfo || []
    }
    sortKeys = dimensionAttrInfo.map(item => {
      return item.attr_name
    })
  } else {
    let baseSizeSortMap = sizeInfoDesc?.basicAttribute?.base_attr_sort || {}
    sortKeys = Object.keys(baseSizeSortMap).sort((a, b) => baseSizeSortMap[a] - baseSizeSortMap[b])
  }

  sortKeys.unshift(headName.value || language?.SHEIN_KEY_PWA_15701 || 'Size')

  if (hasLocalSizes.value) {
    sortKeys.splice(props.sizeGuidePlanA ? 0 : 1, 0, props.country)
  }

  return sortKeys
})

const tableData = computed(() => {
  if (currentTab.value === 'Base') {
    return genTableData(baseSizeInfo.value, tableHeader.value)
  } else {
    if (props.sizeInfoDesc?.multiPartFlag === '1') {
      return genTableData(currentMultiPartSizeInfo.value, tableHeader.value, 'attr_value_name')
    } else {
      return genTableData(sizeInfo.value, tableHeader.value, 'attr_value_name')
    }
  }
})

function genTableData(dataSource: Object[] = [], tableHeader: string[], sizeKey = 'size') {
  let data: string[][] = []
  hiddenTableColumnIndex.value = []
  dataSource?.forEach(item => {
    const row: string[] = []
    tableHeader.forEach((key, index) => {
      if (index === 0) {
        row.push(item[sizeKey] || '-')
      } else if (index === 1 && hasLocalSizes.value) {
        const localSize = props.localSizes?.[row[0]]
        const size = localSize?.find(item => item.country_code === props.country)?.attr_local_size_value
        row.splice(props.sizeGuidePlanA ? 0 : 1, 0, size || '-')
      } else {
        const value = (item[key] as string)?.replace(/(cm|inch)/g, '').trim()
        if(value === '/') {
          hiddenTableColumnIndex.value.push(index)
        }
        row.push((item[key] as string)?.replace(/(cm|inch)/g, '').trim() || '-')
      }
    })
    data.push(row)
  })
  return data
}

const isJapan = computed(() => {
  return useAppConfigs().$envs.siteUID === 'mjp'
})

const sizeTipsText = computed(() => {
  return (
    '*' +
    (currentTab.value == 'Product'
      ? props.lengthUnit === 'cm'
        ? props.language?.SHEIN_KEY_PWA_17562
        : props.language?.SHEIN_KEY_PWA_33425
      : props.language?.SHEIN_KEY_PWA_17565)
  )
})

function handleTabChange(tab) {
  // da-event-click="1-6-1-46"
  const fitType = tab.key === 'true_size' ? 'true_to_size' : tab.key
  analysisInstance?.triggerNotice({
    id: 'click_size_guide_fit_well_tab.comp_size-guide',
    data: { fit_type: fitType },
  })
  currentTab.value = tab.key
}
</script>

<style lang="less">
.bsc-size-table {
  .common-size-table__top {
    &_title {
      margin-bottom: 1.5vw;
    }
    margin-bottom: 0;
    &_tabs {
      justify-content: flex-start;
      &_item_text {
        font-size: 3.73333vw;
      }
    }
  }
  &__notice {
    color: #999;
    padding: 0 0 3.2vw;
    font-size: 3.2vw;
  }
  &.default-table .common-size-table__content {
    margin: 0px;
    padding-left: 2.14vw;
    padding-bottom: 3.2vw;
    &_inner-table {
      min-width: auto;
    }
  }
  .common-size-table__content_inner-table {
    & td {
      min-width: 18.667vw;
      max-width: 37vw;
      white-space: normal;
      &:first-child {
        font-weight: 700;
      }
    }
  }
}
.bsc-multi-part-tab {
  margin: 3.2vw;
  overflow-x: scroll;

  li {
    display: inline-block;
    border: 2px solid #222;
    border-right: 0px solid #222;
    padding: 0 3.2vw;
    line-height: 7.2vw;
    font-size: 3.2vw;

    &:last-of-type {
      border-right: 2px solid #222;
    }
  }

  .part-active {
    background: #222;
    color: white;
  }
}
</style>
