<template>
  <div
    class="productSheinClubPromotionFloor"
    :style="containerStyle"
    @click="handleClick">
    <div class="productSheinClubPromotionFloor__left">
      <img
        v-if="titleImg"
        :src="titleImg.imgSrc"
        :style="titleImg.imgStyle" />
      <p
        class="productSheinClubPromotionFloor__leftText"
        :style="sheinClubPromotionFloorInfo.textStyle"
        v-html="sheinClubPromotionFloorInfo.text"></p>
    </div>
    <Icon
      name="sui_icon_more_right_12px_2"
      size="12px"
      :is-rotate="cssRight"
      color="#873C00" />
    <img
      v-if="showGradientImg"
      class="productSheinClubPromotionFloor__gradient"
      src="https://img.ltwebstatic.com/images3_ccc/2024/07/30/f2/1722330038e63583a270f178d7c9d8b67fc6e3576f.png" />
  </div>
</template>

<script setup name="SheinClubPromotionFloor">
import { computed, onMounted, inject } from 'vue'
// import { markPoint } from 'public/src/services/mark/index.js'
// import { PromotionUtils } from 'public/src/pages/common/promotion/utils'
import { Icon } from '@shein-aidc/icon-vue3'
import { useAppConfigs } from '@shein-aidc/bs-sdk-libs-manager'
const appConfigs = useAppConfigs()

const { triggerNotice } = inject('analysisInstance')

const emits = defineEmits(['jump'])

const props = defineProps({
  sheinClubPromotionFloorInfo: {
    type: Object,
    default: () => ({}),
  },
})

const titleImg = computed(() => props.sheinClubPromotionFloorInfo?.titleImg)
const containerStyle = computed(() => props.sheinClubPromotionFloorInfo?.containerStyle)
const showGradientImg = computed(() => props.sheinClubPromotionFloorInfo?.showGradientImg)
const analysisData = computed(() => props.sheinClubPromotionFloorInfo?.analysisData)
const cssRight = computed(() => props.sheinClubPromotionFloorInfo?.cssRight)

onMounted(() => {
  triggerNotice({
    id: 'expose_prime_entry.comp_quick-add-cart-standard',
    extraData: analysisData.value,
  })
})

const handleClick = () => {
  const isLogin = appConfigs.$getters?.isLogin
  triggerNotice({
    id: 'click_prime_entry.comp_quick-add-cart-standard',
    extraData: analysisData.value,
  })
  if (!isLogin) {
    window.SHEIN_LOGIN.show({
      show: true,
      from: 'other',
      cb: () => {
        handleClick()
      },
    })
    return
  }
  emits('jump', props.sheinClubPromotionFloorInfo?.jumpUrl)
}


</script>

<style lang="less">
.productSheinClubPromotionFloor {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.2133rem;
  position: relative;

  &__left {
    display: flex;
    align-items: center;
  }

  &__leftText {
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    color: #873c00;
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__icon-more {
    color: #873C00;
  }

  &__gradient {
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    height: 100%;
  }
}
</style>
