<template>
  <ul class="bs-color-default-text__wrapper">
    <li
      v-for="(color, index) in data"
      :key="color.goods_id"
      :class="{
        'bs-color-default-text__item': true,
        'sold-out': color?.isSoldOutStatus === '1',
        'active': activeGoodsId === color.goods_id,
      }"
      @click="useOnColorChange(emits, color, index)"
    >
      {{ color?.attr_value || '' }}
      <!-- 右上角角标 -->
      <slot
        name="UPPER_RIGHT_TAG"
        :tagInfo="color.tag"
      >
      </slot>
    </li>
  </ul>
</template>

<script name="BSColorDefaultText" setup lang="ts">
import { defineEmits } from 'vue'
import type { DS_MainSalesAttr } from '../../../types/index'
import useOnColorChange from '../../hook/useOnColorChange'


interface ColorModeProps {
  /** 色块数据 */
  data: DS_MainSalesAttr.MainSaleAttributeInfo []
  /** 当前选中的商品ID */
  activeGoodsId: string,
}

withDefaults(defineProps<ColorModeProps>(), {
  data: () => ([]),
  activeGoodsId: '',
})

const emits = defineEmits<{
  (event: 'onChange', params: object): void
}>()

</script>

<style lang="less" scoped>
@vw: 375/100vw;

.bs-color-default-text {
  &__wrapper {
    display: flex;
    flex-wrap: wrap;
    margin-top: 6 /@vw;
    margin-bottom: 4 /@vw;
  }

  &__item {
    position: relative;
    margin-right: 12 / @vw;
    margin-bottom: 8 / @vw;
    padding: 6 / @vw 12 / @vw 8 / @vw;
    border: 1px solid #e5e5e5;
    border-radius: 26 / @vw;
    font-size: 14px;
    line-height: 16 / @vw;

    &.sold-out {
      color: #bcbcbc;
      border: 1px dashed #e5e5e5;
    }

    &.active {
      color: #222;
      border: 1px solid #222;
    }
  }

}
</style>
