import { computed } from 'vue'
import { ColorMode } from '@shein-aidc/bs-main-sales-attr-mobile'
import { useModules } from './useModules'

/**
 * 主销售属性数据模型
 */
export const useMainSalesAttrModules = ({
	featureConfig,
	analysisConfig,
	largeImageMode,
}) => {
	// 基础数据
	const { saleAttr, language, productInfo, staticSaleAttr, fsAbt } = useModules()

	/** 当前选中的主销售属性 */
	const currentAttr = computed(() => {
		const goodsId = productInfo?.value?.goods_id
		const { info = [] } = staticSaleAttr?.value?.mainSaleAttribute || {}
		return info?.find(item => item.goods_id === goodsId)
	})

	// 是否显示 thickening 加绒款的 abt
	const isShowThickeningAbt = computed(() => {
		const { goodssaleattribute } = fsAbt?.value || {}
		const attrnewiconAb = goodssaleattribute?.param?.attr_thickening_icon
		return attrnewiconAb === 'on'
	})

	/** 主销售属性数据(兼容商详) */
	const mainSaleAttrInit = computed(() => {
		const { mainSaleAttribute } = staticSaleAttr?.value || {}
		const { mainSaleAttrDesc } = mainSaleAttribute || {}
		const { goodsMainAttPicShow, goodssaleattribute } = fsAbt?.value || {}
		const { attr_name, attr_value, attr_id, attr_value_id, attrImg } = currentAttr.value || {}
        const { attr_image } = attrImg || {}

		const isThickening = isShowThickeningAbt.value && currentAttr.value?.isShowThickening === '1'

		// 标题
		const colorBlockText = curColorMode.value !== ColorMode.BSColorDefaultText ? `${attr_name || ''}: ${attr_value || ''}${isThickening ? ' ' + language?.value?.SHEIN_KEY_PWA_34950 : ''}` : attr_name || ''

		// 是否展示FindMyShade入口
		// const isShowFindMyShadeEntry = !!findMyShadeH5Url
		const isShowFindMyShadeEntry = false
		const findMyShadeParams = {
			ruleId: 'rec_ver:S93V1.0',
			urlQuery: 'main_attr=' + (attr_value_id ? attr_id + '_' + attr_value_id : ''),
			urlFilter: /&?attr_ids=(\d|_)+/g,
		}

		// 美妆颜色描述展现样式
		const mainSaleAttrDescHtml = (goodssaleattribute?.param?.color_details === 'on' && mainSaleAttrDesc) ? `<span style="color: #A86104;">${mainSaleAttrDesc}</span>` : mainSaleAttrDesc
		const mainSaleAttrDescText = mainSaleAttrDesc ? `${attr_value}: ${mainSaleAttrDescHtml}` : ''

		const showMainAttrPic = !mainSaleAttrDescText && attr_image && goodsMainAttPicShow?.param?.goodsMainAttPicShow === 'on'
		const mainAttrPicInfo = showMainAttrPic ? {
			textDetail: `${language?.value?.SHEIN_KEY_PWA_24732}: `,
			attr_value,
			attr_image,
		} : null

		return {
			colorBlockText,
			isShowFindMyShadeEntry,
			findMyShadeParams,
			mainSaleAttrDescText,
            mainAttrPicInfo,
		}
	})

	/** hot 标签埋点数据(兼容商详) */
	const hotGoodsSnList = computed(() => {
		const { info = [] } = staticSaleAttr?.value?.mainSaleAttribute || {}
		const hotList: string[] = []
		info.map(item => {
			if (item?.hot_color === '1' && !+item?.isEco) {
				hotList.push(item?.goods_sn)
			}
		})
		return hotList?.join('_') || ''
	})

	/** 是否展示主销售属性(兼容商详) */
	const isShowMainAttr = computed(() => {
		const { mainSaleAttrShowMode, info = [] } = staticSaleAttr?.value?.mainSaleAttribute || {}
		const { colorList = [] } = mainSalesAttrData.value
		const { mainSaleAttrDescText = '', mainAttrPicInfo = '' } = mainSaleAttrInit.value

		const colorLenLimit = mainSaleAttrShowMode === '1' ? colorList?.length > 0 : colorList?.length > 1

		return featureConfig.value.needMainSalesAttr
			&& (colorLenLimit || !!mainSaleAttrDescText || !!mainAttrPicInfo)
	})

	/** 色块展示样式类型 */
	const curColorMode = computed(() => {
		const { mainSaleAttrShowMode } = staticSaleAttr?.value?.mainSaleAttribute || {}
		const cccDetailsTemplate = productInfo?.value?.cccDetailsTemplate

		if (mainSaleAttrShowMode === '1') {
			// 文字类型
			return ColorMode.BSColorDefaultText
		}
		const colorpickAb = fsAbt?.value?.colorpick?.param?.colorpick
		if(['A'].includes(colorpickAb)) return ColorMode.BSColorCircleImage
		if(['B'].includes(colorpickAb)) return ColorMode.BSColorSquareImage
		if (cccDetailsTemplate?.tempType == 1 && cccDetailsTemplate?.content?.[0]?.colorType === '2') {
			// 方形色块
			return ColorMode.BSColorSquareImage
		}
		// 默认圆形色块
		return ColorMode.BSColorCircleImage
	})

	/** 是否展示 new 标签 */
	const isShowNewTags = computed(() => {
		const { goodssaleattribute } = fsAbt?.value || {}
		const attrnewiconAb = goodssaleattribute?.param?.attrnewicon
		const { info = [] } = staticSaleAttr?.value?.mainSaleAttribute || {}

		if (attrnewiconAb === 'A') {
			// 满足A，全是新品则不展示
			return !info?.every?.(item => item.isNew == '1')
		}

		return attrnewiconAb === 'B'
	})

	/**
	 * 主销售属性组件配置
	 */
	const mainSalesAttrConfig = computed(() => {
		const { selectcolor } = fsAbt?.value || {}
		const { canShowLargeImg } = staticSaleAttr?.value?.mainSaleAttribute || {}

		/** 是否支持大图切换 */
		const needLargeImageMode = canShowLargeImg === '1' && selectcolor?.param?.largeimage === 'on'

		return {
			isShowMainAttr: isShowMainAttr.value,
			location: 'popup',
			mainSaleAttrShowMode: curColorMode.value,
			firstShowLargeImage: largeImageMode.value, // 首次打开是否支持大图
			needLargeImageMode,
			salesAttrConfig: featureConfig.value.salesAttrConfig,
			analysisConfig,
			isShowNewTags: isShowNewTags.value,
			isShowThickeningAbt: isShowThickeningAbt.value,
		}
	})

	/**
	 * 主销售属性组件数据
	 */
	const mainSalesAttrData = computed(() => {
		const { info = [] } = staticSaleAttr?.value?.mainSaleAttribute || {}
		const { nonSpecifiedGoodsIds = [] } = saleAttr?.value || {}
		// 降价标签
		const skcPriceInfosMap = saleAttr?.value?.moreSkcPriceInfos?.reduce((res, item) => {
			const { goodsId, lowerPriceFlag } = item || {}
			if (goodsId) {
				res[goodsId] = !!+lowerPriceFlag
			}
			return res
		}, {})

		let colorList = info.map(info => ({
			...info,
			lowPrice: skcPriceInfosMap?.[info.goods_id] ? '1' : '0',
		}))
		if(nonSpecifiedGoodsIds.length) {
			colorList = colorList.filter(item => !nonSpecifiedGoodsIds.includes(item.goods_id))
		}
		const isShowSoldOutSkc = fsAbt?.value?.showsoldoutskc?.param?.showsoldoutskc === 'on'
		if(!isShowSoldOutSkc) {
			// 过滤售罄色块(售罄但CCC标记为要展示的skc不需要过滤)
			colorList = colorList.filter(item => item.isSoldOutStatus != '1' || item.isSoldOutStatusByCcc == '1') || []
		}
		return {
			language: language?.value,
			mainSaleAttrInit: mainSaleAttrInit.value,
			currentAttr: currentAttr.value,
			colorList,
			hotGoodsSnList: hotGoodsSnList.value,
		}
	})

	return {
		currentAttr,
		mainSalesAttrConfig,
		mainSalesAttrData,
	}
}
