<template>
  <!-- 最外层包一个 block 解决低版本safari高度坍塌 -->
  <div>
    <div
      id="priceContainer"
      class="quickPriceContainer"
      :style="priceContainerStyle"
    >
      <FlashBeltWraper
        v-if="flashBeltWraperInfo.showBeltAndPriceWrapper"
        :flash-belt-wraper-info="flashBeltWraperInfo"
      >
        <Price
          :main-price-info="mainPriceInfo"
          :price-prefix-info="pricePrefixInfo"
          :countdown-info="countdownInfo"
          :discount-info="discountInfo"
          :discount-amount-info="discountAmountInfo"
          :estimated-info="estimatedInfo"
          :suggested-sale-price-info="suggestedSalePriceInfo"
          :exclusive-info="exclusiveInfo"
          :recommend-retail-price="recommendRetailPrice"
          :recommend-retail-price-fr-de="recommendRetailPriceFrDe"
          :unit-price-info="unitPriceInfo"
          :config="{ scene: 'MAIN', goodsSn, priceStyleType: { showBrandDeals: !!flashBeltWraperInfo.brandDealsInfo} }"
          @clickEstimatedTag="clickEstimatedTag"
        />
      </FlashBeltWraper>
      <template v-else>
        <Price
          :main-price-info="mainPriceInfo"
          :price-prefix-info="pricePrefixInfo"
          :countdown-info="countdownInfo"
          :discount-info="discountInfo"
          :estimated-info="estimatedInfo"
          :suggested-sale-price-info="suggestedSalePriceInfo"
          :exclusive-info="exclusiveInfo"
          :recommend-retail-price="recommendRetailPrice"
          :recommend-retail-price-fr-de="recommendRetailPriceFrDe"
          :unit-price-info="unitPriceInfo"
          :config="{ scene: 'MAIN', goodsSn, priceStyleType: { showBrandDeals: !!flashBeltWraperInfo.brandDealsInfo} }"
          @clickEstimatedTag="clickEstimatedTag"
        />
        <EstimatedNewCountdown
          v-if="countDownEndTime && serverTime && !countdownNewStyle"
          :end-time="countDownEndTime"
          :now-time="serverTime"
          :is-promotion="priceEndTimeIsPromotion"
          :is-show-count-down-mill-sec="fsAbt?.Detailimelimit?.param?.Timelimitmillisec === 'show'" />
      </template>
    </div>
  </div>
</template>

<script setup name="PriceContainer">
/**
 * @file
 * price 容器 引入 vuex 数据传入 price 组件
*/
import { computed, toRefs, watch, inject, ref, onMounted } from 'vue'
import usePrice from '../../hook/usePrice'
import Price from './Price/index.vue'
import FlashBeltWraper from './FlashBeltWraper/index.vue'
import EstimatedNewCountdown from './Price/components/EstimatedTag/EstimatedNewCountdown.vue'
import { useAppConfigs } from '@shein-aidc/bs-sdk-libs-manager'
import { getSource } from '../../../../common/dataSource'

const emits = defineEmits(['clickEstimatedTag'])
const { triggerNotice } = inject('analysisInstance')

const props = defineProps({
  featureConfig: {
    type: Object,
    default: () => ({}),
  },
  fsAbt: {
    type: Object,
    default: () => ({}),
  },
  skuInfo: {
    type: Object,
    default: () => ({}),
  },
  skuList: {
    type: Array,
    default: () => ([]),
  },
  isSoldOut: {
    type: Boolean,
    default: false,
  },
  currentStock: {
    type: Number,
    default: 1,
  },
  price: {
    type: Object,
    default: () => ({}),
  },
  goodsId: {
    type: String,
    default: '',
  },
  mallCode: {
    type: String,
    default: '',
  },
  goodsSn: {
    type: String,
    default: '',
  },
})

const {
  featureConfig,
  skuInfo,
  skuList,
  isSoldOut,
  currentStock,
  price,
  goodsId,
  mallCode,
} = toRefs(props)

const {
  mainPriceInfo,
  pricePrefixInfo,
  countdownNewStyle,
  discountInfo,
  discountAmountInfo,
  estimatedInfo,
  suggestedSalePriceInfo,
  exclusiveInfo,
  flashBeltWraperInfo,
  recommendRetailPrice,
  recommendRetailPriceFrDe,
  unitPriceInfo,
  countDownEndTime,
  priceEndTimeIsPromotion
} = usePrice({
  featureConfig,
  skuInfo,
  skuList,
  isSoldOut,
  currentStock,
  mallCode,
})

const serverTime = ref(null)
const clickEstimatedTag = () => emits('clickEstimatedTag')

const countdownInfo = computed(() => {
  return {
    countdownNewStyle: countdownNewStyle.value,
    countDownEndTime: countDownEndTime.value,
    nowTime: serverTime.value,
    isShowCountDownMillSec: props.fsAbt?.Detailimelimit?.param?.Timelimitmillisec === 'show'
  }
})

const priceReportList = ref([])

const estimatedPriceExpose = () => {
  const sku_code = skuInfo.value?.sku_code || ''
  const skc_sku = goodsId.value + '_' + sku_code
  if(priceReportList.value.includes(skc_sku)){
    return
  }
  priceReportList.value?.push(skc_sku)
  const buried_price = price.value?.buriedPrice || null
  if(!buried_price || !buried_price.type){
    return
  }
  if(buried_price?.type == 1 && !featureConfig?.value?.showNoSatisfied){
    // 凑单到手价被屏蔽了，则type=1不上报
    return
  }
  if(buried_price?.type == 2 && !featureConfig?.value?.showNEstimatedPrice){
    // N件到手价被屏蔽了，则type=2不上报
    return
  }
  let id = 'expose_addprice_abt.comp_quick-add-cart-standard'
  let extraData = {
    estimated_price: buried_price?.price?.usdAmountWithSymbol || '0',
    sku_code,
    location: 1,
  }
  if(buried_price?.type == 2){ // N件到手价上报染色埋点shownprice
    id = 'expose_npicprice.comp_quick-add-cart-standard'
    extraData.estimated_price_num = buried_price?.purchasePcs || 'n'
  }
  triggerNotice({
    id,
    extraData,
  })
}

watch(
  () => skuInfo.value,
  () => {
    estimatedPriceExpose()
  },
)
watch(
  () => goodsId.value,
  () => {
    priceReportList.value = []
  },
)

const priceContainerStyle = computed(() => {
  if (flashBeltWraperInfo?.value?.showBeltAndPriceWrapper) return {}
  return {
    'display': 'flex',
    'justify-content': 'space-between',
  }
})

onMounted(() => {
  const appConfigs = useAppConfigs()
  const apis = getSource({ appConfigs })
  apis.getServicesTime().then((res) => {
    serverTime.value = res?.data?.zzz || (Date.now() / 1000 >> 0)
  }).catch(() => {
    serverTime.value = Date.now() / 1000 >> 0
  })
})

</script>

<style lang="less">
.quickPriceContainer {
  width: 100%;
  margin-bottom: .2133rem;
  display: flex;
  align-items: center;
}
</style>
