<template>
  <div
    ref="qtyWrap"
    class="quickViewQuantity"
    :style="{'--tips-width': `${tipsWidth}px`}"
  >
    <span
      ref="qtyLanguage"
      class="qty-text"
    >
      {{ title }}:
    </span>
    <div
      ref="qtyContent"
      class="calc"
    >
      <div
        :class="{ disabled: quantity <= 1, iconBox: true }"
        @click="handleReduce"
      >
        <Icon
          name="sui_icon_min_16px"
          size="18px"
        />
      </div>
      <input
        type="text"
        :value="quantity"
        :disabled="addCartUpperLimit === 0"
        readonly
      />
      <div
        :class="{ disabled: addCartUpperLimit <= quantity, iconBox: true }"
        @click="handleAdd"
      >
        <Icon
          name="sui_icon_add_16px"
          size="18px"
        />
      </div>
    </div>
    <div
      ref="qtyTips"
      class="quickViewQuantity__tips"
    >
      <p
        v-if="buyMultipleText"
        class="quickViewQuantity__buyMultipleText"
      >
        <span class="nowrap-text">{{ buyMultipleText }}</span>
      </p>
      <div
        v-if="buyMultipleText && stockTip && !isTwoLines"
        class="quickViewQuantity__tips-gap"
      ></div>
      <div
        v-if="stockTip"
        v-expose="stockTipExpose"
        class="quickViewQuantity__tips-stock"
        :class="[currentStock === 0 ? 'grey' : 'orange']"
      >
        <Icon
          name="sui_icon_hourglass_12px"
          size="12px"
          style="margin-right: 2px"
        />
        <span class="nowrap-text">{{ stockTip }}</span>
      </div>
    </div>
  </div>
</template>

<script name="QuickCartQuantity" setup lang="ts">
import { debounce } from '@shein/common-function'
import { computed, watch, defineProps, inject, ref, onMounted } from 'vue'
import { Icon } from '@shein-aidc/icon-vue3'
import { AS_QuickAddCartStandard } from '@shein-aidc/bs-quick-add-cart-standard-mobile'
import { useModules } from '../../modules/useModules'

const props = defineProps({
  goodsId: {
    type: String,
    default: '',
  },
  quantity: {
    type: Number,
    default: 0,
  },
  currentStock: {
    type: Number,
    default: 0,
  },
  buyMultipleText: {
    type: String,
    default: '',
  },
  willSoldOutTipsInfo: {
    type: Object,
    default: () => ({}),
  },
  setQuantity: {
    type: Function,
    default: () => {},
  },
  brandDealsInfo: {
    type: Object,
    default: () => ({}),
  },
})
const { detailPromotionFloor, language } = useModules()
const { vExpose, triggerNotice } = inject<AS_QuickAddCartStandard.AnalysisInstance>('analysisInstance') || {}
const title = computed(() => language?.value?.SHEIN_KEY_PWA_15664 || 'Qty')
const detailPromotionInfo = computed(() => detailPromotionFloor?.value?.detailPromotionInfo || [])
const flashSaleLimit = computed(() => {
  const flashSaleData = detailPromotionInfo.value?.find?.((item) => item.typeId == 10)
  if (!flashSaleData || typeof window === 'undefined') return 100000
  const currentTime = Date.now() / 1000
  const {
    singleLimitType,
    buyLimit,
    soldNum,
    endTimestamp,
    singleNum,
  } = flashSaleData
  const hasLimit = (singleLimitType == 1 || singleLimitType == 3)
  if (hasLimit && Number(buyLimit) > Number(soldNum) && Number(currentTime) < Number(endTimestamp)) return Number(singleNum)
  return 100000
})
const underPriceLimit = computed(() => {
  const underPriceInfo = detailPromotionInfo.value?.find?.((item) => item.typeId == 30)
  const limitNum = Number(underPriceInfo?.singleNum)
  if (isNaN(limitNum) || !limitNum) {
    return 100000
  }
  return limitNum
})
const addCartUpperLimit = computed(() => {
  // 百亿补贴活动可购买数
  const brandDealsLimit = props.brandDealsInfo?.singleRemainNum ?? 100000
  return Math.min(flashSaleLimit.value, underPriceLimit.value, props.currentStock, brandDealsLimit)
})
const stockTip = computed(() => {
  // if (props.currentStock <= 0) {
  //   return language?.value?.SHEIN_KEY_PWA_14987 || 'sold out'
  // }
  return props.willSoldOutTipsInfo?.tip || ''
})
const stockTipExpose = computed(() => {
  if (props.currentStock <= 0) return {}
  return {
    id: 'expose_lowstock_label.comp_quick-add-cart-standard',
    prefix: 'comp_quick-add-cart-standard',
    data: {
      goods_level: props.willSoldOutTipsInfo?.scene,
      label_type: props.willSoldOutTipsInfo?.lowStockType == 2 ? 1 : 2,
      location: 'qty',
    },
  }
})

const handleReduce = () => {
  triggerNotice?.({
    id: 'click_qty.comp_quick-add-cart-standard',
  })
  if (props.quantity <= 1) return
  props.setQuantity?.(props.quantity - 1)
}
const handleAdd = () => {
  triggerNotice?.({
    id: 'click_qty.comp_quick-add-cart-standard',
  })
  if (props.quantity >= addCartUpperLimit.value) return
  props.setQuantity?.(props.quantity + 1)
}

// 1. 如果当前数量大于加车上限，则将当前数量设置为加车上限
// 2. 如果当前数量小于1，则将当前数量设置为1
// 3. 如果当前数量在加车上限范围内，也需要更新当前数量，触发视图更新
const handleChangeQty = debounce({
  func: () => {
    if (props.quantity > addCartUpperLimit.value) {
      const count = addCartUpperLimit.value > 0 ? addCartUpperLimit.value : 1
      props.setQuantity?.(count)
    } else if (props.quantity < 1) {
      props.setQuantity?.(1)
    } else {
      props.setQuantity?.(props.quantity)
    }
  },
  wait: 150,
})

watch([() => props.goodsId, addCartUpperLimit], () => {
  // 切换skc（商品id变化）时，加车上限有可能会变化，需要更新当前数量
  // 加车上限会在商品id变化后，再触发更新，所以需防抖处理
  handleChangeQty()
})

const isTwoLines = ref(false)
const tipsWidth = ref('5.6rem')
const qtyWrap = ref()
const qtyTips = ref()
const qtyLanguage = ref()
const qtyContent = ref()
// 计算限购&低库存提示宽度
const getQtyTipsWidth = () => {
  const qtyWrapWidth = qtyWrap.value?.clientWidth
  const qtyLanguageWidth = qtyLanguage.value?.clientWidth
  const qtyContentWidth = qtyContent.value?.clientWidth
  tipsWidth.value = qtyWrapWidth - qtyLanguageWidth - qtyContentWidth - 40
  if (qtyTips.value.clientHeight >= 28) {
    isTwoLines.value = true
  }
}

onMounted(() => {
  getQtyTipsWidth()
})
</script>

<style lang="less" scoped>
.nowrap-text {
  // 超出一行展示省略号
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.quickViewQuantity {
  display: flex;
  align-items: center;
  margin-bottom: 3.2vw;
  margin-top: 2.4vw;

  .qty-text {
    font-size: 12px;
  }

  .calc{
    display: flex;
    margin-left: 3.2vw;
    margin-right: 2.133vw;
    // TODO: ar站点翻转
    // .margin-l(.32rem);
    // .margin-r(.2133rem);
    .iconBox{
      width: 6.4vw;
      height: 6.4vw;
      background:rgba(246,246,246,1);
      color: #222;
      user-select: none;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: bold;
      &.disabled{
        opacity: .3;
      }
    }
    input{
      width: 9.6vw;
      height: 6.4vw;
      text-align: center;
      font-weight: bold;
      border: none;
      outline: none;
      font-size: 14px;
      &[disabled] {
          color: #ccc;
      }
    }
  }
  &__tips-stock {
    max-width: var(--tips-width, 5.6rem);
    font-size: 12px;
    display: flex;
    align-items: center;
  }
  .orange{
    color: #FA6338;
  }
  .grey{
    color: #ccc;
  }

  &__tips {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  &__tips-gap {
    width: 1px;
    height: 10px;
    opacity: 0.4;
    margin: auto 4px;
    background: #FA6338;
  }
  &__buyMultipleText {
    max-width: var(--tips-width, 5.6rem);
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    color: #FA6338;
    display: flex;
    align-items: center;
  }
}
</style>
