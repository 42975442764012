<template>
  <ul class="bs-color-circle-image__wrapper">
    <li
      v-for="(color, index) in data"
      :key="color.goods_id"
      :class="{
        'bs-color-circle-image__item': true,
        'active': activeGoodsId === color.goods_id
      }"
      @click="useOnColorChange(emits, color, index)"
    >
      <div
        :style="{ 'background-image': `url(${useBaseCutImg(color.goods_color_image, 80, { max: 100 })})`}"
        :class="{
          'bs-color-circle-image__item-inner': true,
          'sold-out': color?.isSoldOutStatus === '1'
        }"
      >
      </div>
      <!-- 右上角角标 -->
      <slot
        name="UPPER_RIGHT_TAG"
        :tagInfo="color.tag"
      >
      </slot>
    </li>
  </ul>
</template>

<script name="BSColorCircleImage" setup lang="ts">
import { defineEmits } from 'vue'
import type { DS_MainSalesAttr } from '../../../types/index'
import useOnColorChange from '../../hook/useOnColorChange'
import useBaseCutImg from '../../hook/useBaseCutImg'

interface ColorModeConfig {
  /** 缺省图 */
  lazyImg: string,
  fixedRatio: string,
}

interface ColorModeProps {
  /** 色块数据 */
  data: DS_MainSalesAttr.MainSaleAttributeInfo []
  /** 色块配置 */
  config?: ColorModeConfig,
  /** 当前选中的商品ID */
  activeGoodsId: string,
}

withDefaults(defineProps<ColorModeProps>(), {
  data: () => ([]),
  activeGoodsId: '',
})

const emits = defineEmits<{
  (event: 'onChange', params: object): void
}>()

</script>

<style lang="less" scoped>
@vw: 375/100vw;

.bs-color-circle-image {
  &__wrapper {
    display: flex;
    flex-wrap: wrap;
    margin-top: 6 / @vw;
  }

  &__item {
    position: relative;
    margin-right: 12 / @vw;
    margin-bottom: 12 / @vw;
    width: 26 / @vw;
    height: 26 / @vw;

    &.active {
      position: relative;
      padding: 3px;
      &::before {
        position: absolute;
        content: '';
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        border: 1px solid #222222;
        border-radius: 100%;
      }
    }
  }

  &__item-inner {
    width: 100%;
    height: 100%;
    border-radius: 100%;
    box-sizing: border-box;
    border: 1px solid rgba(34, 34, 34, 0.06);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 26 / @vw;

    &.sold-out {
      position: relative;
      &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        transform: translate3d(0, -50%, 0) rotate(-45deg);
        transform-origin: center;
        width: 100%;
        height: 2px;
        background: rgba(153, 153, 153, 0.8);
        z-index: 1;
      }
      &::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        transform: translate3d(0, -50%, 0) rotate(-45deg);
        transform-origin: center;
        width: 100%;
        height: 4px;
        background: rgba(255, 255, 255, 0.9);
        font-size: 0;
      }
    }
  }
}
</style>
