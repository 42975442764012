import { DS_QuickAddCartStandard } from '@shein-aidc/bs-quick-add-cart-standard-mobile'
import Logger from '../utils/logger'
import { useModules } from '../modules/useModules'


/**
 * 默认优先级
 */
const defaultPriority = 100

class PluginManager {
  plugins: DS_QuickAddCartStandard.QuickAddCartPlugin[] = []
  context: DS_QuickAddCartStandard.PluginManagerContext
  constructor() {
    this.plugins = []
    this.context = {
      useModules,
    }
  }

  registerPlugin(plugin: DS_QuickAddCartStandard.QuickAddCartPlugin) {
    if (!plugin.name) {
      Logger.warn('Plugin name does not exist. Registration failed.')
      return
    }
    // 初始化优先级
    plugin.priority = plugin.priority ? plugin.priority : defaultPriority
    // 初始化禁用状态
    plugin.disable = false
    const existingPluginIndex = this.plugins.findIndex(p => p.name === plugin.name)
    if (existingPluginIndex !== -1) {
      Logger.warn(`Plugin ${plugin.name} has already been registered and is now being overwritten.`)
      this.plugins[existingPluginIndex] = plugin
    } else {
      this.plugins.push(plugin)
    }
    plugin.register && plugin.register(this.context)
  }

  /**
   * 重置插件状态
   */
  resetPlugins () {
    this.plugins.forEach(plugin => {
      plugin.disable = false
    })
  }

  registerPlugins(plugins: DS_QuickAddCartStandard.QuickAddCartPlugin[]) {
    plugins.forEach(p => this.registerPlugin(p))
  }
  /**
   * 排除插件
   * @param excludePlugins 需要排除的插件名字集合
   */
  excludePlugins(excludePlugins: string[]) {
    if(excludePlugins.length > 0) {
      this.plugins.forEach(p => {
        if (excludePlugins.includes(p.name)) {
          p.disable = true
        }
      })
    }
  }

  removePlugin(pluginName: string) {
    const index = this.plugins.findIndex(p => p.name === pluginName)
    if (index !== -1) {
      this.plugins.splice(index, 1)
    }
  }

  cleanPlugin() {
    this.plugins = []
  }

  getPluginByHookName(name: string) {
    return this.plugins.find(item => item[name] && !item.disable)
  }

  filterPluginsByHookName(name: string) {
    return this.plugins.filter(item => item[name] && !item.disable)
  }

  filterPluginsByCallbackName(name: string) {
    return this.plugins.filter(item => item.callbacks && item.callbacks[name] && !item.disable)
  }

  /**
   * 按priority降序排序
   */
  sortPluginsByPriority() {
    return this.plugins.sort((a, b) => b.priority - a.priority)
  }
}

let PluginManagerInstance: PluginManager | null = null
export function usePluginManager() {
  if (PluginManagerInstance) {
    return PluginManagerInstance
  }
  PluginManagerInstance = new PluginManager()
  return PluginManagerInstance
}

export const pluginManager = usePluginManager()

export function usePlugins() {
  return {
    /**
     * @param hookName 钩子名字
     * 按插件优先级顺序执行钩子，并在某个插件的hook返回一个“非空”值时立即返回
     */
    render: <K extends DS_QuickAddCartStandard.PluginHookNames>(hookName: K, ...args: [...Parameters<NonNullable<DS_QuickAddCartStandard.QuickAddCartPlugin[K]>>]) => {
      const plugins = usePluginManager().filterPluginsByHookName(hookName)
      for (const plugin of plugins) {
        // @ts-ignore
        const result = plugin[hookName]!(...args) as ReturnType<NonNullable<DS_QuickAddCartStandard.QuickAddCartPlugin[K]>>
        if (result !== null && result !== undefined) {
          Logger.info(`Plugin ${plugin.name} hook ${hookName}:`, ...args)
          // @ts-ignore
          // 这里需要给每个插件的结果添加一个唯一的key，避免重复渲染
          result.key = `${plugin.name}_${hookName}_${new Date().getTime()}`
          return result
        }
      }
      return null
    },
     /**
     * @param hookName 钩子名字
     * 按插件优先级顺序执行钩子，并在某个插件的hook返回一个“非空”值时立即返回
     */
     hook: <K extends DS_QuickAddCartStandard.PluginHookNames>(hookName: K, ...args: [...Parameters<NonNullable<DS_QuickAddCartStandard.QuickAddCartPlugin[K]>>]) => {
      const plugins = usePluginManager().filterPluginsByHookName(hookName)
      for (const plugin of plugins) {
        // @ts-ignore
        const result = plugin[hookName]!(...args) as ReturnType<NonNullable<DS_QuickAddCartStandard.QuickAddCartPlugin[K]>>
        if (result !== null && result !== undefined) {
          return result
        }
      }
      return null
    },
    /**
     * 按插件优先级顺序执行钩子
     */
    hooks: <K extends DS_QuickAddCartStandard.PluginHookNames>(hookName: K, ...args: [...Parameters<NonNullable<DS_QuickAddCartStandard.QuickAddCartPlugin[K]>>]) => {
      const plugins = usePluginManager().filterPluginsByHookName(hookName)
      const promises = plugins.map(plugin => {
        // @ts-ignore
        return plugin[hookName]!(...args) as ReturnType<NonNullable<DS_QuickAddCartStandard.QuickAddCartPlugin[K]>>
      })
      return Promise.all(promises)
    },
    /**
     * 按插件优先级顺序执行回调
     */
    callbacks: <K extends keyof DS_QuickAddCartStandard.CallBacks>(callbackName: K, ...args: [...Parameters<NonNullable<DS_QuickAddCartStandard.CallBacks[K]>>]) => {
      const plugins = usePluginManager().filterPluginsByCallbackName(callbackName)
      for (const plugin of plugins) {
        try {
          Logger.info(`Plugin ${plugin.name} callback ${callbackName}:`, ...args)
          plugin.callbacks![callbackName]!(...(args as [any])) as ReturnType<NonNullable<DS_QuickAddCartStandard.CallBacks[K]>>
        } catch(e) {
          Logger.error(`Plugin ${plugin.name} callback ${callbackName} fail:`, e)
        }
      }
    },
  }
}

