<template>
  <SDialog
    :visible="visible"
    role="text"
    :aria-label="language?.SHEIN_KEY_PWA_15060"
    aria-live="polite"
  >
    <div class="bsc-quick-add-cart__dialog">
      <p>
        {{ language?.SHEIN_KEY_PWA_17325 }}
      </p>
      <div class="bsc-quick-add-cart__dialog_qty-limit">
        <SButton
          class="bsc-quick-add-cart__dialog_qty-limit_btn"
          :type="['primary', 'H80PX']"
          @click="handleConfirm"
        >
          {{ language?.SHEIN_KEY_PWA_17332 }}
        </SButton>
        <SButton
          class="bsc-quick-add-cart__dialog_qty-limit_btn"
          :type="['normal', 'H80PX']"
          @click="close"
        >
          {{ language?.SHEIN_KEY_PWA_16092 }}
        </SButton>
      </div>
    </div>
  </SDialog>
</template>

<script name="PromotionLimit" setup lang="ts">
/**
 * 折扣促销超过限制订单数弹窗
 */
import { ref } from 'vue'
import { SDialog } from '@shein-aidc/sui-dialog/mobile'
import { SButton } from '@shein-aidc/sui-button/mobile'
import { DialogOptions } from './types'
import { DS_QuickAddCartStandard } from '@shein-aidc/bs-quick-add-cart-standard-mobile'
const language = ref<Record<DS_QuickAddCartStandard.LANGUAGE_KEY , string | undefined> | undefined>(undefined)
const visible = ref<boolean>(false)
const onConfirm = ref<(() => void)| undefined>(undefined)
const onClose = ref<(() => void)| undefined>(undefined)

const open = (options: DialogOptions) => {
    language.value = options.language
    onConfirm.value = options.onConfirm
    onClose.value = options.onClose
    visible.value = true
    if(options?.duration) {
        setTimeout(() => {
          visible.value = false
        }, options.duration)
    }
}
const close = () => {
    visible.value = false
    onClose.value && onClose.value()
}

const handleConfirm = () => {
    onConfirm.value && onConfirm.value()
    visible.value = false
}
defineExpose({ open, close })
</script>


<style lang="less">
.bsc-quick-add-cart {
  &__dialog {
    display: flex;
    flex-direction: column;
    text-align: center;
    padding-top: 1.5vw;
    padding-bottom: 4.7vw;
    font-size: 12px;
    line-height: 1.5;
    &_qty-limit {
      margin-top: 2vw;
      display: flex;
      justify-content: space-around;
      &_btn {
        font-size: 12px;
        flex: 1;
        &:first-child {
          margin-right: 2vw;
        }
      }
    }
  }
}
</style>
