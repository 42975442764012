import { DS_QuickAddCartStandard } from '@shein-aidc/bs-quick-add-cart-standard-mobile-vue2'
import { Button } from './Button.tsx'
import { DisableButton } from './DisableButton.tsx'
import { FootBarTips } from './FootBarTips.tsx'

/**
 * 百亿补贴活动插件
 * @description 禁止点击 ｜ 跳转 app
 */
export const brandDealsPlugin = (): DS_QuickAddCartStandard.QuickAddCartPlugin => {
  return {
    name: 'brandDealsPlugin',
    priority: 120,
    renderFooterBarTips: (context) => {
      const { brandDealsInfo, isSoldOut, unListed } = context
      // 非预售商品，非售罄, 活动库存为0
      if(!unListed && !isSoldOut && brandDealsInfo && brandDealsInfo?.triggerLimit) {
        return FootBarTips(brandDealsInfo.addCartLimitTip)
      }
    },
    renderAddCartButton: (context) => {
      const { brandDealsInfo, isSoldOut, unListed } = context
      // 是否展示百亿补贴拦截按钮按钮
      const showBrandDealsBtn = !unListed && !isSoldOut && brandDealsInfo
      // 非预售商品，非售罄，活动库存大于0
      if(showBrandDealsBtn && !brandDealsInfo?.triggerLimit) {
        return Button(brandDealsInfo)
      }
      // 非预售商品，且售罄活动库存为0
      if(showBrandDealsBtn && brandDealsInfo?.triggerLimit) {
        return DisableButton(brandDealsInfo.mainBtnText)
      }
    },
  }
}
