import { DS_QuickAddCartStandard } from '@shein-aidc/bs-quick-add-cart-standard-mobile'
import { Button } from './Button.tsx'
import { DisableButton } from './DisableButton.tsx'
import { FootBarTips } from './FootBarTips.tsx'

/**
 * 更新销售属性插件
 * @param {Function} updateCallback 更新销售属性回调
 * @description 销售属性模式
 */
export const SalesAttrUpdatePlugin = (updateCallback): DS_QuickAddCartStandard.QuickAddCartPlugin => {
  let pluginContext: DS_QuickAddCartStandard.PluginManagerContext | null = null
  return {
    name: 'SalesAttrUpdate',
    register: (context) => {
      pluginContext = context
    },
    renderFooterBarTips: (context) => {
      const { isSoldOut, mallStock, unListed } = context
      // 非预售商品，且售罄或者库存为0
      if(!unListed && (isSoldOut || mallStock == 0)) {
        return FootBarTips(pluginContext?.useModules?.()?.language?.value?.SHEIN_KEY_PWA_16794)
      }
    },
    renderAddCartButton: (context) => {
      const { isSoldOut, mallStock, unListed } = context
      // 非预售商品，非售罄，并且mall库存大于0
      if(!unListed && !isSoldOut &&(mallStock ?? 0) > 0) {
        return Button({
          context,
          updateCallback,
          text: pluginContext?.useModules?.()?.language?.value?.SHEIN_KEY_PWA_15285,
        })
      }
      // 非预售商品，且售罄或者库存为0
      if(!unListed && (isSoldOut || mallStock == 0)) {
        return DisableButton(pluginContext?.useModules?.()?.language?.value?.SHEIN_KEY_PWA_15285)
      }
    },
  }
}
