<script lang="jsx">
import { Icon } from '@shein-aidc/icon-vue3'
export default {
  name: 'SizeBubble',
  functional: true,
  props: {
    sizeUnit: {
      type: String,
      default: '',
    },
    attrDescPopUp: {
      type: Array,
      default: () => [],
    },
    sizeConstant: {
      type: Object,
      default: () => {},
    },
    showSizeAttributeEntrance: {
      type: Boolean,
      default: false,
    },
    handleOpenSizeGuide: {
      type: Function,
      default: () => {},
    },
  },
  setup(props, { slots }) {
    return () => {
      const { attrDescPopUp, sizeUnit, handleOpenSizeGuide, showSizeAttributeEntrance, sizeConstant } =
        props
      const { cssRight } = sizeConstant || {}

      const partsInfo = () => {
        // 只取第一个部件
        const part = attrDescPopUp?.[0] || {}
        // 多部件尺码的时候，展示部件名字
        const partName =
          part.multiPartName && attrDescPopUp.length > 1 ? (
            <span class="size-info-part-name">{part.multiPartName}:</span>
          ) : null

        // 尺码详情
        const infoDetail = part?.bindAttrData?.map(item => {
          const { attrNameValueCm, attrNameValueInch, attrNameKey } = item || {}
          return (
            <div class="size-info-item">
              <div class="size-info-item-key">{attrNameKey}</div>
              <div class="size-info-item-value">{ sizeUnit === 'inch' ? attrNameValueInch : attrNameValueCm }</div>
            </div>
          )
        })
        return (
          <div class="size-info-part">
            {partName}
            <div class="size-info-part-detail">{infoDetail}</div>
          </div>
        )
      }

      return (
        <div
          class="goods-size__item-info goods-size__item-bubble-info"
          aria-hidden="true"
        >
          {slots?.default?.() || null }
          {attrDescPopUp?.length ? (
            <div
              class="goods-size__content-container"
              onClick={handleOpenSizeGuide}
            >
              <div class="goods-size__mesurement-container">
                <div class="size-info-parts">{partsInfo()}</div>
                <div class="sticky-right">
                  <div class="fade-bg"></div>
                  {showSizeAttributeEntrance && (
                    <Icon name="sui_icon_more_right_16px" size="16px" is-rotate={cssRight} />
                  )}
                </div>
              </div>
            </div>
          ) : null}
        </div>
      )
    }
  },
}
</script>

<style lang="less">
.goods-size {
  &__item-bubble-info {
    display: flex;
    flex-flow: column nowrap;
    padding: 8px;
    align-items: flex-start;
    align-self: stretch;
    border-radius: 2px;
    background: rgba(239, 243, 248, 0.5);
    font-family: "SF Pro";
    line-height: normal;
    &-header {
      display: flex;
      align-items: center;
      line-height: 14px;
      padding-bottom: 4px;
      .hourglass-icon {
        padding-right: 2px;
      }
      .tips {
        color: #FA6338;
        text-align: right;
        font-size: 12px;
        font-style: normal;
        font-weight: 510;
      }
    }

    .goods-size__content-container {
      max-width: 100%;
      width: 100%;
      box-sizing: border-box;
    }
    .goods-size__mesurement-container {
        width: 100%;
        display: flex;
        align-items: center;
    }
    // 固定在右边区域
    .sticky-right {
      box-sizing: border-box;
      display: flex;
      align-items: center;
      height: 100%;
      color: #959595;
      padding-left: 4px;
      position: relative;
      .fade-bg {
        position: absolute;
        background: linear-gradient(90deg, rgba(247, 249, 251, 0.00) 0%, #F7F9FB 88.33%);
        width: 8vw;
        height: 8vw;
        left: -8vw;
      }
    }

    .size-info-parts {
      display: flex;
      overflow: scroll;
      flex: 1;
      &::-webkit-scrollbar {
        height: 0;
      }
    }
    .size-info-part {
      white-space: nowrap;
      display: flex;
      flex-flow: column nowrap;
      font-family: 'SF Pro';
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      color: #666666;
      box-sizing: border-box;
      &-name {
        color: #000000;
        font-weight: 590;
        padding-bottom: 4px;
      }
      &-detail {
        display: flex;
        flex-flow: row nowrap;
        .size-info-item {
          display: flex;
          flex-flow: column nowrap;
          padding-right: 12px;
          &-value {
            color: #000000;
            font-weight: 590;
          }
        }
      }
    }
  }
}
</style>
