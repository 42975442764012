<template>
  <!-- 点击展开大图 -->
  <div
    v-show="value"
    class="img-modal img-modal-review"
    :class="[value ? 'img-modal__visibility img-modal__animated img-modal__zoomIn' : '']"
    @click="close"
  >
    <div class="img-modal__big-swiper j-addbag-big-swiper">
      <div class="img-modal__swiper-outer">
        <i class="sh_m_sui_icon_nav_close_24px modal-icon-close"></i>
      </div>
      <swiper-container
        ref="swiperEl"
        class="img-modal__swiper-wrapper"
        init="false">
        <!-- eslint-disable vue/no-template-shadow -->
        <swiper-slide
          v-for="(item, index) in imgs"
          :key="index"
          class="img-modal__goods-img"
          @click="close">
          <img :src="getImageSrc(item)" />
        </swiper-slide>
      </swiper-container>
      <div
        v-if="stockTip"
        class="bsc-big-img-modal__stock-tips-wrap">
        <div
          v-expose="{
            id: 'expose_lowstock_label.comp_quick-add-cart-standard',
            data: {
              goods_level: willSoldOutTipsInfo?.scene,
              label_type: willSoldOutTipsInfo?.lowStockType == 1 ? 2 : 1,
              location: 'bigPicture',
            },
          }"
          class="bsc-big-img-modal__stock-tips"
        ><span>{{ stockTip }}</span></div>
      </div>
    </div>
  </div>
</template>
  <script lang="ts" setup>
  import { ref, toRefs, watch, nextTick, computed, inject } from 'vue'
  import { type Libs, useAppConfigs } from '@shein-aidc/bs-sdk-libs-manager'
  import { transformImg, preloadImgs } from '@shein/common-function'
  import { EmptyImg } from '@shein-aidc/basis-resource'
  import { register } from 'swiper/element'
  import { Pagination } from 'swiper/modules'
  import { BImgModalProps } from './types'
  import type { Swiper } from 'swiper'
  import { AS_QuickAddCartStandard } from '@shein-aidc/bs-quick-add-cart-standard-mobile'
  // 只在客户端环境中注册 swiper
  typeof window !== 'undefined' && register()
  const props = defineProps(BImgModalProps)
  const emits = defineEmits(['update:value'])
  const { value, index, imgs } = toRefs(props)
  const swiperInstance = ref<Swiper[] | null>(null)
  const swiperEl = ref<HTMLElement | null>(null)
  const isShowClearImg = ref(false)

  const appConfigs = useAppConfigs().$envs as Libs.Envs
  const { vExpose } = inject<AS_QuickAddCartStandard.AnalysisInstance>('analysisInstance') || {}


  watch(
    () => imgs.value,
    newVal => {
      if (newVal?.length) {
        nextTick(() => {
          initSwiper()
        })
      }
    },
  )

  watch(
    () => value.value,
    newVal => {
      if (newVal) {
        if (Array.isArray(swiperEl) && swiperInstance.value) {
          swiperInstance.value.forEach(i => i?.slideTo?.(index.value, 0, false))
          swiperInstance.value.forEach(i => i?.update?.())
        } else {
          (swiperInstance.value as unknown as Swiper)?.update?.()
          ;(swiperInstance.value as unknown as Swiper)?.slideTo?.(index.value, 0, false)
        }
        preloadImgs({
          imgs: imgs.value.map(i => transformImg({ img: i.origin_image?.replace?.('.jpg', '_thumbnail_750x.jpg') })),
        }).then(() => {
          isShowClearImg.value = true
        })
      } else {
        nextTick(() => {
          isShowClearImg.value = false
        })
      }
    },
  )

  const initSwiper = () => {
    if (!swiperEl.value) return
    const swiperParams = {
      dir: appConfigs?.cssRight ? 'rtl' : 'ltr',
      modules: [Pagination],
      injectStylesUrls: ['//sheinm.ltwebstatic.com/pwa_dist/libs/swiper/modules/pagination-element.min.css'],
      injectStyles: [
        `
        .swiper-pagination {
          font-size: 17px;
        }
      `,
      ],
      observer: true,
      observeParents: true,
      pagination: {
        type: 'fraction',
        clickable: true,
      },
    }
    Object.assign(swiperEl.value, swiperParams)
    try {
      (swiperEl.value as any).initialize()
      swiperInstance.value = (swiperEl.value as any).swiper
    } catch (error) {
      console.error('swiper init error:', error)
    }

    if (Array.isArray(swiperInstance.value)) {
      swiperInstance.value.forEach(i => i.update())
    } else {
      (swiperInstance.value as unknown as Swiper).update()
    }
  }

  const getImageSrc = item => {
    let origin = item?.origin_image || ''
    // 临时方案
    origin = origin?.replace('.webp', '.jpg')
    // 默认图直接返回
    if (EmptyImg === origin) return EmptyImg
    return isShowClearImg.value
      ? transformImg({ img: origin.replace('.jpg', '_thumbnail_750x.jpg') })
      : transformImg({ img: origin.replace('.jpg', '_thumbnail_405x552.jpg') })
  }

  const stockTip = computed(() =>{
      if(props.fsAbt?.Detailsalmostsoldout?.param?.bigpicture_lowstock === 'on') {
        return props.willSoldOutTipsInfo.tip || ''
      }
      return ''
  })

  const close = () => {
    emits('update:value', false)
  }
  </script>
  <style lang="less" scoped>
  .bsc-big-img-modal__stock-tips-wrap {
    position: absolute;
    bottom: 8vw;
    width: 100vw;
    display: flex;
    justify-content: center;
  }
  .bsc-big-img-modal__stock-tips {
      display: inline-flex;
      height: 0.5333rem;
      padding: 0px 0.32rem;
      justify-content: center;
      align-items: center;
      gap: 6px;
      flex-shrink: 0;
      color: #FF734D;
      text-align: center;
      font-family: "SF Pro";
      font-size: 0.3733rem;
      font-style: normal;
      font-weight: 400;
      line-height: 0.3733rem;
      border-radius: 0.5333rem;
      border: 0.5px solid rgba(255, 255, 255, 0.20);
      background: rgba(255, 255, 255, 0.20);
      max-width: calc(100vw - 6.4vw);
      span {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
  }
  .img-modal {
    --swiper-pagination-fraction-color:  #fff;
    --swiper-pagination-top: 0.6rem;

    width: 10rem;
    height: 100%;
    visibility: hidden;
    display: block;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    background-color: #222;
    &__big-swiper {
      height: 100%;
      position: relative;
    }
    &__goods-img {
      width: 10rem;
      height: 100%;
      margin-top: 0.6rem;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        display: block;
      }
    }
    &__swiper-outer {
      position: relative;
      top: 0.6rem;
      z-index: 299999;
      .modal-icon-close {
        position: absolute;
        left: 0.3rem /* rtl:ignore */;
        top: 0;
      }
    }
    &.img-modal-review {
      z-index: 199999;
      transform: translate3d(0, 0, 199999px);
    }

    &__swiper-wrapper {
      height: 100%;
    }
    &__visibility {
      visibility: visible;
    }
    &__animated {
      animation-duration: 1s;
      animation-fill-mode: both;
    }
    @keyframes zoomIn {
      from {
        opacity: 0;
        transform: scale3d(0.3, 0.3, 0.3);
      }

      50% {
        opacity: 1;
      }
    }
    &__zoomIn {
      animation-name: zoomIn;
      animation-duration: 0.1s;
    }
    .sh_m_sui_icon_nav_close_24px {
      display: inline-block;
      background: url(data:image/svg+xml;base64,PHN2ZyBzdHlsZT0id2lkdGg6IGF1dG87IGhlaWdodDogMWVtOyIgd2lkdGg9IjI0IiBoZWlnaHQ9IjI0IiB2aWV3Qm94PSIwIDAgMjQgMjQiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNMTkuNjAxNCA1LjQ1OTFMMTMuMDYwNiAxMS45OTkxTDE5LjYwMTQgMTguNTQwNkwxOC41NDA3IDE5LjYwMTJMMTIgMTMuMDU5OEw1LjQ1OTIyIDE5LjYwMTJMNC4zOTg1NiAxOC41NDA2TDEwLjkzOTMgMTEuOTk5MUw0LjM5ODU2IDUuNDU5MUw1LjQ1OTIyIDQuMzk4NDRMMTIgMTAuOTM4NUwxOC41NDA3IDQuMzk4NDRMMTkuNjAxNCA1LjQ1OTFaIiBmaWxsPSIjRkZGRkZGIj4KPC9wYXRoPgo8L3N2Zz4=) no-repeat;
      background-size: 100%;
      width: 24px;
      height: 24px;
    }
  }
  </style>
