<template>
  <div
    :class="[
      'goods-size-group__wrapper',
      {
        'goods-size-group__relation-wrapper': isFoldScene
      }
    ]"
  >
    <div
      aria-hidden="true"
      class="goods-size-group__title"
      @click="controlSlideExpandStatus"
    >
      <div
        :class="[
          'goods-size__title-txt',
          {
            // 'goods-size__title-bold': from === 'detail',
            'goods-size-group__title-txt': isFoldScene,
          }
        ]"
      >
        {{ title }}
      </div>
      <span
        v-if="isFoldScene"
        v-expose="{
          id: 'expose_find_your_fit.comp_sub-sales-attr',
          prefix: 'comp_sub-sales-attr',
          data: {
            location,
            goods_id: goodsId,
          },
        }"
        class="more-icon"
      >
        <Icon
          v-show="!perfectFitShow"
          name="sui_icon_more_down_12px"
          color="#222"
          size="14px"
        />
        <Icon
          v-show="perfectFitShow"
          name="sui_icon_more_up_12px"
          color="#222"
          size="14px"
        />
      </span>
    </div>
    <component
      :is="isFoldScene ? SSlide : 'div'"
      :visible="perfectFitShow"
    >
      <div
        v-expose="getAnalysisData({ type: 'expose' })"
        :class="{
          'goods-size-group__content': true,
          'perfect-fit': isFoldScene,
        }"
      >
        <ul class="goods-size__sizes choose-size">
          <li
            v-for="item in groups"
            :key="item.goodsId"
            v-tap="getAnalysisData({ type: 'click', item })"
            :class="{
              'goods-size__sizes-item': true,
              'size-active': item.isCurrentGroup == '1',
            }"
            type="text"
            @click="clickSizeGroupItem(item)"
          >
            <span class="goods-size__sizes-item-text">
              {{ item.displayDesc }}
            </span>
            <Icon
              v-if="isAfterSize || isFoldScene"
              name="sui_icon_more_right_12px_2"
              size="12px"
              :style="{
                display: 'inline-flex',
                justifyContent: 'center',
                alignItems: 'center',
              }"
            />
          </li>
        </ul>
      </div>
    </component>
  </div>
</template>

<script setup>
import { computed, defineProps, ref, watch } from 'vue'

import { SSlide } from '@shein-aidc/sui-slide/mobile'
import { Icon } from '@shein-aidc/icon-vue3'

import analysisInstance from '../utils/useAnalysisSdk.js'
const { vTap, vExpose, triggerNotice, getExposeInstance } = analysisInstance || {}

const daEventExpose = getExposeInstance?.() || {}
const props = defineProps({
  exposeCodeMap: {
    type: Object,
    default: () => ({}),
  },
  saleAttrGroups: {
    type: Object,
    default: () => ({}),
  },
  language: {
    type: Object,
    default: () => ({}),
  },
  sizeGroupAbt: {
    type: String,
    default: '',
  },
  goodsId: {
    type: String,
    default: '',
  },
  isFromDetailPage: {
    type: Boolean,
    default: false,
  },
  globalPerfectFitShow: {
    type: Boolean,
    default: false,
  },
  handleUpdateGlobalPerfectFitShow: {
    type: Function,
    default: () => {},
  },
  handleClickSizeGroupItem: {
    type: Function,
    default: () => {},
  },
})
const location = props.isFromDetailPage ? 'page' : 'popup'
const exposeCode = props.exposeCodeMap?.sizeGroupList || ''
const isFoldScene = computed(() => props.sizeGroupAbt === 'fold')
const isAfterSize = computed(() => props.sizeGroupAbt === 'size')
const title = computed(() => isFoldScene.value ? props.language?.SHEIN_KEY_PWA_30032 : props.saleAttrGroups?.title || '')
const groups = computed(() => {
  if (isFoldScene.value || isAfterSize.value) {
    return props.saleAttrGroups?.groups?.filter(item => item.isCurrentGroup !== '1') || []
  }
  return props.saleAttrGroups?.groups || []
})
const controlSlideExpandStatus = () => {
  if (!isFoldScene.value) return
  perfectFitShow.value = !perfectFitShow.value
  props.handleUpdateGlobalPerfectFitShow?.(perfectFitShow.value)
  if(perfectFitShow.value) { // 收起时重置曝光
    daEventExpose.reset(exposeCode)
  }
  triggerNotice({
    id: 'click_find_your_fit.comp_sub-sales-attr',
    extraData: {
      goods_id: props.goodsId,
      click_type: perfectFitShow.value ? 1 : 0,
      location: props.isFromDetailPage ? 'page' : 'popup',
    },
  })
}
const getAnalysisData = ({ type = 'click', item }) => {
  let goods_size_list = '' // 拼接规则 goods_id`size_group`theme_type
  if(item) {
    const { theme_type = '', displayDesc = '', goodsId = '' } = item
    goods_size_list = `${goodsId}\`${displayDesc}\`${theme_type || ''}`
  } else {
    goods_size_list = groups.value?.map?.(item => `${item.goodsId}\`${item.displayDesc}\`${item.theme_type || ''}`).join(',')
  }
  const size_group_style = isAfterSize.value ? 'size_attr' : (isFoldScene.value ? 'find_your_fit' : 'size_group')
  const data = {
    location,
    source_goods_id: props.goodsId,
    size_group_style,
    goods_size_list,
  }
  return {
    id: type === 'click' ? 'click_size_group.comp_sub-sales-attr' : 'expose_size_group.comp_sub-sales-attr',
    prefix: 'comp_sub-sales-attr',
    data,
    code: exposeCode,
  }
}
const clickSizeGroupItem = item => {
  props.handleClickSizeGroupItem?.({
    ...item,
    openQuickCart: isFoldScene.value || isAfterSize.value,
  })
}
const perfectFitShow = ref(false)
watch(() => props.globalPerfectFitShow, () => {
  perfectFitShow.value = props.globalPerfectFitShow || false
}, { immediate: true })
</script>

<style lang="less">
.goods-size-group {
  &__wrapper {
    margin-bottom: 1vw;
    .size-group-desc{
      margin-left: 1.06vw;
    }
  }
  &__relation-wrapper {
    margin-bottom: 2.133vw;
  }
  &__title {
    display: flex;
    align-items: center;
    line-height: 4.3vw;
    .more-icon{
        margin-left: 0.533vw;
    }
  }
  &__title-txt {
    color: #222;
    font-size: 14px !important;/* stylelint-disable-line declaration-no-important */
  }
  &__content {
    margin-top: 1.5vw;
    .goods-size__sizes-item {
      display: flex;
      max-width: 46%;
    }
    .goods-size__sizes-item-text {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
.perfect-fit {
  .goods-size__sizes-item {
    display: flex;
    max-width: 46%;
  }
  .goods-size__sizes-item-text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>
