import { Libs, useAppConfigs } from '@shein-aidc/bs-sdk-libs-manager'
import type { DS_QuickAddCartStandard } from '../../../types'
import { reactive, readonly, toRefs } from 'vue'
import Logger from '../utils/logger'

const modules = reactive<DS_QuickAddCartStandard.Modules>({
  priceInfo: undefined,
  productInfo: undefined,
  staticSaleAttr: undefined,
  saleAttr: undefined,
  comment: undefined,
  storeInfo: undefined,
  language: undefined,
  detailPromotionFloor: undefined,
  userInfo: undefined,
  fsAbt: undefined,
  gbCommonInfo: useAppConfigs().$envs as Libs.Envs,
  mallInfo: undefined,
  beltInfo: undefined,
  productLabel: undefined,
  shipInfo: undefined,
  addCartInfo: undefined,
  couponInfo: undefined,
})

const mergeProductInfo = (coldModules: DS_QuickAddCartStandard.GoodsDetailStaticData, otherModules: DS_QuickAddCartStandard.GoodsDetailOtherData) => {
  const productInfo = coldModules.productInfo
  if (productInfo && productInfo.customization_attributes) {
    productInfo.__isCustomization = productInfo.customization_attributes.customization_flag === 1
    productInfo.__isNewProductUnSale = productInfo.isNewProductUnSale === '1'
  }
  // 混入细节图
  if (otherModules?.detailImages?.length && productInfo?.currentSkcImgInfo) {
    productInfo.currentSkcImgInfo.detailImages = otherModules?.detailImages || []
  }
  return productInfo
}

const updateModules = ({
  coldModules,
  hotModules,
  otherModules,
  abtModules,
}: DS_QuickAddCartStandard.GoodsDetailModules) => {
  modules.staticSaleAttr = coldModules.saleAttr
  modules.productInfo = {
    ...mergeProductInfo(coldModules, otherModules),
    is_on_sale: hotModules.productInfo?.is_on_sale,
    is_saved: hotModules.productInfo?.is_saved,
    stock: hotModules.productInfo?.stock,
    cccDetailsTemplate: hotModules.productInfo?.cccDetailsTemplate,
    giftGoodsSelectId: hotModules.productInfo?.giftGoodsSelectId,
  } as DS_QuickAddCartStandard.ProductInfo
  modules.saleAttr = hotModules.saleAttr
  modules.priceInfo = hotModules.priceInfo
  modules.detailPromotionFloor = hotModules.detailPromotionFloor
  modules.userInfo = hotModules.userInfo
  modules.fsAbt = abtModules?.detailAbtInfoList?.find(item => item.goodsId === coldModules.productInfo?.goods_id)?.webDetailAbtInfo || hotModules.webProductDetailAbtInfo || {}
  modules.mallInfo = coldModules.mallInfo
  modules.storeInfo = { ...coldModules.storeInfo, ...hotModules.storeInfo }
  modules.beltInfo = hotModules.beltInfo
  modules.productLabel = { ...coldModules.productLabel, ...hotModules.productLabel}
  modules.shipInfo = hotModules.shipInfo
  modules.addCartInfo = hotModules.addCartInfo
  modules.couponInfo = hotModules.completeCouponInfo?.cmpCouponInfo?.cmpCouponInfoList
  Logger.log('modules', modules)
}

const updateLanguage = (language: Record<string, string| undefined>) => {
  modules.language = language
}

/**
 * 更新商品收藏状态
 * @description 收藏状态比较特殊，仅限这个方法可以修改 modules 里面的 数据
 * @param status
 */
export const updateProductWishStatus = (status: string) => {
  if (modules.productInfo) {
    modules.productInfo = {
      ...modules.productInfo,
      is_saved: status,
    }
  }
}

/**
 * 获取 modules
 * @description 只允许在根组件中使用
 * @description 其余组件请使用 props 的形式传入
 */
const useModules = () => {
  // 限制 modules 只读
  // return readonly(toRefs(modules)) as unknown as Readonly<ToRefs<Omit<DS_QuickAddCartStandard.Modules, 'language'> & { language?: Record<LANGUAGE_KEY , string | undefined> }>>
  return toRefs(readonly(modules)) as unknown as DS_QuickAddCartStandard.QuickAddCartModules
}

const clearModules = () => {
  modules.priceInfo = undefined
  modules.productInfo = undefined
  modules.staticSaleAttr = undefined
  modules.saleAttr = undefined
  modules.comment = undefined
  modules.storeInfo = undefined
  modules.detailPromotionFloor = undefined
  modules.userInfo = undefined
  modules.fsAbt = undefined
  modules.gbCommonInfo = useAppConfigs().$envs as Libs.Envs
  modules.mallInfo = undefined
  modules.beltInfo = undefined
  modules.productLabel = undefined
}

export { updateModules, useModules, clearModules, updateLanguage }
