<script lang="jsx">
import { ClientOnly } from '@sheinfe/vue-client-only'
import { SSlide } from '@shein-aidc/sui-slide/mobile'
import SizeBubble from './SizeBubble.vue'
import { Icon } from '@shein-aidc/icon-vue3'
import analysisInstance from '../utils/useAnalysisSdk.js'
const { vTap, vExpose } = analysisInstance || {}

export default {
  name: 'SizeSlideTips',
  functional: true,
  directives: {
    expose: vExpose,
    tap: vTap,
  },
  props: {
    showDesc: {
      type: Boolean,
      default: false,
    },
    showNewBubbleStyle: {
      type: Boolean,
      default: false,
    },
    soldOutTips: {
      type: String,
      default: '',
    },
    localSizeDescText: {
      type: String,
      default: '',
    },
    attrDescPopUp: {
      type: Array,
      default: () => [],
    },
    sizeInfoTitle: {
      type: String,
      default: '',
    },
    showSizeAttributeEntrance: {
      type: Boolean,
      default: false,
    },
    sizeUnit: {
      type: String,
      default: '',
    },
    isFromPopup: {
      type: Boolean,
      default: false,
    },
    sizeConstant: {
      type: Object,
      default: () => {},
    },
    analysisData: {
      type: Object,
      default: () => {},
    },
    handleSetSizeRefs: {
      type: Function,
      default: () => {},
    },
  },
  emits: ['handleOpenSizeGuideFn'],
  setup(props, { emit }) {
    return () => {
      const {
        showDesc,
        soldOutTips,
        attrDescPopUp = [],
        sizeUnit = '',
        localSizeDescText = '',
        showNewBubbleStyle = false,
        sizeInfoTitle,
        showSizeAttributeEntrance,
        isFromPopup,
        analysisData,
        sizeConstant,
        handleSetSizeRefs,
      } = props
      const { cssRight } = sizeConstant || {}
      const { stockLogInfo } = analysisData || {}
      const stockTips = () => {
        if (!soldOutTips) return null
        const tipDom = <span
          class="tips"
          v-expose={{
            id: 'expose_lowstock_label.comp_sub-sales-attr',
            prefix: 'comp_sub-sales-attr',
            data: {
              ...(stockLogInfo || {}),
              location: 'otherattr',
            },
          }}
          >
          {soldOutTips}
        </span>
        if (showNewBubbleStyle) {
          return <div class="goods-size__item-bubble-info-header">
            <Icon
              name="sui_icon_hourglass_12px"
              class="hourglass-icon"
              size="12px"
              color="#FA6338"
              style="margin-right: 2px"
            />{tipDom}
          </div>
        }
        return tipDom
      }
      const handleOpenSizeGuide = () => {
        if(showSizeAttributeEntrance){
          emit('handleOpenSizeGuideFn', 'SizeSlideTips', isFromPopup ? 'cart_pop' : 'detail_pop', isFromPopup ? 4 : 3)
          // listeners['handleOpenSizeGuideFn']('SizeSlideTips', isFromPopup ? 'cart_pop' : 'detail_pop')
        }
      }
      const isMoreThan = attrDescPopUp.length > 3
      const isLessThan =
        (attrDescPopUp.length > 1 && attrDescPopUp.length <= 3) ||
        (attrDescPopUp.length == 1 && attrDescPopUp[0].attrNameKey)
      const sizeInfo = attrDescPopUp?.map((info) => {
        const infoName =
          info.multiPartName && attrDescPopUp.length > 1 ? (
            <span class="sizeinfo-name">{info.multiPartName} -</span>
          ) : null

        const infoDetail = info?.bindAttrData?.map((item) => {
          const { attrNameValueCm, attrNameValueInch, attrNameKey } = item || {}
          return (
            <span class="sizeinfo-one">
              <span class="sizeinfo-t">{attrNameKey}:</span>
              { sizeUnit === 'inch' ? attrNameValueInch : attrNameValueCm }
            </span>
          )
        })
        return (
          <span class={[{ morethan3: isMoreThan }, { lessthan3: isLessThan }]}>
            {infoName}
            {infoDetail}
          </span>
        )
      })

      const slideContent = () =>
        attrDescPopUp?.length ? (
          <div class="goods-size__content-container" onClick={handleOpenSizeGuide}>
            <div class="goods-size__mesurement-container">
              {!localSizeDescText ? <div
                class="mesurement-title"
              >
                {sizeInfoTitle}
              </div> : <div
                style={{ paddingTop: soldOutTips ? '6px' : '0' }}
                class="goods-size__one-desc"
                innerHTML={localSizeDescText}
              ></div>}
              <div>{sizeInfo}</div>
            </div>
            {showSizeAttributeEntrance && <Icon name="sui_icon_more_right_16px" size="16px" is-rotate={cssRight} />}
          </div>
        ) : null

      const slideWrap = () => {
        if (!showDesc) return null
        if (showNewBubbleStyle) {
          return <SizeBubble
            sizeConstant={sizeConstant}
            sizeUnit={sizeUnit}
            attrDescPopUp={attrDescPopUp}
            handleOpenSizeGuide={handleOpenSizeGuide}
            showSizeAttributeEntrance={showSizeAttributeEntrance}
          >
            {stockTips()}
            {localSizeDescText ? <div style={{ paddingTop: soldOutTips ? '2px' : '0' }} class="goods-size__one-desc" innerHTML={localSizeDescText}></div> : null}
          </SizeBubble>
        }
        return <div
          class="goods-size__item-info"
          aria-hidden="true"
        >
          {stockTips()}
          {slideContent()}
        </div>
      }

      return <ClientOnly>
          <SSlide
            ref={handleSetSizeRefs ? handleSetSizeRefs('bubble', 'slideDesc') : () => {}}
            class={[{ 'goods-size__slide-common': showDesc }]}
            visible={showDesc}
          >
            {slideWrap()}
          </SSlide>
        </ClientOnly>
    }
  },
}
</script>

<style lang="less" scoped>
.goods-size{
  &__content-container{
    display: flex;
    align-items: center;
    gap: 2.66666vw;
  }
  &__mesurement-container{
    flex: 1;
  }
  &__one-desc{
    color: #000000;
    line-height: normal;
    margin-bottom: 6px;
  }
}
</style>
