<template>
  <Drawer
    v-model:visible="showLocalSize"
    class="c-drawer-local-size-select"
    direction="btt"
    :append-to-body="appendToBody"
    @open="drawerChange(true)"
    @close="drawerChange(false)"
  >
    <div class="goodsd__local-title">
      {{ language.SHEIN_KEY_PWA_16223 }}
    </div>
    <div class="goodsd__local-sizeWrap">
      <div
        v-if="isNewSizeLocal"
        class="goodsd__local-size"
        :class="{ 'goodsd__local-select': !target }"
        @click="clickHandle('')"
      >
        <div style="display: flex;gap: 2px">
          {{ language.SHEIN_KEY_PWA_23345 }}
          <span class="goodsd__local-sub">({{ language.SHEIN_KEY_PWA_23343 }})</span>
        </div>
        <Icon
          v-if="!target"
          name="sui_icon_selected_12px"
          size="16px"
        />
      </div>
      <div
        v-for="item in localSizeSelect"
        :key="item"
        class="goodsd__local-size"
        :class="{ 'goodsd__local-select': target === item }"
        :data-country="item"
        :da-event-click="reportSelect ? '1-6-1-23' : null"
        @click="clickHandle(item)"
      >
        {{ item }}
        <Icon
          v-if="target === item"
          name="sui_icon_selected_12px"
          size="16px"
        />
      </div>
    </div>
    <div class="goodsd__local-margin"></div>
    <div
      class="goodsd__local-cancel"
      @click="showLocalSize = false"
    >
      {{ language.SHEIN_KEY_PWA_15254 }}
    </div>
  </Drawer>
</template>
<script>
import { SDrawer as Drawer } from '@shein-aidc/sui-drawer/mobile'
import { Icon } from '@shein-aidc/icon-vue3'
import { getSource } from '../../../../common/dataSource'
import { useAppConfigs } from '@shein-aidc/bs-sdk-libs-manager'
// TODO
// import schttp from 'public/src/services/schttp'
/**
 * @param { Object } callbacks 回调
 *   @param { Function } change 尺码选择更改
 *   @param { Function } opened 打开回调
 *   @param { Function } closed 关闭回调
 */
export default {
  name: 'SelectLocalSize',
  components: {
    Drawer,
    Icon,
  },
  data() {
    return {
      id: '',
      config: {},
      language: {},
      list: [],
      target: '',
      showLocalSize: false,
      callbacks: {},
      localSize: [],
    }
  },
  computed: {
    localSizeSelect() {
      return this.localSize?.map?.(item => item?.country_code) || []
    },
    storeSelected() {
      return !!this.config.storeSelected || true
    },
    reportSelect() {
      return !!this.config.reportSelect
    },
    appendToBody() {
      return !!this.config.appendToBody
    },
    isNewSizeLocal() {
      return !!this.config.isNewSizeLocal
    },
  },
  methods: {
    async open({ id = '', localSize = [], value = '', options }) {
      if (!id) {
        console.warn('id is not empty!')
        return
      }
      if (!localSize?.length) {
        console.warn('localSize is not empty!')
        return
      }
      this.setPopBaseData(localSize, options)
      this.id = id
      this.target = value
      await this.$nextTick()
      this.showLocalSize = true
    },
    close() {
      this.showLocalSize = false
    },
    setPopBaseData(localSize, options = {}) {
      const { config = {}, callbacks = {}, language = {} } = options
      this.localSize = localSize
      this.config = config
      this.language = language
      this.callbacks = callbacks
    },
    clickHandle(index) {
      this.target = index
      this.callbacks?.change?.(index)
      if (this.storeSelected) {
        window.localStorage.setItem('last_select_country', index || 'default')
      }
      this.showLocalSize = false
      // TODO: 不知道后面中间层融合要不要迁移 + 下沉
      const appConfigs = useAppConfigs()
      const apis = getSource({ appConfigs })
      apis.updateBusineseCache({
        cacheData: index || 'default',
        scanType: 'detail_local_country',
      })
    },
    drawerChange(val) {
      if (val) return this.callbacks?.opened?.()
      return this.closeHandle()
    },
    closeHandle() {
      this.callbacks?.closed?.()
      this.unbind()
    },
    unbind() {
      this.callbacks = {}
    },
  },
}
</script>
<style lang="less">
.goodsd {
  &__local-sizeWrap {
    max-height: 35vw;
    overflow-y: auto;
  }
  &__local-size {
    font-size: 14px;
    padding: 3.2vw;
    position: relative;
    display: flex;
    justify-content: space-between;
  }
  &__local-sub {
    color: #959595;
    font-weight: 400;
  }
  &__local-margin {
    background: #f6f6f6;
    height: 15.2vw;
  }
  &__local-select {
    font-weight: 700;
  }
  &__local-cancel {
    padding: 4.13vw 0;
    text-align: center;
    position: absolute;
    bottom: 0;
    background: #fff;
    width: 100%;
    font-size: 14px;
  }
  &__local-title {
    color: #999;
    font-size: 14px;
    text-align: center;
    padding: 3.06vw 0;
    position: relative;
    border-bottom: 1px solid rgba(229, 229, 229, 0.83);

    .icon-close {
      position: absolute;
      top: 1.2vw;
      font-size: 12px;
      color: #999;
      right: 1.8vw;
    }
  }
}
</style>
