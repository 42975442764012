<template>
  <div class="bsc-size-tools">
    <div class="bsc-size-tools__left">
      <slot name="left"></slot>
    </div>
    <div class="bsc-size-tools__right">
      <!-- local size select -->
      <slot name="right"></slot>
      <!-- size unit switch -->
      <ul
        class="bsc-size-unit-switch"
        :class="{ 'switch-reverse': defaultSizeUnit === 'cm' }">
        <li
          v-tap="{
            id: 'click_change_unit.comp_size-guide',
            data: {
              unit: 'inch',
            }
          }"
          :class="{ 'unit-active': sizeUnit === 'inch' }"
          da-event-click="1-6-1-25"
          data-unit="IN"
          @click="changeUnit('inch')">
          IN
        </li>
        <li
          v-tap="{
            id: 'click_change_unit.comp_size-guide',
            data: {
              unit: 'cm',
            }
          }"
          :class="{ 'unit-active': sizeUnit === 'cm' }"
          da-event-click="1-6-1-25"
          data-unit="CM"
          @click="changeUnit('cm')">
          CM
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup name="BSizeTools" lang="ts">
import { inject } from 'vue'
import { defineProps, defineEmits } from 'vue'
import { AS_SizeGuide } from '@shein-aidc/bs-size-guide-mobile'

const { vTap } = inject<AS_SizeGuide.AnalysisInstance>('analysisInstance') || {}
const props = defineProps<{
  sizeUnit: string
  defaultSizeUnit: string
}>()

const emit = defineEmits<{
  (event: 'size-unit-change', unit: string): void
}>()

function changeUnit(unit: string) {
  if (unit === props.sizeUnit) return
  emit('size-unit-change', unit)
}
</script>

<style lang="less">
.bsc-size-tools {
  display: inline-flex;
  width: 100%;
  margin-bottom: 2.2vw;
  padding: 0 3.2vw;
  height: 12.2vw;
  background-color: #fff;
  justify-content: space-between;
  align-items: center;

  &__left {
    font-weight: 500;
    font-size: 3.8vw;
    line-height: 8.6vw;
    color: #333;
  }

  &__right {
    display: inline-flex;
    align-items: center;
  }
}

.bsc-size-unit-switch {
  width: 18.2vw;
  height: 7vw;
  padding: 1.6vw;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #e5e5e5;
  border-radius: 0.4267rem;
  background-color: #f6f6f6;
  margin-left: 3.2vw;

  &.switch-reverse {
    flex-direction: row-reverse;
  }

  li {
    width: 8vw;
    height: 5.2vw;
    line-height: 5.2vw;
    text-align: center;
    color: #222;
    flex-shrink: 1;

    &.unit-active {
      background: #222;
      color: #fff;
      border-radius: 5.2vw;
      flex-grow: 1;
      flex-shrink: 0;
    }
  }
}
</style>
