<template>
  <SDialog
    :visible="visible"
    role="text"
    :aria-label="language?.SHEIN_KEY_PWA_15060"
    aria-live="polite"
    :lock-scroll="false"
  >
    <div class="bsc-quick-add-cart__dialog">
      <Icon
        name="sui_icon_success_40px"
        class="bsc-quick-add-cart__dialog-icon"
        color="#198055"
        width="45px"
        height="45px" />
      {{ language?.SHEIN_KEY_PWA_15060 }}!
    </div>
  </SDialog>
</template>

<script name="AddCartSuccess" setup lang="ts">
/**
 * 加车成功弹窗
 */
import { ref } from 'vue'
import { SDialog } from '@shein-aidc/sui-dialog/mobile'
import { Icon } from '@shein-aidc/icon-vue3'
import { DialogOptions } from './types'
import { DS_QuickAddCartStandard } from '@shein-aidc/bs-quick-add-cart-standard-mobile'
const language = ref<Record<DS_QuickAddCartStandard.LANGUAGE_KEY , string | undefined> | undefined>(undefined)
const visible = ref<boolean>(false)
const onClose = ref<(() => void)| undefined>(undefined)
const open = (options: DialogOptions) => {
    language.value = options.language
    onClose.value = options.onClose
    visible.value = true
    if(options?.duration) {
        setTimeout(() => {
            close()
        }, options.duration)
    }
}
const close = () => {
    visible.value = false
    onClose.value && onClose.value()
}

defineExpose({ open, close })
</script>


<style lang="less">
.bsc-quick-add-cart {
  &__dialog {
    display: flex;
    flex-direction: column;
    text-align: center;
    padding-top: 1.5vw;
    padding-bottom: 4.7vw;
    font-size: 12px;
    line-height: 1.5;
  }
  &__dialog-icon {
    margin-bottom: 2vw;
  }
}
</style>
