<template>
  <div class="productLowestPriceTips">
    <template v-if="lowestPriceTips.text">
      <Icon
        name="sui_icon_lowest_price_12px_2"
        size="12px"
        color="#C44A01" />
      <p
        v-expose="{
          id: 'expose_pricestatus.comp_quick-add-cart-standard',
          data: {
            ...lowestPriceTips.analyzeData
          }
        }"
        class="productLowestPriceTips__text"
      >{{ lowestPriceTips.text }}</p>
    </template>
    <p
      v-if="lowestPriceTips.text && lowestPriceTips.taxIncludedText"
      class="productLowestPriceTips__split"
    >·</p>
    <p
      v-expose="{id: 'expose_taxcontent.comp_quick-add-cart-standard'}"
      class="productLowestPriceTips__tax">{{ lowestPriceTips.taxIncludedText }}</p>
  </div>
</template>

<script setup name="LowestPriceTips">
import { Icon } from '@shein-aidc/icon-vue3'
import { inject } from 'vue'

const { vExpose } = inject('analysisInstance')

defineProps({
  lowestPriceTips: {
    type: Object,
    default: () => ({}),
  },
})
</script>

<style lang="less">
.productLowestPriceTips {
  display: flex;
  align-items: center;
  font-size: 10px;
  margin-bottom: .1067rem;
  line-height: 1;
  &__text {
    margin-left: .1067rem;
    font-size: 10px;
    color: #c44a01;
  }
  &__split {
    margin: 0 .1067rem;
  }
  &__tax {
    font-size: 10px;
    color: #666;
  }
}
</style>
