export function DisableButton(text) {
  return (
    <button
      class="mshe-btn-black call-app-btn-disabled"
      role="button"
      aria-label="disabled"
    >
      {text || 'ADD TO CART'}
    </button>
  )
}
