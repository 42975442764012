import { createApp } from 'vue'
let compInstance = null
export default {
  open: (config) => {
    if (compInstance) {
      compInstance.open(config)
      return compInstance
    }
    return new Promise((resolve) => {
      import(/* webpackChunkName: "select_local_size" */ './main.vue').then((components) => {
        const selectLocalSize = components.default
        const tempDiv = document.createElement('div')
        const comp = createApp(selectLocalSize).mount(tempDiv)
        document.body.appendChild(tempDiv)
        comp.open(config)
        compInstance = comp
        resolve(compInstance)
      })
    })
  },
  close: () => {
    if (compInstance && compInstance.close) {
      compInstance.close()
    }
  },
}
