<template>
  <div>
    <s-drawer
      v-model:visible="isVisible"
      class="bsc-size-guide-drawer-wrapper"
      :no-header="true"
      type="full"
      direction="rtl"
      :append-to-body="true">
      <div
        v-cloak
        class="bsc-size-guide-drawer"
        :class="{ 'sizeGuidePlanA-drawer': sizeGuidePlanA }">
        <section>
          <header class="bsc-size-guide-drawer__header">
            <Icon
              name="sui_icon_nav_back_24px"
              class="j-clear-history-hash bsc-size-guide-drawer__header__back_icon"
              width="6.8vw"
              height="6.8vw"
              :is-rotate="useAppConfigs().$envs?.cssRight"
              @click="closeDrawer"
            />
            {{ language.SHEIN_KEY_PWA_15005 }}
          </header>
        </section>
        <section class="bsc-size-guide-drawer__content">
          <!-- size tools -->
          <SizeTools
            :language="language"
            :default-size-unit="defaultSizeUnit"
            :sizeUnit="currentSizeUnit"
            @size-unit-change="handleSelectSizeUnit"
          >
            <template #left>
              {{ language.SHEIN_KEY_PWA_17557 }}
            </template>
            <template #right>
              <div
                v-if="sizeCountryList?.length"
                class="bsc-local-size-select"
                @click="localSizeSelectHandle(true)">
                <span>
                  <template v-if="currentCountry"> {{ currentCountry }} {{ language.SHEIN_KEY_PWA_15701 }} </template>
                  <template v-else>
                    {{ language.SHEIN_KEY_PWA_23344 }}
                  </template>
                  <Icon
                    name="sui_icon_more_down_12px_2"
                    width="3.2vw"
                    height="3.2vw" />
                </span>
              </div>
            </template>
          </SizeTools>
          <!-- product desc list -->
          <div
            v-if="productDescList.length"
            class="fit-fabric">
            <ProductDesc
              v-for="(desc, index) in productDescList"
              :key="index"
              :desc="desc"
              :desc-title="getProductDescTitle(desc.attr_id)"
              :desc-values="getProductDescValues(desc.attr_id)"
              :size-guide-plan-a="sizeGuidePlanA"
            />
          </div>
          <!-- size table -->
          <SizeTable
            :visible="isVisible"
            :language="language"
            :size-sort-map="sizeSortMap"
            :localSizes="localSizes"
            :length-unit="currentSizeUnit"
            :size-template="saleAttr?.sizeTemplate"
            :size-info-desc="saleAttr?.sizeInfo"
            :size-priority-abt="sizePriorityAbt"
            :country="currentCountry"
            :sizeGuidePlanA="sizeGuidePlanA"
            :is-collapse-measure="isCollapseMeasure"
          />
          <!-- modal intro -->
          <ModelIntro
            v-if="sizeGuidePlanA && productInfo?.models_info"
            :language="language"
            :localSizes="localSizes"
            :currentLocalCountry="currentCountry"
            :modelInfo="productInfo?.models_info"
            :sizeGuidePlanA="sizeGuidePlanA"
            :sizeUnit="sizeUnit"
          />
          <!-- buyer size table -->
          <BuyerSizeTable
            v-if="isShowBuyerSizeTable"
            style="margin-top: 8px"
            weight-unit="kg"
            :visible="isVisible"
            :spu-id="productInfo?.productRelationID"
            :skc="productInfo?.goods_sn"
            :language="language"
            :size-sort-map="sizeSortMap"
            :localSizes="localSizes"
            :length-unit="currentSizeUnit"
            :country="currentCountry"
            @table-change="handleBuyerTableChange"
          />
        </section>
      </div>
    </s-drawer>
  </div>
</template>

<script lang="ts">
import { AS_SizeGuide, DS_SizeGuide } from '../../types'
import { provide, defineComponent, watch, ref, PropType, computed } from 'vue'
import { SDrawer as Drawer } from '@shein-aidc/sui-drawer/mobile'
import { Icon } from '@shein-aidc/icon-vue3'
import { DS_QuickAddCartStandard } from '@shein-aidc/bs-quick-add-cart-standard-mobile'
import ProductDesc from './components/ProductDesc.vue'
import BuyerSizeTable from './components/BuyerSizeTable/index.vue'
import SizeTools from './components/SizeTools.vue'
import { useAnalysis } from '../../common/analysisSource'
import LocalSizeSelect from './components/LocalSizeSelect'
import ModelIntro from './components/ModelIntro.vue'
import SizeTable from './components/SizeTable/index.vue'
import { useAppConfigs } from '@shein-aidc/bs-sdk-libs-manager'


export default defineComponent({
  name: 'BSizeGuide',
  components: {
    's-drawer': Drawer,
    Icon,
    ProductDesc,
    SizeTable,
    BuyerSizeTable,
    SizeTools,
    ModelIntro,
  },
  props: {
    fsData: {
      type: Object as PropType<DS_SizeGuide.FS_DATA>,
      default: () => ({}),
    },
    dataSource: {
      type: Object as PropType<DS_SizeGuide.DataSource>,
      default: () => ({}),
    },
    analysisSource: {
      type: Object as PropType<AS_SizeGuide.AnalysisSource>,
      default: () => ({}),
    },
    value: {
      type: Boolean,
      default: false,
    },
    language: {
      type: Object as PropType<DS_SizeGuide.LANGUAGE>,
      default: () => ({}),
    },
    productInfo: {
      type: Object as PropType<DS_QuickAddCartStandard.ProductInfo>,
      default: () => ({}),
    },
    productLabel: {
      type: Object as PropType<DS_QuickAddCartStandard.ProductLabel>,
      default: () => ({}),
    },
    saleAttr: {
      type: Object as PropType<DS_QuickAddCartStandard.SaleAttr>,
      default: () => ({}),
    },
    country: {
      type: String,
      default: '',
    },
    fsAbt: {
      type: Object as PropType<DS_QuickAddCartStandard.FsAbt>,
    },
    sizeUnit: {
      type: String as PropType<'cm' | 'inch'>,
      default: 'cm',
    },
  },
  emits: ['update:value', 'local-change', 'size-unit-change'],
  // eslint-disable-next-line max-lines-per-function
  setup(props, { emit }) {
    const analysisInstance = useAnalysis(props?.analysisSource)
    provide('analysisInstance', analysisInstance)
    const isVisible = ref(false)
    const isCollapseMeasure = ref(true)
   // const sizeGuidePlanA = computed(() => {
    //   return props.fsAbt?.sizeguideyouhua520?.param?.sizeguideyouhua520 === 'planA'
    // })
    // abt 推全了 planA 分支
    const sizeGuidePlanA = true
    const productDescList = computed(() => {
      let result: DS_QuickAddCartStandard.ProductDescInfo[] = []
      const productDetails = props.productInfo.productDescriptionInfo?.completeProductDetails || []
      const fitDesc = productDetails.find(item => {
        return (item.attr_name_en === 'Fit Type') && item.attr_value_en
      })
      if(fitDesc) {
        result.push(fitDesc)
      }
      const fabricDesc = productDetails.find(item => {
        return (item.attr_name_en === 'Fabric') && item.attr_value_en
      })
      if(fabricDesc) {
        result.push(fabricDesc)
      }else {
        const stretchDesc = productDetails.find(item => {
          return (item.attr_name_en === 'Stretch') && item.attr_value_en
        })
        if(stretchDesc) {
          result.push(stretchDesc)
        }
      }
      return result
    })

    const sizeInfo = computed(() => {
      return props.saleAttr?.multiLevelSaleAttribute?.skc_sale_attr?.find(item => item.attr_id === '87')
    })

    const localSizes = computed(() => {
      return sizeInfo.value?.attr_value_list?.reduce((acc, cur) => {
        acc[cur.attr_value_name_en] = cur.attr_value_local_size
        return acc
      }, {} as Record<string, DS_QuickAddCartStandard.AttrValueLocalSize[] | undefined>)
    })

    /**
     * 默认尺码单位
     */
    const defaultSizeUnit = computed(() => {
      return sizeInfo.value?.defaultSizeUnit === '1' ? 'inch' : 'cm'
    })

    const sizePriorityAbt = computed(() => {
      return props.fsAbt?.sizepriority?.param?.sizepriority || 'off'
    })

    const isShowBuyerSizeTable = computed(() => {
      return props.fsAbt?.sizeguidefit?.param?.sizeguidefit === 'planA' && props.productLabel?.isSizeGuideShowMatchModules === '1'
    })

    const currentCountry = computed(() => {
      if(props.country && sizeCountryList.value) {
        return sizeCountryList.value.find(item => item.country_code === props.country)?.country_code || ''
      }
      return ''
    })

    /**
     * 用户选择的尺码单位
     */
    const userSelectedSizeUnit = ref<'cm' | 'inch' | undefined>(props.sizeUnit)

    const currentSizeUnit = computed(() => {
      return userSelectedSizeUnit.value || defaultSizeUnit.value
    })

    const sizeCountryList = computed(() => {
      return sizeInfo.value?.attr_value_size_country
    })

    const sizeSortMap = computed(() => {
      return sizeInfo.value?.attr_value_list?.reduce((acc, cur, index) => {
        acc[cur.attr_value_name_en] = index
        return acc
      }, {} as Record<string, number>)
    })

    function handleSelectSizeUnit(unit: string) {
      userSelectedSizeUnit.value = unit as 'cm' | 'inch'
      localStorage.setItem('selectedUnit', unit)
      emit('size-unit-change', unit)
    }

    const fabric = computed(() => {
      if (!Object.keys(props.language).length) return []
      return [
        {
          key: 'Non-Stretch',
          value: props.language.SHEIN_KEY_PWA_18060,
        },
        {
          key: 'Slight Stretch',
          value: props.language.SHEIN_KEY_PWA_18061,
        },
        {
          key: 'Medium Stretch',
          value: props.language.SHEIN_KEY_PWA_18062,
        },
        {
          key: 'High Stretch',
          value: props.language.SHEIN_KEY_PWA_18063,
        },
      ]
    })

    const fitType = computed(() => {
      if (!Object.keys(props.language).length) return []
      return [
        {
          key: 'Skinny',
          value: props.language.SHEIN_KEY_PWA_17558,
        },
        {
          key: 'Slim Fit',
          value: '',
        },
        {
          key: 'Regular Fit',
          value: props.language.SHEIN_KEY_PWA_17559,
        },
        {
          key: 'Loose',
          value: '',
        },
        {
          key: 'Oversized',
          value: props.language.SHEIN_KEY_PWA_17560,
        },
      ]
    })
    watch(
      () => props.value,
      val => {
        isVisible.value = val
        // 打开size guide时，获取用户选择的尺码单位
        if (val) {
          const data = localStorage.getItem('selectedUnit') || undefined
          userSelectedSizeUnit.value = props.sizeUnit || data as 'cm' | 'inch'
        }
      },
    )

    watch(() => props.productInfo?.models_info,
      (models_info) => {
        if (models_info) {
          isCollapseMeasure.value = false
        }else {
          isCollapseMeasure.value = true
        }
      },
    )

    function localSizeSelectHandle(status: boolean) {
      if (status) {
        LocalSizeSelect.open({
          id: props.productInfo?.goods_id,
          value: currentCountry.value,
          localSize: sizeCountryList.value,
          options: {
            language: props.language,
            config: {
              appendToBody: true,
              isNewSizeLocal: true,
            },
            callbacks: {
              change: (country: any) => {
                console.log('local-change', country)
                emit('local-change', country)
              },
            },
          },
        })
      } else {
        LocalSizeSelect.close()
      }
    }

    function closeDrawer() {
      isVisible.value = false
      localSizeSelectHandle(false)
      emit('update:value', false)
    }

    function getProductDescTitle(attrId: number) {
      if (attrId === 39) {
        return props.language?.SHEIN_KEY_PWA_18059
      } else {
        return props.language?.SHEIN_KEY_PWA_17611
      }
    }

    /**
     * 根据attrId获取对应的描述值集合
     * @param attrId
     */
    function getProductDescValues(attrId: number) {
      if (attrId === 39) {
        return fabric.value
      } else {
        return fitType.value
      }
    }

    function handleBuyerTableChange(value: boolean) {
      isCollapseMeasure.value = value
    }

    return {
      props,
      isVisible,
      productDescList,
      isShowBuyerSizeTable,
      closeDrawer,
      getProductDescTitle,
      getProductDescValues,
      sizeInfo,
      sizeSortMap,
      localSizes,
      handleSelectSizeUnit,
      defaultSizeUnit,
      sizeCountryList,
      localSizeSelectHandle,
      sizePriorityAbt,
      isCollapseMeasure,
      handleBuyerTableChange,
      currentSizeUnit,
      sizeGuidePlanA,
      currentCountry,
      useAppConfigs,
    }
  },
})
</script>

<style lang="less">
.bsc-size-guide-drawer-wrapper {
  font-size: 12px;
  .S-drawer__body {
    background: #f6f6f6;
  }
}
.bsc-size-guide-drawer {
  height: 100%;
  &__header {
    transform: perspective(1px); // 兼容ios在翻转后icon不展示的问题
    background: #fff;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 11.7vw;
    line-height: 11.7vw;
    color: #333;
    font-weight: bold;
    font-size: 4.6vw;
    text-align: center;

    &__back_icon {
      font-size: 6.4vw;
      position: absolute;
      left: 0;
      font-weight: normal;
      padding: 3.2vw;
    }
  }

  &__content {
    border-bottom: 1.17rem solid #f6f6f6;
    min-height: calc(100% - 1.17rem);
    background: #f6f6f6;
  }

  .sui_icon_more_down_16px {
    color: #959595;
  }

  .sui_icon_more_down_16px.reverse {
    transform: rotate(180deg);
    transition: transform 0.3s;
  }
}

.sizeGuidePlanA-drawer {
  .fit-fabric__title {
    padding-top: 0;
    max-width: 2.6667rem;
    font-size: 0.3733rem;
    margin-bottom: 0.2rem;
    word-break: break-all;
  }

  .fit-fabric__planA {
    gap: 0.5333rem;
    align-items: flex-end;
  }

  .fit-fabric {
    &__dec {
      display: flex;
      border-top: none;
      border-bottom: 2px solid #e5e5e5;
      justify-content: space-between;
      margin-top: 0.32rem;
      padding-bottom: 0.2133rem;
      margin-bottom: 0.4rem;
      color: #222;

      div {
        position: relative;
        padding-top: 0;
        max-width: 2rem;
      }
    }

    &__pos {
      position: absolute;
      display: block;
      background: #e5e5e5;
      width: 2px;
      height: 6px;
      bottom: -0.32rem;
      top: auto;
    }
  }

  .fit-fabric__select {
    background: #000;
    height: 0.16rem;
    width: 0.5333rem;
    margin-top: -0.08rem;
  }

  .fit-fabric__icon {
    display: none;
  }

  .s-drawer-local {
    border-radius: 0.4rem 0.4rem 0 0;
  }

  .measure-guide {
    margin-top: 0;
  }

  .measure-guide__header {
    color: var(---sui_color_black, #000);
    font-size: 0.3733rem;
    font-style: normal;
    font-weight: 500;
  }

  .measure-guide__footer {
    background: #fff;
    padding: 0 0.32rem 0px 0.32rem;
  }

  .measure-wrap {
    p {
      font-size: 0.32rem;
    }
  }
}

.c-drawer-local {
  /*rw:begin*/
  border-radius: 0.4rem 0.4rem 0 0;
}

.bsc-local-size-select {
  display: inline-flex;
  align-items: center;
  border: 1px solid #e5e5e5;
  color: #222;
  background-color: #f6f6f6;
  border-radius: 6.4vw;
  height: 7vw;
  padding: 0 3.2vw;

  span {
    font-size: 3.2vw;
  }

  .suiiconfont {
    vertical-align: top;
  }
}

.fit-fabric {
  background: #ffffff;
  padding: 0 0.32rem;
  margin-bottom: 0.22rem;

  &__planA {
    display: flex;
    gap: 20px;

    .fit-fabric__dec {
      flex: 1;
    }
  }

  .fit-fabric__left {
    text-align: left /*rtl:ignore*/;
  }

  .fit-fabric__center {
    text-align: center;
  }

  .fit-fabric__right {
    text-align: right /*rtl:ignore*/;
  }

  &__title {
    font-weight: 700;
    font-size: 3.8vw;
    color: #333;
    padding-top: 6.4vw;

    &:last-child {
      padding-top: 0.666rem;
    }
  }

  &__dec {
    display: flex;
    border-top: 1px solid #cccccc;
    justify-content: space-between;
    margin-top: 0.32rem;
    padding-bottom: 0.32rem;
    color: #222;

    div {
      position: relative;
      padding-top: 0.4rem;
      max-width: 2rem;
    }
  }

  &__pos {
    position: absolute;
    display: block;
    background: #cccccc;
    width: 1px;
    height: 6px;
    top: 0px;
  }

  &__icon {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px 6px 0 6px;
    border-color: #222 transparent transparent transparent;
  }

  &__posR {
    right: 0;

    .fit-fabric__icon {
      left: -1.6vw;
    }
  }

  &__posC {
    transform: translateX(-50%);
    left: 50%;

    .fit-fabric__icon {
      left: -6px;
    }
  }

  &__select {
    background: none;
  }
}

.c-drawer .address-mshe-mask {
  position: fixed;
  top: 0;
  right: 0 /*rtl:ignore*/;
  bottom: 0;
  left: 0 /*rtl:ignore*/;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 999;
  /* stylelint-disable-line declaration-property-value-blacklist */
  transform: translate3d(0, 0, 999px);
}
</style>
