import { DS_QuickAddCartStandard } from '@shein-aidc/bs-quick-add-cart-standard-mobile'
import { Button } from './Button.tsx'
import Logger from '../../utils/logger.ts'

const OLD_CUSTOMIZATION_LINK = 'https://shein-client.customeow.io'
const NEW__CUSTOMIZATION_LINK = 'https://m.shein.com'

const appendQueryParamsInUrl = (url: string, params: Record<string, string | undefined>) => {
  try {
      const urlObj = new URL(url)
      const urlParams = new URLSearchParams(urlObj.search)
      for (const key in params) {
        if (params[key]){
          urlParams.append(key, encodeURIComponent(params[key] as string))
        }
      }
      urlObj.search = urlParams.toString()
      return urlObj.toString()
  } catch (error) {
      throw new Error(`Invalid URL: ${url}`)
  }
}

let customerMade
if (typeof window !== 'undefined') {
  import('./CustomeowDrawer/customerMade.ts').then(res => {
    customerMade = res.default
  })
}

/**
 * 定制化商品插件
 * @param isDirectCustom 是否可以直接定制商品
 * @description 非商详主商品，显示跳转商详页的按钮
 * @description 商详主商品，显示正常加车按钮。但会在加车前调用接口获取定制化信息，加入到加车参数中
 * @description 直接定制商品，显示正常加车按钮。但会在加车前调用接口获取定制化信息，加入到加车参数中
 */
export const CustomizationProductPlugin = (isDirectCustom?: boolean): DS_QuickAddCartStandard.QuickAddCartPlugin => {
  let pluginContext: DS_QuickAddCartStandard.PluginManagerContext | null = null
  return {
    name: 'CustomizationProduct',
    register(context) {
      pluginContext = context
    },
    beforeAddCartApi: async (context) => {
      const { productInfo, skuInfo, setAddCartParams } = context
      // 定制商品
      if (productInfo?.customization_attributes?.customization_flag === 1) {
        const isNewLink = productInfo?.customization_attributes?.customization_platfrom_flag === '1'
        const url = isNewLink ?
        `${NEW__CUSTOMIZATION_LINK}${pluginContext?.useModules?.().gbCommonInfo?.value?.langPath || '/ccst'}/ccst/customization`
        : `${OLD_CUSTOMIZATION_LINK}/${pluginContext?.useModules?.().gbCommonInfo?.value?.appLanguage}`
        const link = appendQueryParamsInUrl(url, {
          sku: skuInfo?.sku_code,
          language: pluginContext?.useModules?.().gbCommonInfo?.value?.appLanguage,
          type: 'immersive',
          site_uid: pluginContext?.useModules?.().gbCommonInfo?.value?.siteUID,
        })
        Logger.info('customization link:', link)
        // const link = 'https://shein-client.customeow.io/pt-br?sku=I91d0mo1g7xu'
        const customization_info = await customerMade({
          link,
          errorTip: pluginContext?.useModules?.().language?.value?.SHEIN_KEY_PWA_26887,
        })
        Logger.info('customization_info:', customization_info)
        if (!customization_info) {
          Logger.warn('customization_info is empty')
          // 取消加车流程
          context.stopAddCartFlow()
        }else {
          setAddCartParams({customization_info})
        }
      }
    },
    renderAddCartButton(context) {
      const { isSoldOut, mallStock, unListed, isCustomization, isMainProduct } = context
      // 定制商品
      // 非直接定制商品
      // 非商详主商品
      // 非预售商品
      // 非预售商品，非售罄，并且mall库存大于0
      if (isCustomization && !isDirectCustom && !isMainProduct && !unListed && !isSoldOut && (mallStock ?? 0) > 0) {
        /** 跳转商详页的按钮 */
        return Button({
          text: pluginContext?.useModules?.().language?.value?.SHEIN_KEY_PWA_15019 || 'ADD TO CART',
          context,
        })
      }
    },
  }
}
