<template>
  <div
    v-if="timer"
    class="quickAddEstimatedTagNewCountdown"
    :class="{ 'countdownNewStyle': countdownNewStyle }"
    :style="{'color': colorStyle}"
  >
    <div v-if="countdownNewStyle" class="divide-line" :style="{'background-color': colorStyle}"></div>
    <Icon
      v-else
      name="sui_icon_time_12px"
      size="12px"
      color="#FA6338"
      class="quickAddEstimatedTagNewCountdown__icon"
    />
    <div class="bsc-price__count-down">
      <p class="bsc-price__count-down__range">{{ hms.H }}</p>
      <p class="quickAddEstimatedTagNewCountdown__colon">:</p>
      <p class="bsc-price__count-down__range">{{ hms.M }}</p>
      <p class="quickAddEstimatedTagNewCountdown__colon">:</p>
      <p class="bsc-price__count-down__range">{{ hms.S }}</p>
      <template v-if="isShowCountDownMillSec">
        <p class="quickAddEstimatedTagNewCountdown__colon">:</p>
        <p class="bsc-price__count-down-millSec__range">{{ timer?.secondHundred }}</p>
      </template>
    </div>
  </div>
</template>

<script setup name="EstimatedTagNewCountdown">
/**
 * 到手价标签新样式右侧倒计时
 */
import { Icon } from '@shein-aidc/icon-vue3'
import { watch, ref, computed, nextTick, inject } from 'vue'
import { useCountDown } from '../../../../../utils/countDown'
const { triggerNotice } = inject('analysisInstance')

const props = defineProps({
  endTime: {
    type: String,
    default: '',
  },
  isShowCountDownMillSec: {
    type: Boolean,
    default: false,
  },
  nowTime: {
    type: Number,
    default: 0,
  },
  countdownNewStyle: {
    type: Boolean,
    default: false,
  },
  colorStyle: {
    type: String,
    default: '',
  },
  isPromotion: {
    type: Boolean,
    default: false,
  },
})

const timer = ref(null)

const hms = computed(() => {
  let { H, M, S } = timer.value?.timeObj || {}
  return { H, M, S }
})

const initTimer = () => {
  timer.value = useCountDown({
    endTime: props.endTime,
    nowTime: props.nowTime,
    isShowCountDownMillSec: props.isShowCountDownMillSec,
  })
  nextTick(() => {
    window.appEventCenter?.$emit?.('quickDetailcalcPriceDomFn')
  })
}

watch(
  () => props.endTime,
  () => {
    if (props.endTime && props.nowTime) {
      initTimer()
    }
  },
  { immediate: true },
)

watch(
  () => props.isPromotion,
  (val) => {
    if (val) {
      triggerNotice({ id: 'expose_activity_limit_time.comp_quick-add-cart-standard' })
    }
  },
  { immediate: true },
)
</script>

<style lang="less">
.quickAddEstimatedTagNewCountdown {
  display: flex;
  flex-wrap: nowrap;
  color: #fa6338;
  font-size: 11px;
  white-space: nowrap;
  transform: translateY(.0267rem);
  &.countdownNewStyle{
    align-items: center;
    padding: 0px 3px 0 0;
    transform: translateY(0);
    line-height: 1;
    .divide-line{
      height: 10px;
      width: 1px;
      background: #F23F0C;
      opacity: 0.3;
      margin: 0 4px 0 1px;
    }
  }
  &__icon {
    font-size: 11px !important;/* stylelint-disable-line declaration-no-important */
    margin-right: 3px;
  }
  &__colon {
    margin-right: 1px;
  }
}

.bsc-price__count-down {
  display: flex;
  line-height: 1;
  direction: ltr; /* rtl ignore */
  &__range {
    width: 14px;
    display: flex;
    justify-content: center;
  }
  &-millSec__range {
    width: 7px;
    display: flex;
    justify-content: center;
  }
}
</style>
