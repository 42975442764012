<template>
  <div
    class="productSpecialFlash"
  >
    <div class="productSpecialFlash__title">
      <span class="productSpecialFlash__title-text">{{ flashBeltWraperInfo.title }}</span>
    </div>
    <slot></slot>
  </div>
</template>
<!-- :is-rotate="GB_cssRight" -->
<script setup name="SpecialFlash">
defineProps({
  flashBeltWraperInfo: {
    type: Object,
    default: () => ({}),
  },
})
</script>

<style lang="less">
.productSpecialFlash {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 -.32rem;
  padding: .2133rem .32rem;
  background: linear-gradient(270deg,#fff 17.52%,hsla(0,0%,100%,0) 64.13%),linear-gradient(180deg,#ffeae5,rgba(255,246,243,0));
  position: relative;

  &_has-belt {
    border-radius: 13px 13px 0px 0px;
  }
  &_neignbor-belt{
    box-shadow: 0 -4px 12px 0 rgba(222,154,24,0.40);
  }

  &__title {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 15px;
    margin-bottom: 6px;
  }

  &__title-text {
    font-size: 11px;
    font-weight: 700;
    line-height: 13px;
    letter-spacing: 0px;
    color: #FA6338;
  }

  &__embellish-img {
    overflow: hidden;
    position: absolute;
    right: 0.4324rem;
    top: 0;
    width: 2.4533rem;
    height: 100%;
    > img {
      width: 100%;
    }
  }
}
</style>
