import type { ExtractPropTypes, PropType } from 'vue'
import type { DS_QuickAddCartStandard } from 'packages/bs/quick-add-cart-standard/types/index.ts'

export const BHeaderProps = {
  fsAbt: {
    type: Object as PropType<Record<string, any>>,
    default: () => ({}),
  },
  language: {
    type: Object as PropType<Record<DS_QuickAddCartStandard.LANGUAGE_KEY , string | undefined>>,
    default: () => ({}),
  },
  closeQuickAddCart: {
    type: Function,
    default: () => {},
  },
  featureConfig: {
    type: Object as PropType<DS_QuickAddCartStandard.FeatureConfig>,
    default: () => ({}),
  },
  shipInfo: {
    type: Object as PropType<DS_QuickAddCartStandard.ShipInfo>,
    default: () => ({}),
  },
  addCartInfo: {
    type: Object as PropType<DS_QuickAddCartStandard.AddCartInfo>,
    default: () => ({}),
  },
  skuList: {
    type: Array as PropType<DS_QuickAddCartStandard.Sku[]>,
    default: () => [],
  },
  apis: {
    type: Object as PropType<DS_QuickAddCartStandard.APIS>,
    default: () => ({}),
  },
  productInfo: {
    type: Object as PropType<DS_QuickAddCartStandard.ProductInfo>,
  },
  mallCode: {
    type: String,
    default: '',
  },
  isSwitchColor: {
    type: Boolean,
    default: false,
  },
}

export type TBHeaderProps = ExtractPropTypes<typeof BHeaderProps>
