/** eslint-disiable */
// @ts-nocheck

import { getSaGoodsList, getLocateLabelsMark } from './product-item'

import { getSaAnalysisParams } from '../utils'

/**
 * 迁移的代码，后续可能会暴露出去让外部自行处理。
 * @param param
 */
export const getClickAddBagParam = ({ extraData }) => {
    let param: any = {}
    /**
      * @result 加车请求返回的响应体
      * @postData 上报的字段参数集合
      * @faultReason 加车失败的原因，如果不传失败的理由是响应体的msg，兜底 ‘server_failure’
      * @from 加车自有埋点区分加车来源
      * @traceid
      * @code 与商品曝光点击的code一致，用来获取ga的eventCategory 或者 ga电商代码的list
      * @eventCategory ga埋点的eventCategory，如果不传则会通过code去获取
      * @gaList ga的电商代码list，如果不传则会通过code 去获取
      * @isNew 是否是新品
      */
    const {
        interestpoints = '',
        result = {},
        target,
        postData = {},
        faultReason = '',
        page_name,
        from = 'other',
        traceid = '',
        review_location = '',
        button_type,
        hypernymProductSum,
        src_module: _srcModule = '',
        src_identifier: _srcIdentifier = '',
        src_tab_page_id: _srcTabPageId = '',
        ranking_from = '',
        coupon_type = '',
        isAttrFold,
        goods_list,
    } = extraData


    param.interestpoints = interestpoints || ''
    param.sku_code = postData.skuCode || postData.sku_code || ''
    param.sku_id = postData.sku
    param.quickship_tp = postData.quickShip
    param.goods_id = postData.goods_id
    param.size = postData.attrs && postData.attrs.length && postData.attrs[0].attr_value_id || ''
    param.result = result.code == 0 ? 1 : 2 // 1/2 成功1 失败 2
    param.result_reason = result.code == 0 ? '' : faultReason || result.msg || 'server_failure'
    param.activity_from = from === 'cart' ? 'goods_list' : from
    param.review_location = review_location
    param.mall_code = postData.mallCode
    param.location = postData.location
    param.image_tp = postData.image_tp
    // eslint-disable-next-line no-prototype-builtins
    param.is_default_mall = postData.hasOwnProperty('is_default_mall') ? postData.is_default_mall : 1
    // eslint-disable-next-line no-prototype-builtins
    param.is_default_mall = postData.hasOwnProperty('is_default_mall') ? postData.is_default_mall : 1
    param.threshold = postData.threshold
    param.button_type = button_type || 'add_to_bag'
    param.is_customize = postData?.is_customize || 0
    param.ranking_from = ranking_from
    if (postData.feed_type) {
        param.feed_type = postData.feed_type
    }

    if (isAttrFold) {
        param.is_attr_fold = isAttrFold
    }

    // 购物车页券筛选
    if (coupon_type) param.coupon_type = coupon_type

    // 同一次加车内的多条加车数据上报这个id要一致(新组合购)
    if (postData.click_id) param.click_id = postData.click_id
    // eslint-disable-next-line no-prototype-builtins
    if (param.hasOwnProperty(hypernymProductSum)) {
        // 只有有上位词的页面【搜索商品页】才新增 rec_result_count
        param.rec_result_count = typeof hypernymProductSum === 'number' ? hypernymProductSum : '-'
    }

    if (!['wishlist'].includes(from)) param.traceid = traceid
    param.dimension = '-' // 维度，不清楚是什么参数，产品说和app保持一致写死”-“
    param.style = postData.style || 'detail'

    // 商品项目角标
    const locateLabels = getLocateLabelsMark(postData.locateLabels)
    const saGoodsList = getSaGoodsList({ goodsId: postData.goods_id, sku: postData.sku, spu: postData.spu, index: postData.index, price: postData.price, originPrice: postData.originPrice, mallCode: postData.mall_code, locateLabels })
    const getListName = {
        page_goods_group: 'page_shein_picks',
    }

    const listName = page_name || getListName[window.SaPageInfo?.page_name] || window.SaPageInfo?.page_name
    if (target) {
        const { saAbt = '', goodsList = '', tabList, srcModule, srcIdentifier, trendTag } = getSaAnalysisParams(
          target,
          {
            mallCode: postData.mallCode,
            ...(locateLabels ? { locateLabels } : {}),
            ...(postData.quickShip ? { quickShip: 'quickship_' + 1 } : {}),
          },
          listName,
        )
        param.abtest = saAbt
        param.goods_list = postData.goodsListFromTarget || goodsList
        param.tab_list = tabList
        param.src_module = srcModule || ''
        param.src_identifier = srcIdentifier || ''
        param.trend_tag = trendTag
      } else {
        param.abtest = ''
        param.goods_list = ''
        if(from == 'gals' || from == 'gals_live') {
          param.goods_list = saGoodsList
        }
      }


      if(goods_list) {
        param.goods_list = goods_list
      }


    // 部分场景会通过extraData传入这些属性
    param.src_module = _srcModule || param.src_module || ''
    param.src_identifier = _srcIdentifier || param.src_identifier || ''
    param.src_tab_page_id = _srcTabPageId || ''
    param = { ...param, ...(extraData.passParams || {}) }
    return param
}
