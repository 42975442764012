<template>
  <div
    class="bsc-size-guide-drawer__product-desc"
    :class="{'fit-fabric__planA': sizeGuidePlanA}">
    <div class="fit-fabric__title">
      {{ descTitle || desc.attr_name }}
    </div>
    <div class="fit-fabric__dec">
      <div
        v-for="(item, index) in descValues"
        :key="index"
        :class="{
          'fit-fabric__right': descValues.length - 1 === index,
          'fit-fabric__center': descValues.length - 1 !== index && index !== 0,
          'fit-fabric__left': index === 0,
        }"
      >
        <span
          class="fit-fabric__pos"
          :class="{'fit-fabric__posR':descValues.length - 1 === index,
                   'fit-fabric__posC':descValues.length - 1 !== index && index !==0,
                   'fit-fabric__select':desc.attr_value_en === item.key}"
        >
          <div
            v-show="desc.attr_value_en === item.key"
            class="fit-fabric__icon"></div>
        </span>
        {{ item.value }}
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { DS_QuickAddCartStandard } from '@shein-aidc/bs-quick-add-cart-standard-mobile'
import { defineComponent, PropType } from 'vue'
export default defineComponent({
  name: '',
  props: {
    sizeGuidePlanA: {
      type: Boolean,
      default: false,
    },
    desc: {
      type: Object as PropType<DS_QuickAddCartStandard.ProductDescInfo>,
      default: () => ({}),
    },
    descTitle: {
      type: String,
      default: '',
    },
    descValues: {
      type: Array as PropType<{ key: string; value?: string }[]>,
      default: () => [],
    },
  },
  setup(props) {
    return { props }
  },
})
</script>

<style lang="less">

</style>
