<script lang="jsx">
import { SButton as Button } from '@shein-aidc/sui-button/mobile'
import { Icon } from '@shein-aidc/icon-vue3'
export default {
  name: 'SizeFeedBackSuccessPanel',
  functional: true,
  props: {
    language: {
      type: Object,
      default: () => ({}),
    },
    selectedSize: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ['handleShowSimilar'],
  setup(props, { emit }) {
    return () => {
      const { language, selectedSize } = props
      // TODO: similarRecommendText 像是不传了
      // const { similarRecommendText = '', selectedSize = {} } = props.params
      const similarContent = () =>
        selectedSize.type == 0 ? (
          <div>
            {/* <p>{similarRecommendText}</p> */}
            <Button
              type={['default', 'H56PX']}
              onClick={() => emit('handleShowSimilar')}
            >
              {language.SHEIN_KEY_PWA_21191}
            </Button>
          </div>
        ) : null

      return (
        <div class="success-box">
          <Icon
            name="sui_icon_success_75px"
            size="56px"
            color= '#198055'
          />
          <h2>{language.SHEIN_KEY_PWA_21092}</h2>
          <h3>{language.SHEIN_KEY_PWA_21093}</h3>
          { similarContent() }
        </div>
      )
    }
  },
}
</script>
