import OneLink from '../../utils/oneLink'

export function Button(brandDealsInfo = {}) {
  function handleClick() {
    const { activityId, oneLinkUrl } = brandDealsInfo
    const oneLink = new OneLink(oneLinkUrl, activityId)
    const el = document.querySelector('.call-app-btn')
    el && oneLink.bindCopyHandle(el)
    oneLink.navigateToApp()
  }

  return (
    <button
      class="mshe-btn-black call-app-btn"
      role="button"
      aria-label={brandDealsInfo.mainBtnText}
      onClick={() => handleClick()}
    >
      {brandDealsInfo.mainBtnText || 'Shop on the SHEIN APP'}
    </button>
  )
}
