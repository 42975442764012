<template>
  <div class="buyer-size-table">
    <div
      v-show="loading"
      class="buyer-size-table__loading">
      <Loading
        type="newpage"
        :show="loading" />
    </div>
    <CommonSizeTable
      v-show="!loading && comment_percent"
      v-expose="{
        id: 'expose_size_guide_fit_well.comp_size-guide',
        data: {
          fit_data: comment_sizes?.length ? 1 : 0,
        },
        once: false,
      }"
      :title="language.SHEIN_KEY_PWA_31636"
      :tabs="tabs"
      :tab-disabled="tabDisabled"
      :tableHeader="tableHeader"
      :tableData="tableData"
      :desc="tableData.length ? language.SHEIN_KEY_PWA_31641 : ''"
      :activeTab="currentTab"
      :hidden-table="tabDisabled"
      @tab-change="handleTabChange">
      <!-- 表格头部 -->
      <template #table-header="{ value, index }">
        <div class="buyer-size-table__header_item">
          {{ value }}
          <div
            v-if="index === 0"
            class="buyer-size-table__header_item_desc">
            ({{ lengthUnit + '/' + weightUnit }})
          </div>
        </div>
      </template>

      <!-- 表格单元格 -->
      <template #table-cell="{ value, index }">
        <div
          v-if="index === 0"
          class="buyer-size-table__cell_bold">
          <Icon
            name="sui_icon_avatar_16px_color"
            width="4.25vw"
            height="4.25vw" />
          <span>{{ value }}</span>
        </div>
        <div
          v-else
          class="buyer-size-table__cell">
          {{ value }}
        </div>
      </template>

      <!-- no data tips -->
      <template
        v-if="!sizeList.length"
        #table-content>
        <div class="buyer-size-table__tips">
          <Icon
            name="sui_icon_empty_75px_1"
            size="2rem" />
          <p class="buyer-size-table__tips_text">
            {{ language.SHEIN_KEY_PWA_31640 }}
          </p>
        </div>
      </template>
    </CommonSizeTable>
  </div>
</template>

<script setup name="BuyerSizeTable" lang="ts">
/**
 * @name BuyerSizeTable
 * @description 买家真实评论尺码表格
 * @description 这是一个功能完备的业务组件，提供开箱即用体验。
 */
import { defineProps, defineEmits, ref, watch, computed, inject } from 'vue'
import { Icon } from '@shein-aidc/icon-vue3'
import { SLoading as Loading } from '@shein-aidc/sui-loading/mobile'
import { DS_SizeGuide, AS_SizeGuide } from '@shein-aidc/bs-size-guide-mobile'
import { useAppConfigs } from '@shein-aidc/bs-sdk-libs-manager'
import { getSource } from '../../../../common/dataSource'
import CommonSizeTable from '../CommonSizeTable.vue'
import { DS_QuickAddCartStandard } from '@shein-aidc/bs-quick-add-cart-standard-mobile'

interface AttrInfo {
  attr_type: number
  cm: number
  inch: number
}

interface SizeInfo {
  size: string
  height: AttrInfo
  weight: AttrInfo
  bust: AttrInfo
  waist: AttrInfo
  hips: AttrInfo
}

const props = defineProps<{
  spuId: string,
  skc: string,
  language: DS_SizeGuide.LANGUAGE,
  localSizes?: Record<string, DS_QuickAddCartStandard.AttrValueLocalSize[] | undefined>,
  country: string,
  lengthUnit?: 'cm' | 'inch',
  weightUnit?: 'kg' | 'lbs',
  sizeSortMap?: Record<string, number> | undefined,
  visible: boolean,
}>()

const emit = defineEmits<{
  (event: 'table-change', show: boolean): void
}>()

const analysisInstance = inject<AS_SizeGuide.AnalysisInstance>('analysisInstance')
const { vExpose } = analysisInstance || {}

const appConfigs = useAppConfigs()
const apis = getSource({ appConfigs })
const comment_percent = ref<DS_SizeGuide.CommentPercentOverallFit | undefined>(undefined)
const comment_sizes = ref<DS_SizeGuide.CommentSkuInfo[] | undefined>(undefined)
const currentTab = ref('true_size')
const loading = ref(true)

// 1:Height、2:Weight、3:Bust、5:Waist、6:Hips
const comment_size_attr_map = {
  1: 'height',
  2: 'weight',
  3: 'bust',
  5: 'waist',
  6: 'hips',
}

function fetchGoodsCommentSizes() {
  if(!props.visible) return
  loading.value = true
  apis.getCommentSizes({
    spu_id: props.spuId,
    skc: props.skc,
  }).then(json => {
    const { comment, sizes } = json
    comment_percent.value = comment?.percent_overall_fit
    emit('table-change', !comment_percent.value)
    comment_sizes.value = sizes
    currentTab.value = 'true_size' // 默认选中合身尺码
  }).finally(() => {
    loading.value = false
  })
}

watch([() => props.spuId, () => props.skc, () => props.visible], fetchGoodsCommentSizes, { immediate: true })
watch(() => props.visible, () => {
  // visible 变化时，重置 tab 为合身尺码
  currentTab.value = 'true_size'
})

function formatTabText(key) {
  if (key === 'small') {
    return props.language.SHEIN_KEY_PWA_31637 || 'Small'
  } else if (key === 'true_size') {
    return props.language.SHEIN_KEY_PWA_31638 || 'True to size'
  } else if (key === 'large') {
    return props.language.SHEIN_KEY_PWA_31639 || 'Large'
  }
}

function convertOverallFit(key) {
  if (key === 'small') {
    return 3
  } else if (key === 'true_size') {
    return 1
  } else if (key === 'large') {
    return 2
  }
}

const tabs = computed(() => {
  if (comment_percent.value) {
    return Object.keys(comment_percent.value).filter(key => comment_percent.value?.[key] !== '0%')
      .map(key => {
        return {
          key,
          text: formatTabText(key) as string,
          extra: `: ${comment_percent.value?.[key]}`,
        }
      })
      .sort(a => {
        // 按 small ，true_size ，large 顺序排列
        if (a.key === 'small') {
          return -1
        } else if (a.key === 'true_size') {
          return 0
        } else {
          return 1
        }
      })
  } else {
    return []
  }
})

const sizeList = computed(() => {
  const list = filterSizeList(comment_sizes.value, currentTab.value)
  const sortMap = props.sizeSortMap
  if (sortMap && Object.keys(sortMap).length > 0) {
    return list.sort((a, b) => {
      return sortMap[a.size] - sortMap[b.size]
    }).filter(item => sortMap[item.size] !== undefined) // 过滤sortMap中没有的尺码
  } else {
    return list
  }
})

// Buyer（身高体重）、本地化尺码、Size、Bust、Waist、Hips
const tableHeader = computed(() => {
  const language = props.language
  const header = [
    language.SHEIN_KEY_PWA_31643 || 'Size',
    language.SHEIN_KEY_PWA_31644 || 'Bust',
    language.SHEIN_KEY_PWA_31645 || 'Waist',
    language.SHEIN_KEY_PWA_31646 || 'Hips',
  ]
  if (props.country && props.localSizes) {
    header.unshift(props.country)
  }
  header.unshift(`${language.SHEIN_KEY_PWA_31642 || 'Buyer'}`)
  return header
})

const tableData = computed(() => {
  const data = genTableData(
    sizeList.value,
    props.lengthUnit,
    props.weightUnit,
    props.localSizes,
  )
  // True to size  下条数小于3条时, 不展示表格
  if (currentTab.value === 'true_size' && data.length < 3) {
    return []
  }
  return data
})

const tabDisabled = computed(() => {
  return currentTab.value === 'true_size' && tableData.value.length === 0
})

function handleTabChange(tab) {
  const fitType = tab.key === 'true_size' ? 'true_to_size' : tab.key
  analysisInstance?.triggerNotice({
    id: 'click_size_guide_fit_well_tab.comp_size-guide',
    data: { fit_type: fitType },
  })
  currentTab.value = tab.key
}

/**
 * 过滤尺码列表
 * @param {Array} sizeList 尺码列表
 * @param {String} tabKey 当前选中的 tab key
 * @returns {Array} 过滤后的尺码列表 { size: string, height: { cm: number, inch: number }, weight: { kg: number, lb: number }, bust: { cm: number, inch: number }, waist: { cm: number, inch: number }, hips: { cm: number, inch: number } }[]
 */
function filterSizeList(sizeList: DS_SizeGuide.CommentSkuInfo[] = [], tabKey: string) {
  const memberOverallFit = convertOverallFit(tabKey)
  const result: SizeInfo[] = []
  for (let i = 0; i < sizeList.length; i++) {
    const sizeItem = sizeList[i]
    const sizeName = sizeItem.size_en
    const skuInfoList = sizeItem.sku_info_list
    let limit = 0 // 每个尺码只取 3 条数据
    skuInfoList?.forEach(skuInfo => {
      if (limit < 3 && skuInfo.member_overall_fit === memberOverallFit) {
        limit++
        const sizeInfo = transformSizeInfo(
          skuInfo.size_info_list,
          sizeName,
          comment_size_attr_map,
        )
        result.push(sizeInfo)
      }
    })
  }
  return result
}

/**
 * 将商品尺码的 size_info_list 转换为 sizeInfo 对象
 */
function transformSizeInfo(sizeInfoList: DS_SizeGuide.CommentSizeInfo[], sizeName: string, attrMap: Record<number, string>) {
  const result = {
    size: sizeName,
  }
  sizeInfoList.forEach(sizeInfo => {
    const attrName = attrMap[sizeInfo.attr_type]
    result[attrName] = transformSizeAttrInfo(sizeInfo)
  })
  return result as SizeInfo
}

function formatUnit(unit: string) {
  if (unit === 'in') {
    return 'inch'
  }
  return unit
}

/**
 * 将商品尺码的 size_info 转换为 size attr 对象
 */
function transformSizeAttrInfo(sizeInfo: DS_SizeGuide.CommentSizeInfo) {
  const result = {
    attr_type: sizeInfo.attr_type,
  }
  result[formatUnit(sizeInfo.default_unit)] = parseFloat(
    sizeInfo.option_value,
  )
  result[formatUnit(sizeInfo.second_unit)] = sizeInfo.option_value ? (
    parseFloat(sizeInfo.option_value) * sizeInfo.transform_rate
  ).toFixed(1) : ''
  return result as AttrInfo
}

/**
 * 将商品尺码的 attr_info 转换为 attr value 对象
 */
function transformSizeAttrValue(attrInfo, lengthUnit, weightUnit) {
  if (attrInfo) {
    return attrInfo[lengthUnit] || attrInfo[weightUnit] || '-'
  }
  return '-'
}

function genTableData(sizeList, lengthUnit = 'cm', weightUnit = 'kg', localSizes?: Record<string, DS_QuickAddCartStandard.AttrValueLocalSize[] | undefined>) {
  const result = sizeList.map(item => {
    const rowData = [
      item.size,
      transformSizeAttrValue(item.bust, lengthUnit, weightUnit),
      transformSizeAttrValue(item.waist, lengthUnit, weightUnit),
      transformSizeAttrValue(item.hips, lengthUnit, weightUnit),
    ]
    if (props.country && localSizes) {
      const localSize = localSizes[item.size]
      const size = localSize?.find(item => item.country_code === props.country)?.attr_local_size_value
      rowData.unshift(size || '-')
    }
    rowData.unshift(
      `${transformSizeAttrValue(
        item.height,
        lengthUnit,
        weightUnit,
      )}/${transformSizeAttrValue(item.weight, lengthUnit, weightUnit)}`,
    )
    return rowData
  })
  return result
}


</script>

<style lang="less">
.buyer-size-table {
  &__loading {
    width: 100%;
    padding: 2.13333rem 0.64rem;
  }

  &__header {
    &_item {
      &_desc {
        font-size: 0.26667rem;
        font-weight: 400;
      }
    }
  }

  &__cell {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 0.32rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    white-space: nowrap;

    span {
      margin-right: 0.05rem;

      &:last-child {
        margin-right: 0;
      }
    }

    &_bold {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      font-size: 0.32rem;
      font-style: normal;
      font-weight: 400;
      line-height: 100%;
      font-weight: 700;
      white-space: nowrap;

      span {
        margin-right: 0.05rem;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  &__tips {
    display: flex;
    padding: 2.13333rem 0.64rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.53333rem;
    align-self: stretch;

    &_text {
      color: #222;
      text-align: center;
      font-family: 'SF Pro';
      font-size: 0.37333rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.00005rem;
    }
  }
}

[mir=rtl] {
  .buyer-size-table__cell_bold {
    span {
      direction: ltr;
    }
  }

  .buyer-size-table__cell {
    direction: ltr;
  }
}
</style>
