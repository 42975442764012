<template>
  <div
    class="quickUnitPrice"
    :style="unitPriceInfo.style"
    v-html="unitPriceInfo.valueHtml"
  >
  </div>
</template>

<script setup name="UnitPrice">
defineProps({
  unitPriceInfo: {
    type: Object,
    default: () => ({}),
  },
})
</script>

<style lang="less">
.quickUnitPrice {
  font-size: 11px;
  line-height: 1;
}
</style>
