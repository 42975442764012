<template>
  <div
    class="quickDiscountInfoPl"
    :style="assignStyle"
  >
    <DiscountPercent
      v-if="discountInfoPl.discountPercent"
      :value="discountInfoPl.discountPercent"
      :style-map="discountPercentAssignStyle"
    />
    <p class="quickDiscountInfoPl__retail">
      {{ retailPrice }}
    </p>
    <p class="quickDiscountInfoPl__retail-plTip">
      {{ retailPriceTip }}
    </p>
  </div>
</template>

<script setup name="DiscountInfoPl">
/**
 * @file 主价格 原价 折扣率
*/
import DiscountPercent from './DiscountPercent.vue'
import { computed, toRefs } from 'vue'
import { template } from '@shein/common-function'

const props = defineProps({
  assignStyle: {
    type: Object,
    default: () => ({}),
  },
  retailPrice: {
    type: String,
    default: '',
  },
  retailPriceTip: {
    type: String,
    default: '',
  },
  discountPercent: {
    type: String,
    default: '',
  },
  discountAmount: {
    type: String,
    default: '',
  },
  discountPercentAssignStyle: {
    type: Object,
    default: () => ({}),
  },
  discountAmountInfo: {
    type: Object,
    default: () => ({}),
  },
  config: {
    type: Object,
    default: () => ({}),
  },
})
const { discountAmountInfo, discountPercent, discountAmount, config } = toRefs(props)

/**
 * 波兰合规价自定义数据/样式
*/
const discountInfoPl = computed(() => {
  const { discountValue = null, showDiscountPercent, saveLanguageKey } = discountAmountInfo.value || {}
  const { priceStyleType = {} } = config.value || {}

  // 展示折扣率还是折扣金额
  const _discountAmount = discountAmount.value ? template(discountAmount.value, saveLanguageKey || 'Save {0}') : ''
  const showValue = showDiscountPercent ? discountPercent.value : _discountAmount

  return {
    discountPercent: discountPercent.value || '',
    // 百补样式
    ...(priceStyleType?.showBrandDeals ? {
      discountPercent: discountValue?.type === 'retailPriceSale' ? showValue : '',
    } : {}),
  }
})
</script>

<style lang="less">
.quickDiscountInfoPl {
  display: flex;
  align-items: flex-end;
  align-self: normal;
  max-width: 100%;
  color: #767676;
  &__retail {
    font-size: 11px;
    line-height: 1;
    text-decoration: line-through;
    margin-left: 2px;
    margin-right: 4px;
  }
  &__retail-plTip {
    font-size: 10px;
    font-weight: 400;
    line-height: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>
