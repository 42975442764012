import { ref, computed, watch } from 'vue'
import { useModules } from '../modules/useModules.ts'
import { getEstimatedInfo, checkEstimatedWithConfig } from './utils.ts'


export const useCommonInfo = (arg) => {

  const {
    featureConfig,
    isSoldOut,
    currentStock,
    skuInfo,
    mallCode,
  } = arg

  const {
    language,
    productInfo,
    priceInfo,
    detailPromotionFloor,
    userInfo,
    gbCommonInfo,
    fsAbt,
  } = useModules()

  const cssRight = gbCommonInfo?.value?.cssRight

  const goodsId = computed(() => productInfo?.value?.goods_id)
  const goodsSn = computed(() => productInfo?.value?.goods_sn)
  const mallSoldOut = computed(() => currentStock.value == 0)

  const skcPromotionInfo = computed(() => detailPromotionFloor?.value?.detailPromotionInfo || [])
  const skuPromotionInfo = computed(() => skuInfo && skuInfo.value?.detailPromotionInfo || [])

  const flashPromotion = computed(() => skcPromotionInfo?.value?.find?.(item => item.typeId == 10) || null)
  const flashType = computed(() => flashPromotion?.value?.flashType || null)
  const newFlashPromotion = computed(() => !!flashPromotion.value && skcPromotionInfo?.value?.find?.(item => item.typeId == 10 && [2, 3, 4].includes(Number(item.flashType))) || null)
  const isFlash = computed(() => !!flashPromotion?.value)
  const isNormalFlash = computed(() => [1, 4, 5].includes(Number(flashType?.value)))
  const isSpecialFlash = computed(() => [2, 3].includes(Number(flashType?.value)))
  const isBrandFlash = computed(() => flashType.value == 6)
  const promotionList = computed(() => skuInfo && skuInfo.value?.sku_code ? skuPromotionInfo.value : skcPromotionInfo.value)
  const promotionIds = computed(() => promotionList.value?.map(item => item.id)?.join(',') || '')

  // 百亿补贴
  const brandDealsInfo = computed(() => {
    const { SHEIN_KEY_PWA_15019, SHEIN_KEY_PWA_36125 } = language?.value || {}
    const promotionInfo = promotionList.value?.find?.(item => item.typeId == '31' && item.promotion_logo_type == '21') || null
    if (!promotionInfo || !featureConfig?.value?.needPromotion) return null

    // 用户是否限购
    const singleRemainNum = promotionInfo.singleRemainNum || '9999'
    const triggerLimit = +singleRemainNum === 0
    const baseUrl = 'https://onelink.shein.com/9/4fp55xbiwr9z'
    const urlFrom = `${gbCommonInfo?.value?.siteUID}_SHEIN_detail_m2a_pwa`
    const oneLinkUrl = `${baseUrl}?mall_code=${mallCode?.value}&goods_id=${goodsId?.value}&url_from=${urlFrom}`

    return {
      ...promotionInfo.brandDealsInfo,
      GB_cssRight: cssRight,
      oneLinkUrl,
      activityId: '1315',// 归因上报id
      singleRemainNum: +singleRemainNum,// 用户可购买数量
      singleNum: +promotionInfo.singleNum,// 商品限购总数
      triggerLimit, // 用户是否限购
      mainBtnText: triggerLimit ? SHEIN_KEY_PWA_15019 : SHEIN_KEY_PWA_36125,
    }
  })

  const isPaidUser = computed(() => userInfo?.value?.isPaidMember == 1)
  const sheinClubPromotionInfo = computed(() => skcPromotionInfo.value?.find?.(item => item.typeId == 29) || null)

  const exclusiveInfo = computed(() => {
    if (Number(priceInfo?.value?.showTypeId) == 12 && priceInfo?.value?.showPrice) {
      const { SHEIN_KEY_PWA_24353, SHEIN_KEY_PWA_17305, SHEIN_KEY_PWA_15146 } = language?.value || {}
      return {
        isS3newpriceAbtNew: fsAbt?.value?.EstimatedPrice?.p?.S3newprice == 'new',
        imgLink: 'https://img.ltwebstatic.com/images3_ccc/2024/07/23/00/1721706854eda1168bf47805172a56be15e10d076c.png',
        amountWithSymbol: priceInfo?.value?.showPrice?.amountWithSymbol,
        text: SHEIN_KEY_PWA_24353,
        introText: SHEIN_KEY_PWA_17305,
        btnText: SHEIN_KEY_PWA_15146,
      }
    }
    return null
  })

  const isShowPrice = computed(() => (isPaidUser.value && !!sheinClubPromotionInfo.value) || (isSpecialFlash.value || flashType.value == 4) || (!!exclusiveInfo?.value && exclusiveInfo?.value?.isS3newpriceAbtNew))

  const price = computed(() => newFlashPromotion.value ? priceInfo?.value : (skuInfo?.value?.priceInfo || priceInfo?.value || {}))
  const finalPrice = isShowPrice.value ? price.value?.showPrice : price.value?.salePrice

  const complianceMode = computed(() => featureConfig?.value?.needComplianceModel?.complianceMode)
  const complianceTipsMode = computed(() => featureConfig?.value?.needComplianceModel?.complianceTipsMode)
  const complianceModeDe = computed(() => featureConfig?.value?.needComplianceModel?.complianceModeDe)
  const isHitComplianceMode = computed(() => {
    const { complianceMode, complianceTipsMode, complianceModeDe } = featureConfig?.value?.needComplianceModel || {}
    return complianceMode || complianceTipsMode || complianceModeDe
  })

  const isInversion = computed(() => price.value?.suggestedSalePriceInfo?.isInversion)

  return {
    goodsId,
    goodsSn,
    isSoldOut,
    mallSoldOut,
    skcPromotionInfo,
    promotionIds,
    flashPromotion,
    flashType,
    newFlashPromotion,
    isFlash,
    isNormalFlash,
    isSpecialFlash,
    isBrandFlash,
    brandDealsInfo,
    isPaidUser,
    sheinClubPromotionInfo,
    exclusiveInfo,
    isShowPrice,
    price,
    finalPrice, // 最终接口/埋点取这个
    complianceMode,
    complianceTipsMode,
    complianceModeDe,
    isHitComplianceMode,
    isInversion,
    cssRight,
  }
}

/* ======= 价格 ======= */
export const useEstimatedInfo = (arg) => {
  const {
    language,
    priceInfo,
    fsAbt,
  } = useModules()
  const {
    isSoldOut,
    currentStock,
    featureConfig,
    skuInfo,
    mallCode,
  } = arg
  const {
    goodsSn,
    mallSoldOut,
    isFlash,
    isNormalFlash,
    isSpecialFlash,
    isBrandFlash,
    brandDealsInfo,
    isPaidUser,
    sheinClubPromotionInfo,
    exclusiveInfo,
    complianceMode,
    complianceModeDe,
    cssRight,
  } = useCommonInfo({
    featureConfig,
    isSoldOut,
    currentStock,
    skuInfo,
    mallCode,
  })
  const skcEstimatedInfo = ref(null)
  watch(() => priceInfo?.value, (val) => {
    if (!val) return
    const { EstimatedPrice, EstimatedShowType, EstimatedNothreShowType, middleast, AllAreaPcsPrice } = fsAbt?.value || {}
    let result = getEstimatedInfo({
      skcPriceInfo: priceInfo?.value,
      skuPriceInfo: null,
      isSoldOut: isSoldOut?.value || mallSoldOut?.value,
      abts: {
        EstimatedPrice,
        EstimatedShowType,
        EstimatedNothreShowType,
        middleast,
        AllAreaPcsPrice,
      },
      otherInfo: {
        exclusiveInfo: exclusiveInfo.value,
        sheinClubPromotionInfo: sheinClubPromotionInfo.value,
        isPaidUser: isPaidUser.value,
        complianceMode: complianceMode.value,
        complianceModeDe: complianceModeDe.value,
        isFlash: isFlash.value,
        isNormalFlash: isNormalFlash.value, // isNormalFlash isSpecialFlash isBrandFlash 三个同时只会生效一个
        isSpecialFlash: isSpecialFlash.value,
        isBrandFlash: isBrandFlash.value,
        isBrandDeals: !!brandDealsInfo.value,
        language: language?.value,
        goodsSn: goodsSn.value,
        isQuickAdd: true,
        hasLink: featureConfig?.value?.estimatedTagCanClick,
        GB_cssRight: cssRight,
      },
    })
    result = checkEstimatedWithConfig(result, featureConfig?.value)
    skcEstimatedInfo.value = result
  }, { immediate: true })
  const estimatedInfo = computed(() => {
    if (featureConfig?.value?.closeEstimatedAndAbPrice) return null
    const { EstimatedPrice, EstimatedShowType, EstimatedNothreShowType, middleast, AllAreaPcsPrice } = fsAbt?.value || {}
    if (skcEstimatedInfo?.value && !skuInfo?.value?.sku_code) return skcEstimatedInfo.value
    let result = getEstimatedInfo({
      skcPriceInfo: priceInfo?.value,
      skuPriceInfo: skuInfo?.value?.priceInfo,
      isSoldOut: isSoldOut.value || mallSoldOut.value,
      abts: {
        EstimatedPrice,
        EstimatedShowType,
        EstimatedNothreShowType,
        middleast,
        AllAreaPcsPrice,
      },
      otherInfo: {
        exclusiveInfo: exclusiveInfo.value,
        sheinClubPromotionInfo: sheinClubPromotionInfo.value,
        isPaidUser: isPaidUser.value,
        complianceMode: complianceMode?.value,
        complianceModeDe: complianceModeDe?.value,
        isFlash: isFlash.value,
        isNormalFlash: isNormalFlash.value, // isNormalFlash isSpecialFlash isBrandFlash 三个同时只会生效一个
        isSpecialFlash: isSpecialFlash.value,
        isBrandFlash: isBrandFlash.value,
        isBrandDeals: !!brandDealsInfo.value,
        language: language?.value,
        goodsSn: goodsSn.value,
        isQuickAdd: true,
        hasLink: featureConfig?.value?.estimatedTagCanClick,
        GB_cssRight: cssRight,
      },
    })
    result = checkEstimatedWithConfig(result, featureConfig?.value)
    return result
  })
  return {
    skcEstimatedInfo,
    estimatedInfo,
  }
}
/* ======= 价格 ======= */


/* ======= 次级销售属性 ======= */

/* ======= 次级销售属性 ======= */

