import type { DS_QuickAddCartStandard } from '../../../types'
/**
 * 快车所有 XHR 请求的 API
 */

export default {
  /** 静态接口 */
  GOODS_DETAIL_STATIC_DATA: '/product/get_goods_detail_static_data',
  /** 动态接口 */
  GOODS_DETAIL_REALTIME_DATA: '/product/get_goods_detail_realtime_data',
  /** 细节图 */
  GOODS_DETAIL_IMAGE_DATA: '/product/get_goods_detail_image',
  /** 获取商品详情abt */
  GOODS_DETAIL_ABT_DATA: '/products-api/get_detail_abt_info',
  /** 获取多语言 */
  GET_LANGUAGES: '/system/configs/multi_language',
  /** 获取页面多语言 */
  GET_PAGE_LANGUAGES: '/system/configs/page_multi_language_mapping',
  /** 获取服务器时间倒计时用 */
  GET_SERVICES_TIME: '/api/common/servicesTime/get',
  /**
   * 获取人气氛围
   * @description 请求主站node中间层的接口
   */
  GET_DISPLAY_INFO: '/api/productInfo/displayWindowInfo/query',
  /** 加车接口 */
  GOODS_ADD_TO_CART: '/order/add_to_cart',
  /** 收藏接口 */
  GOODS_WHISHLIST_ADD: '/user/wishlist_add',
  /** 取消收藏接口 */
  GOODS_WHISHLIST_REMOVE: '/user/wishlist_remove',
  /** 更新购物车属性 */
  GOODS_CART_UPDATE: '/order/mall/cart_update_attr',
  /** 获取购物车物车信息 */
  GOODS_CART_INFO: '/cart-api/mall/cart/base_info',
  /** 绑定优惠券 */
  COUPON_POST_BIND: '/product/coupon/batch_bind_coupons',
  /** 榜单接口 */
  GET_RANK_TOP_INFO: '/category-api/get_detail_rank_info',
} as DS_QuickAddCartStandard.API_LOCATIONS
