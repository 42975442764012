<template>
  <div class="bsc-quick-add-cart__footerBar quick_add_footerbar">
    <div
      v-if="footerBarTipsComponents"
      class="quick-add-footerbar__tips"
    >
      <component :is="footerBarTipsComponents" />
    </div>
    <p
      v-else-if="(isSoldOut || mallStock == 0) && !unListed"
      class="soldout-warning"
    >
      {{ soldOutTip }}
    </p>
    <div class="list-add-to-bag">
      <FavoriteButton
        v-if="visibleFavoriteButton"
        :disabled="loading"
        :goodsId="productInfo?.goods_id"
        :mallCode="mallCode"
        :language="language"
        :dataSource="dataSource"
        :skuCode="skuInfo?.sku_code"
        :isSaved="isSaved"
        :callbacks="callbacks"
        @onClickAddToBoard="onClickAddToBoard"
        @onToggleWishFetchFinish="onToggleWishFetchFinish"
        @onToggleLoginStatus="onToggleLoginStatus"
        @onToggleWishStatus="onToggleWishStatus"
      />

      <div
        v-if="loading"
        class="add-button-box"
        :class="{ 'with-save-btn': visibleFavoriteButton }">
        <button class="bsc-quick-add-cart-standard__button_black loading">
          <SLoading
            :show="true"
            :mode="'dark'" />
        </button>
      </div>
      <template v-else>
        <component
          :is="customAddCartButton"
          v-if="customAddCartButton"
        />
        <component
          :is="currentBtn.component"
          v-else-if="currentBtn && currentBtn.component"
          v-bind="currentBtn.props"
          v-on="currentBtn.listeners"
        />
      </template>
    </div>
  </div>
</template>
<script name="FooterBar" setup lang="ts">
import { nextTick, computed, PropType, watch, onUnmounted, ref, defineProps } from 'vue'
import { SLoading } from '@shein-aidc/sui-loading/mobile'
import { NotAvaliableBtnStyle } from './constant.ts'
import InactiveButton from './InactiveButton.vue'
import FavoriteButton from './FavoriteButton.vue'
import DefaultAddBtn from './DefaultAddBtn.vue'
import { useModules } from '../../modules/useModules.ts'
// import { SILogger } from '../../../../common/monitor/index.ts'
// import { useAnalysis } from '../../../../common/analysisSource'
import { useReportSa } from './useReportSa.ts'
import { getVariantAttr, getReportGaList, lockPage, removeLockPage, getAddCartFormData, callHook } from './utils.ts'
import { getSource } from '../../../../common/dataSource'
import { useAppConfigs } from '@shein-aidc/bs-sdk-libs-manager'
import type { DS_QuickAddCartStandard } from '../../../../types/index'
import { IToggleWishFetchFinishParams } from './types.ts'
import { useCartStatusHandler } from './useCartStatusHandler.ts'
import { IHandlerResult } from './types.ts'
import { showToast } from '../../utils/toastQueue.ts'
import { template } from '@shein/common-function'
import { usePlugins } from '../../plugins'
import monitoring from '../../hook/monitoring'
import Logger from '../../utils/logger.ts'

const props = defineProps({
  quickShip: {
    type: Number || undefined,
  },
  closeQuickAddCart: {
    type: Function as PropType<() => void>,
    required: true,
  },
  mallStock: {
    type: Number,
  },
  mallCode: {
    type: String,
  },
  quantity: {
    type: Number,
  },
  largeImageMode: {
    type: Boolean,
  },
  setLoading: {
    type: Function as PropType<(loading: boolean) => void>,
    required: true,
  },
  dataSource: {
    type: Object as PropType<DS_QuickAddCartStandard.DataSource>,
    required: true,
  },
  isSoldOut: {
    type: Boolean,
  },
  loading: {
    type: Boolean,
    default: false,
  },
  productInfo: {
    type: Object as PropType<DS_QuickAddCartStandard.ProductInfo>,
    required: true,
  },
  callbacks: {
    type: Object as PropType<DS_QuickAddCartStandard.CallBacks>,
  },
  featureConfig: {
    type: Object as PropType<DS_QuickAddCartStandard.FeatureConfig>,
    default: () => ({}),
    required: true,
  },
  analysisConfig: {
    type: Object as PropType<DS_QuickAddCartStandard.Options['analysisConfig']>,
  },
  language: {
    type: Object as PropType<DS_QuickAddCartStandard.Language>,
    required: true,
  },
  estimatedInfo: {
    type: Object,
  },
  brandDealsInfo: {
    type: Object,
  },
  mainPrice: {
    type: String,
  },
  fixedRatio: {
    type: String,
  },
  isMainProduct: {
    type: Boolean,
  },
  /**
   * 加车来源
   * @description 等同于 addSource
   */
  sourceIdentifier: {
    type: String,
  },
  skuInfo: {
    type: Object as PropType<Record<string, any>>,
  },
  checkSelectSize: {
    type: Function as PropType<() => { status: boolean; unSelectedAttrs: string[] }>,
  },
  customAddCartParams: {
    type: Object as PropType<Partial<DS_QuickAddCartStandard.PostAddToCartParams>>,
  },
})

interface IEmitEvents {
  (event: 'onChangeLimitType', data: number): boolean
  (event: 'onAddCartFinished', data: IHandlerResult, addCartParams: DS_QuickAddCartStandard.PostAddToCartParams, addCartResponse?: DS_QuickAddCartStandard.BffApiResponse<DS_QuickAddCartStandard.GoodsAddCartResponse>): void
  (event: 'onAddCartBefore'): void
  (event: 'onWishStatusChange', data: string): void
  (event: 'onLoginStatusChange'): boolean
}

const isSaved = computed(() => {
  return props.productInfo?.is_saved === '1'
})
onUnmounted(() => {
  removeLockPage()
})
const { reportSaLoadingCart, reportSaAddCartEvent, reportLowstockLabel, reportClickAddCollect } = useReportSa(props)

const footerBarTipsComponents = computed(() => {
  return usePlugins().render('renderFooterBarTips', slotContext.value)
})

const customAddCartButton = computed(() => {
  return usePlugins().render('renderAddCartButton', slotContext.value)
})

const emit = defineEmits<IEmitEvents>()

const handlerAddCartResult = useCartStatusHandler(props, emit)

watch(
  () => props.loading,
  val => {
    if (val) {
      reportSaLoadingCart()
    }
  },
)

const appConfigs = useAppConfigs()
const { mallInfo, fsAbt, saleAttr } = useModules()
const apis = getSource({ appConfigs, dataSource: props.dataSource })

const getFormData = () => {
  const formData = getAddCartFormData({
    skuInfo: props.skuInfo,
    productInfo: props.productInfo!,
    analysisConfig: props.analysisConfig,
    featureConfig: props.featureConfig,
    quantity: props.quantity,
    fsAbt: fsAbt ? fsAbt.value : undefined,
    addSource: props.sourceIdentifier,
  })
  return formData
}

const slotContext = computed(() => {
  return {
   saleAttr: saleAttr?.value,
   isSoldOut: props.isSoldOut,
   isCustomization: isCustomization.value,
   unListed: unListed.value,
   quantity: props.quantity,
   productInfo: props.productInfo,
   estimatedInfo: props.estimatedInfo,
   brandDealsInfo: props.brandDealsInfo,
   customAddCartParams: props.customAddCartParams,
   mainPrice: props.mainPrice,
   fixedRatio: props.fixedRatio,
   isMainProduct: props.isMainProduct,
   skuInfo: props.skuInfo,
   mallCode: props.mallCode,
   mallStock: props.mallStock,
   quickShip: props.quickShip,
   Loading: props.loading,
   closeQuickAddCart: props.closeQuickAddCart,
   setLoading: props.setLoading,
   lockPage,
   removeLockPage,
   checkSelected,
   addCartClick,
   reportSaAddCartEvent,
   getReportGaList,
   getVariantAttr,
  }
})

const footerBarConfig = props.featureConfig?.footerBar || {}

const mainBtnFullWidth = computed(() => {
  const { needFavoriteButton } = footerBarConfig
  return !needFavoriteButton
})

const soldOutTip = computed(() => {
  if (mallInfo && mallInfo.value) {
    const currMallInfo = (mallInfo.value.mallInfoList || []).find(i => i.mall_code === props.mallCode)
    if ((mallInfo.value.mallInfoList || []).length > 0 && props.mallStock === 0 && !props.isSoldOut && currMallInfo) {
      return template(currMallInfo.mall_name, props.language.SHEIN_KEY_PWA_20858 as string)
    }
  }

  return props.language.SHEIN_KEY_PWA_16794
})

const visibleFavoriteButton = computed(() => {
  return footerBarConfig.needFavoriteButton
})

// 预售标识
const unListed = computed(() => {
  return props.productInfo?.__isNewProductUnSale || false
})

const isCustomization = computed(() => {
  return props.productInfo?.customization_attributes?.customization_flag === 1
})

/**
 * 是否隐藏加车后的提示
 */
const hiddenAddCartTips = ref(false)

function setHiddenAddCartTips(val: boolean) {
  Logger.info('setHiddenAddCartTips', val)
  hiddenAddCartTips.value = val
}

function onClickAddToBoard() {
  callHook(props.callbacks, 'onClickAddToBoard', {
    productInfo: props.productInfo,
  })
  props.closeQuickAddCart()
}

const checkSelected = () => {
  if (props.checkSelectSize) {
    const { status, unSelectedAttrs } = props.checkSelectSize()
    if (!status) {
      showToast(`${props.language.SHEIN_KEY_PWA_19270} ${unSelectedAttrs.join('/')}`)
      return false
    }
    return status
  }
  return Boolean(props.skuInfo?.sku_code)
}


const addCartFlow = ref<AsyncGenerator<any> | null>(null)

function stopAddCartFlow() {
  addCartFlow?.value?.return?.(null)
  removeLockPage()
  props.setLoading(false)
  Logger.info('stopAddCartFlow')
}

/**
 * 创建加车流程（调用这个方法会正常触发加车弹窗组件的加车流程，包括加车回调）
 * @param addCartApi 加车动作接口 (不一定是真的加车接口，可以是其他接口)
 * @param btnType 按钮类型/事件来源/场景值
 * @param customAddCartParams 自定义加车参数
 * @param unSelectedCallback 未选中属性回调
 * @description addCartApi 返回值格式 { code: string , data: any }
 * @description 根据code是否为 '0' 判断加车是否成功
 * @description 这个方法不会触发 beforeAddCartApi 和 afterAddCartApi 钩子
 */
async function* createAdCartFlow(params: {
  btnType?: string,
  customAddCartParams?: Partial<DS_QuickAddCartStandard.PostAddToCartParams>
  unSelectedCallback?: () => void
}) {
  Logger.info('createAdCartFlow', params)
  const { btnType = 'add_to_bag' } = params
  monitoring().onMonitoringTrigger({ type: 'add-to-cart-start' })

  // 默认不隐藏加车提示
  hiddenAddCartTips.value = false
  // 检查销售属性是否全部选中
  if (!checkSelected()) {
    if (params.unSelectedCallback) {
      params.unSelectedCallback()
    } else {
      const { isNotSizeBuriedPoint = true } = footerBarConfig || {} // 默认开启埋点
      isNotSizeBuriedPoint &&
        reportSaAddCartEvent({
          result: { code: 1 },
          faultReason: 'no select size',
          btnType,
        })
    }
    return
  }

  let formData = getFormData()
  if(params.customAddCartParams) {
    formData = {
      ...formData,
      ...params.customAddCartParams,
    }
  }

  // 设置加车参数
  function setAddCartParams(data: Partial<DS_QuickAddCartStandard.PostAddToCartParams>) {
    Logger.info('setAddCartParams', data)
    formData = {
      ...formData,
      ...data,
    }
  }
  // 开启 loading
  emit('onAddCartBefore')
  try {
    // 锁定页面
    lockPage()

    // 加车前
    yield await usePlugins().hooks('beforeAddCartApi', {
      setAddCartParams,
      stopAddCartFlow,
      skuInfo: props.skuInfo,
      productInfo: props.productInfo,
    })
    yield

    monitoring().onMonitoringTrigger({ type: 'add-to-cart-request-start' })

    const data = await apis.postAddToCart(formData)

    const isSuccess = data.code === '0'
    const msg = data.msg || 'unknown error'
    monitoring().onMonitoringTrigger({ type: 'add-to-cart-request-end',status: isSuccess ? 'success' : 'fail', msg })
    // 加车后
    yield await usePlugins().hooks('afterAddCartApi', {
      addCartResult: data,
      setHiddenAddCartTips,
      productInfo: props.productInfo,
      isMainProduct: props.isMainProduct,
      skuInfo: props.skuInfo,
      mallCode: props.mallCode,
    })
    nextTick(() => {
      removeLockPage()
    })

    const result = handlerAddCartResult(data, formData, {
      hiddenAddCartTips: hiddenAddCartTips.value,
      reportSa: (type, saParam) => {
        if (type === 'lowStockTips') {
          reportLowstockLabel(saParam)
        }
      },
    })

    emit('onAddCartFinished', result, formData, data)

    reportSaAddCartEvent({
      result: data,
      gaList: getReportGaList(props.analysisConfig!, props.productInfo?.cat_id || '') || '',
      variant: getVariantAttr(props.skuInfo),
      btnType,
    })

    monitoring().onMonitoringTrigger({ type: 'add-to-cart-end' })

  } catch (e: any) {
    console.error('add cart flow error:', e)
    removeLockPage()
    emit('onAddCartFinished', { isSuccess: false, errorMsg: 'error' }, formData)
    const msg = e?.message || 'unknown error'
    monitoring().onMonitoringTrigger({ type: 'add-to-cart-error', status: 'fail', msg })
    reportSaAddCartEvent({
      result: { code: 1 },
      btnType,
    })
  }
}

async function addCartClick(btnType?: string, customAddCartParams?: Partial<DS_QuickAddCartStandard.PostAddToCartParams>) {
  addCartFlow.value = createAdCartFlow({
    btnType,
    customAddCartParams,
  })
  let result = await addCartFlow.value.next()
  while (!result.done) {
    result = await addCartFlow.value.next()// 继续执行
  }
}

function onToggleWishFetchFinish(data: IToggleWishFetchFinishParams) {
  reportClickAddCollect(data)
}

// 收藏成功
function onToggleWishStatus(payload: { isSaved: boolean }) {
  emit('onWishStatusChange', payload.isSaved ? '1' : '0')
}

function onToggleLoginStatus() {
  emit('onLoginStatusChange')
}

const currentBtn = computed(() => {
  const currentMallStock = props.mallStock

  // 预售商品 coming soon
  if (unListed.value) {
    return {
      component: InactiveButton,
      props: {
        btnText: props.language.SHEIN_KEY_PWA_16006 || 'COMING SOON',
        mainBtnFullWidth: mainBtnFullWidth.value,
        language: props.language,
        ariaLabel: props.language.SHEIN_KEY_PWA_16006 || 'COMING SOON',
        btnStyle: NotAvaliableBtnStyle.Normal,
      },
    }
  }

  // 售罄(当前商品售罄 ｜ 当前mall售罄)
  if (props.isSoldOut || currentMallStock == 0) {
    // 当前mall售罄
    if (currentMallStock == 0 && !props.isSoldOut) {
      return {
        component: InactiveButton,
        props: {
          btnText: props.language?.SHEIN_KEY_PWA_15019,
          mainBtnFullWidth: mainBtnFullWidth.value,
          language: props.language,
          ariaLabel: props.language?.SHEIN_KEY_PWA_15019,
          btnStyle: NotAvaliableBtnStyle.CurrentMall,
        },
      }
    }
    // 当前商品售罄
    return {
      component: InactiveButton,
      props: {
        btnText: props.language.SHEIN_KEY_PWA_14987,
        mainBtnFullWidth: mainBtnFullWidth.value,
        language: props.language,
        ariaLabel: props.language.SHEIN_KEY_PWA_14987,
        btnStyle: NotAvaliableBtnStyle.Normal,
      },
    }
  }

  return {
    component: DefaultAddBtn,
    listeners: {
      onClick: addCartClick,
    },
    props: {
      mainBtnFullWidth: mainBtnFullWidth.value,
      submitText: props.language?.SHEIN_KEY_PWA_15019 || 'ADD TO CART',
      visibleFavoriteButton: visibleFavoriteButton.value,
    },
  }
})

watch(footerBarTipsComponents, () => {
  if (footerBarTipsComponents.value) {
    // 本质上式为了显式收集依赖，让组件重新渲染
    // @ts-ignore
    footerBarTipsComponents.value.patchFlag = 32
  }
})

watch(customAddCartButton, () => {
  if (customAddCartButton.value) {
    // 本质上式为了显式收集依赖，让组件重新渲染
    // @ts-ignore
    customAddCartButton.value.patchFlag = 32
  }
})
</script>
<style lang="less">
@import './common.less';

.bsc-quick-add-cart__footerBar {
  button {
    height: 10.66667vw;
    line-height: 10.66667vw;
  }
}

/* stylelint-disable selector-class-pattern, selector-max-specificity, selector-max-type, declaration-no-important */
.flexbox() {
  display: -webkit-box;
  display: -moz-box;
  display: flex;
  display: -ms-flexbox;
}

// 两端对齐
.space-between() {
  justify-content: space-between;
}

.list-add-to-bag {
  align-items: center;
  display: flex;

  > button,
  .add-btn {
    flex: 1;
    // height: 36px;
    // line-height: 36px;

    &.disabled {
      // height: 36px;
      // line-height: 36px;
      flex: 1;
    }

    &.fullWidth {
      flex: 1;
    }
  }
}

.quick_add_footerbar {
  padding: 6px 3.2vw;
  transform: translateZ(199px);
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 199;
  background-color: #fff;
  .quick-add-footerbar__tips {
    margin: 0 -3.2vw 1.6vw -3.2vw;
  }
  .soldout-warning {
    transform: translateY(-97%);
    position: absolute;
    top: 0;
    width: 100%;
    left: 0;
    padding: 2.13333vw 3.2vw;
    background-color: #fff1d5;
    text-align: center;
    margin: 0;
    line-height: 1.2;
    font-size: 12px;
    color: #222;
  }
  .add-button-box {
    position: relative;
    .flexbox();
    .space-between();
    width: 100%;

    & > button {
      width: 100%;
      height: 10.66667vw;
      line-height: 10.66667vw;
    }

    .bsc-quick-add-cart-standard__button_black.loading {
      display: flex;
      align-items: center;
      justify-content: center;

      .S-loading__common-inner {
        margin: 0 !important;
      }

      .S-loading__circular-svg {
        .S-loading__circular-path-under {
          stroke: #646464;
        }

        .S-loading__circular-path {
          stroke: #fff;
        }
      }
    }
  }

  // .add-button-box.with-save-btn {
  // }
}
.bsc-low-stock-tips__addCart {
  width: 62vw;
  display: flex;
  flex-flow: column nowrap;
  padding: 3.2vw;
  font-size: 3.7vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  & .success-tips-content {
    color: #fa6338;
    padding-bottom: 2.1vw;
    display: inline;
    .tips-icon-wrap {
      vertical-align: middle;
    }
    .tips-icon {
      width: 3.2vw;
      height: 3.2vw;
      font-size: 3.2vw;
      margin-right: 2px;
    }
    .sh_m_sui_icon_hourglass_12px {
      display: inline-block;
      background: url(data:image/svg+xml;base64,PHN2ZyBzdHlsZT0id2lkdGg6IGF1dG87IGhlaWdodDogMWVtOyIgd2lkdGg9IjEyIiBoZWlnaHQ9IjEyIiB2aWV3Qm94PSIwIDAgMTIgMTIiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNMTAgMUgxMVYySDEwVjIuNTgwMTNDMTAgMy4zMDk5NCA5LjY4MTA5IDQuMDAzMzIgOS4xMjY5OCA0LjQ3ODI3TDcuNjI2OTggNS43NjM5OUM3LjUyMzgzIDUuODUyNCA3LjQxNTI4IDUuOTMxMDcgNy4zMDI1MSA2QzcuNDE1MjggNi4wNjg5MyA3LjUyMzgzIDYuMTQ3NiA3LjYyNjk4IDYuMjM2MDFMOS4xMjY5OCA3LjUyMTczQzkuNjgxMDkgNy45OTY2OCAxMCA4LjY5MDA2IDEwIDkuNDE5ODdWMTBIMTFWMTFIOS41SDIuNUgxVjEwSDJWOS40MTk4N0MyIDguNjkwMDYgMi4zMTg5MSA3Ljk5NjY4IDIuODczMDIgNy41MjE3M0w0LjM3MzAyIDYuMjM2MDFDNC40NzYxNyA2LjE0NzYgNC41ODQ3MiA2LjA2ODkzIDQuNjk3NDkgNkM0LjU4NDcyIDUuOTMxMDcgNC40NzYxNyA1Ljg1MjQgNC4zNzMwMiA1Ljc2Mzk5TDIuODczMDIgNC40NzgyN0MyLjMxODkxIDQuMDAzMzIgMiAzLjMwOTk0IDIgMi41ODAxM1YySDFWMUgySDEwWk0zIDJWMi41ODAxM0MzIDMuMDE4MDIgMy4xOTEzNCAzLjQzNDA0IDMuNTIzODEgMy43MTkwMUw1LjAyMzgxIDUuMDA0NzNDNS41ODU1NSA1LjQ4NjIyIDYuNDE0NDUgNS40ODYyMiA2Ljk3NjE5IDUuMDA0NzNMOC40NzYxOSAzLjcxOTAyQzguODA4NjYgMy40MzQwNCA5IDMuMDE4MDIgOSAyLjU4MDEzVjJIM1oiIGZpbGw9IiNGQTYzMzgiPgo8L3BhdGg+CjxwYXRoIGQ9Ik02LjcwNzExIDQuMjkyODlMNy4zMjU3NCAzLjY3NDI2QzcuNDYxNjQgMy41MzgzNiA3LjM0NjMgMy4zMDc2NyA3LjE1NjAzIDMuMzM0ODVMNi4xNDE0MiAzLjQ3OThDNi4wNDc2MiAzLjQ5MzIgNS45NTIzOCAzLjQ5MzIgNS44NTg1OCAzLjQ3OThMNC44NDM5NyAzLjMzNDg1QzQuNjUzNyAzLjMwNzY3IDQuNTM4MzYgMy41MzgzNiA0LjY3NDI2IDMuNjc0MjZMNS4yOTI4OSA0LjI5Mjg5QzUuNjgzNDIgNC42ODM0MiA2LjMxNjU4IDQuNjgzNDIgNi43MDcxMSA0LjI5Mjg5WiIgZmlsbD0iI0ZBNjMzOCI+CjwvcGF0aD4KPC9zdmc+) no-repeat;
      background-size: 100%;
    }
  }
}
</style>
