<template>
  <div
    class="quickDiscountInfoDe"
    :style="assignStyle"
  >
    <p class="quickDiscountInfoDe__retail-tip">
      {{ retailPriceTip }}
    </p>
    <p class="quickDiscountInfoDe__retail">
      {{ retailPrice }}
    </p>
    <DiscountPercent
      v-if="discountInfoDe.discountPercent"
      :value="discountInfoDe.discountPercent"
      :style-map="discountPercentAssignStyle"
    />
  </div>
</template>

<script setup name="DiscountInfoDe">
import { computed, toRefs } from 'vue'
import { template } from '@shein/common-function'

/**
 * @file 主价格 原价 折扣率
*/
import DiscountPercent from './DiscountPercent.vue'
const props = defineProps({
  assignStyle: {
    type: Object,
    default: () => ({}),
  },
  retailPrice: {
    type: String,
    default: '',
  },
  retailPriceTip: {
    type: String,
    default: '',
  },
  discountPercent: {
    type: String,
    default: '',
  },
  discountAmount: {
    type: String,
    default: '',
  },
  discountPercentAssignStyle: {
    type: Object,
    default: () => ({}),
  },
  discountAmountInfo: {
    type: Object,
    default: () => ({}),
  },
  config: {
    type: Object,
    default: () => ({}),
  },
})

const { discountAmountInfo, discountPercent, discountAmount, config } = toRefs(props)

/**
 * 德国合规价自定义数据/样式
*/
const discountInfoDe = computed(() => {
  const { discountValue = null, showDiscountPercent, saveLanguageKey } = discountAmountInfo.value || {}
  const { priceStyleType = {}, priceType = '' } = config.value || {}

  // 展示折扣率还是折扣金额
  const _discountAmount = discountAmount.value ? template(discountAmount.value, saveLanguageKey || 'Save {0}') : ''
  const showValue = showDiscountPercent ? discountPercent.value : _discountAmount
  return {
    discountPercent: discountPercent.value || '',
    // 百补样式
    ...(priceStyleType?.showBrandDeals ? {
      discountPercent: (discountValue?.type === 'retailPriceSale' && priceType !== 'retailPriceSale') ? showValue : '',
    }: {}),
  }
})

</script>

<style lang="less">
.quickDiscountInfoDe {
  display: flex;
  align-items: flex-end;
  max-width: 100%;
  color: #767676;
  line-height: 1;

  &__retail {
    font-size: 11px;
    text-decoration: line-through;
    margin: 0 2px;
  }
  &__retail-tip {
    font-size: 10px;
  }
}
</style>
