import { DS_QuickAddCartStandard } from '@shein-aidc/bs-quick-add-cart-standard-mobile'
import { getQueryString } from '@shein/common-function'
import { useAppConfigs } from '@shein-aidc/bs-sdk-libs-manager'
import { getReportGaList, getSyncClickGaEventCategory, getVariantAttr } from '../components/FooterBar/utils'

export const validOneSkc = (data: DS_QuickAddCartStandard.GoodsDetailStaticData) => {
  // 主销售属性只有一个
  return data.saleAttr?.mainSaleAttribute?.info?.length === 1
}

export const validOneSku = (data: DS_QuickAddCartStandard.GoodsDetailStaticData) => {
  // 次级销售属性只有一个，且sku_list只有一个
  return data.saleAttr?.multiLevelSaleAttribute?.skc_sale_attr?.length === 1 && data.saleAttr?.multiLevelSaleAttribute?.sku_list?.length === 1
}

/**
 * 判断是否是定制化商品
 */
export const validCustomization = (data: DS_QuickAddCartStandard.GoodsDetailStaticData) => {
  return data.productInfo?.customization_attributes?.customization_flag === 1
}

function genReportData (params: {
  productInfo: DS_QuickAddCartStandard.ProductInfo,
  saleAttr: DS_QuickAddCartStandard.SaleAttr,
  storeInfo?: DS_QuickAddCartStandard.StoreInfo,
  addCartParams: DS_QuickAddCartStandard.PostAddToCartParams,
  addCartResult: DS_QuickAddCartStandard.BffApiResponse<DS_QuickAddCartStandard.GoodsAddCartResponse>,
  analysisConfig?: DS_QuickAddCartStandard.AnalysisConfig
}) {
  const { productInfo, saleAttr, storeInfo, addCartParams, addCartResult, analysisConfig } = params
  const formData: DS_QuickAddCartStandard.PostAddToCartParams = {
    goods_id: productInfo?.goods_id,
    quantity: `${addCartParams.quantity || 1}`,
    mall_code: addCartParams.mall_code || '',
    promotion_id: analysisConfig?.promotion_id || '0',
    promotion_type: analysisConfig?.promotion_type || '0',
    sku_code: addCartParams.sku_code,
  }
  const popupFrom = analysisConfig?.sa?.activity_from || analysisConfig?.sa?.from || analysisConfig?.from || ''
  // 透传上报字段
  const passParams = analysisConfig?.sa?.passParams || {}
  // 一键加车场景
  passParams.is_one_clk_add = 1
  const skuInfo = saleAttr?.multiLevelSaleAttribute?.sku_list?.find(sku => sku.sku_code === addCartParams.sku_code)
  const sku_sale_attr = skuInfo?.sku_sale_attr
  const quickShipSkuList = saleAttr?.multiLevelSaleAttribute?.sku_list?.filter(sku => sku.isSupportQuickShip === '1')
  const quickShip = quickShipSkuList?.length ? skuInfo?.isSupportQuickShip === '1' ? 1 : 3 : 0
  const gaList = getReportGaList(analysisConfig!, productInfo?.cat_id || '') || ''
  const variant = getVariantAttr({ sku_sale_attr })
  const eventCategory = getSyncClickGaEventCategory(popupFrom)
  const isFromActivity = analysisConfig?.from === 'activityQuickAdd'
  const { actScenes = '' } = analysisConfig || {}
  const actOptions = isFromActivity
      ? {
        actOptions: {
          actScenes,
        },
      }
      : {}
  const extraData = {
    page_name: useAppConfigs().$getters.SaPageInfo.page_name,
    target: analysisConfig?.sourceTarget || null,
    code: analysisConfig?.code || 'other',
    promotion_id: analysisConfig?.promotion_id || '',
    promotion_state: analysisConfig?.promotion_state || '',
    review_location: analysisConfig?.review_location || '',
    ranking_from: analysisConfig?.sa?.ranking_from || '',
    coupon_type: analysisConfig?.sa?.coupon_type || '', // 购物车页券筛选
    passParams,
    postData: {
      attrs: sku_sale_attr,
      goodsListFromTarget: analysisConfig?.getGoodsListFromTarget?.(),
      is_customize: productInfo?.customization_attributes.customization_flag,
      sku: productInfo.goods_sn,
      //@ts-ignore
      goods_id: productInfo.goods_id!,
      quickShip: quickShip,
      spu: productInfo?.productRelationID,
      catId: productInfo?.cat_id,
      recommendType: eventCategory === '推荐列表'? popupFrom.indexOf('auto_rcmd_goods_list') > -1 ? 2 : 1 : 0,
      variant,
      businessModel: storeInfo?.business_model || '',
      storeCode: storeInfo?.store_code,
      mallCode: formData.mall_code,
      is_default_mall: productInfo.selectedMallCode == formData.mall_code ? 1 : 0,
      location: analysisConfig?.sa?.location || 'popup',
      feed_type: analysisConfig?.sa?.feed_type || '',
      image_tp: '',
      style: analysisConfig?.sa?.style || 'popup',
      locateLabels: analysisConfig?.sa?.mobileVerticalView?.locateLabels,
      ...formData,
      ...(analysisConfig?.postData || {}),
      // ...(threshold ? { threshold } : {}),
    },
    gaList,
    eventCategory,
    result: addCartResult,
    from: popupFrom,
    ...actOptions,
    button_type: 'add_to_bag',
    isAttrFold: analysisConfig?.isAttrFold || '0',
    hypernymProductSum: analysisConfig?.hypernymProductSum || null,
  }
  // 从预搜页找相似跳转过来的情况须多加几个参数
  const isFromPreSearchSimilarItems = !!getQueryString({ key: 'preSearchSimilarItems' })
  const srcTabPageId = getQueryString({ key: 'src_tab_page_id' }) || ''
  if (isFromPreSearchSimilarItems && srcTabPageId) {
    Object.assign(
      extraData, {
        src_module: 'presearch_similar',
        src_identifier: '-',
        src_tab_page_id: srcTabPageId,
      },
    )
  }
  return extraData
}

/**
 * 一键加车插件（直接加车）
 * @param addCartRule 加车规则，返回true表示可以直接加车，返回false表示不可以直接加车
 * @param directlyAddCartCallback 直接加车回调
 * @param extraAddCartParams 额外的加车参数
 */
export const DirectlyAddCartPlugin = (
  addCartRule?: (data: DS_QuickAddCartStandard.GoodsDetailStaticData) => boolean,
  directlyAddCartCallback?: (res: DS_QuickAddCartStandard.BffApiResponse<DS_QuickAddCartStandard.GoodsAddCartResponse>) => void,
  extraAddCartParams?: Partial<DS_QuickAddCartStandard.PostAddToCartParams>,
): DS_QuickAddCartStandard.QuickAddCartPlugin => {
  return {
    name: 'DirectlyAddCart',
    beforeOpen: async context => {
      // 加车规则不存在或者不是函数，直接返回false，继续后续流程
      if (!addCartRule || typeof addCartRule !== 'function') return false

      const { op, params, apis, onMonitoringTrigger, loadCartSuccess, analysisInstance } = context
      try {
        onMonitoringTrigger?.({ type: 'directly-add-start' })
        params.isUserSelectedMallCode = 1
        const staticData = await apis.getGoodDetailStaticData(params)
        if (staticData?.info && addCartRule(staticData.info)) {
          const addCartParams: DS_QuickAddCartStandard.PostAddToCartParams = {
            goods_id: params.goods_id,
            quantity: '1',
            freeShippingAbtParams: 'type=B',
            mall_code: staticData.info.mallInfo?.selectedMallCode,
            ...extraAddCartParams,
          }
          // todo 加车后面用统一的方法，做回调处理
          onMonitoringTrigger?.({ type: 'add-to-cart-request-start' })
          const data = await apis.postAddToCart(addCartParams)
          const isSuccess = data.code === '0'
          onMonitoringTrigger?.({ type: 'add-to-cart-request-end', status: isSuccess ? 'success' : 'fail' })
          onMonitoringTrigger?.({ type: 'directly-add-end' })
          const reportData = genReportData({
              productInfo: staticData.info.productInfo as DS_QuickAddCartStandard.ProductInfo,
              saleAttr: staticData.info.saleAttr as DS_QuickAddCartStandard.SaleAttr,
              storeInfo: staticData.info.storeInfo as DS_QuickAddCartStandard.StoreInfo,
              addCartParams,
              addCartResult: data,
              analysisConfig: op?.analysisConfig,
          })
          analysisInstance?.triggerNotice({
            id: 'click_add_bag.comp_quick-add-cart-standard',
            data: reportData,
          })
          if (isSuccess) {
            // 加车成功弹窗
            loadCartSuccess?.()
          }
          directlyAddCartCallback?.(data)
          return isSuccess
        } else {
          onMonitoringTrigger?.({ type: 'directly-add-end' })
          return false
        }
      } catch (error) {
        onMonitoringTrigger?.({ type: 'directly-add-error', status: 'fail', msg: error })
        console.log('DirectlyAddCart Plugin fail:', error)
        return false
      }
    },
  }
}
