import { ref, createApp } from 'vue'
import { useAppConfigs } from '@shein-aidc/bs-sdk-libs-manager'
import type { DS_QuickAddCartStandard } from '../../../types'
import { getSource } from '../../../common/dataSource'
import QuickAddCart from '../quick-add-cart-standard.vue'
import { updateLanguage, useModules } from '../modules/useModules'
import Logger from '../utils/logger'
import { pluginManager, usePlugins } from '../plugins'
import { CustomizationProductPlugin } from '../plugins/customization'
import { apolloConfigPlugin } from '../plugins/apolloConfig'
import { brandDealsPlugin } from '../plugins/brandDeals'
import { mergeOptions } from './utils'
import pkg from '../../package.json'
import monitoring from './monitoring'
import { debounce } from '@shein/common-function'


/**
 * 默认埋点配置
 */
export const DEFAULT_ANALYSIS_CONFIG: DS_QuickAddCartStandard.AnalysisConfig = {
  sa: {},
  ga: {
    name: '',
    list: '',
  },
  source: '',
  code: '',
  sourceTarget: undefined,
  from: '',
  // 购物车
  promotion_id: '',
  promotion_type: '',
  quantity: 1,
  tab: '',
  //专题
  biClickReport: undefined,
  mod: {},
  content_list: '',
  index: 0,
  style: 'popup',
}

export const DEFAULT_SALES_ATTR_CONFIG: DS_QuickAddCartStandard.SalesAttrConfig = {
  selectedAttrIndex: {},
  selectedAttrSkuCode: '',
  showTitleSizeGuide: false,
  showSizeFeedBackEnter: false,
  globalPerfectFitShow: false,
  hideSizeGroupeSize: false,
  showSizeRecommend: false,
  saleAttrsFoldAb: '',
  recommendMySize: '',
  recommendMyBraSize: '',
  showAttrPlusSize: true,
}

/**
 * 默认功能配置
 */
export const DEFAULT_FEATURE_CONFIG: DS_QuickAddCartStandard.FeatureConfig = {
  needLockMall: false,
  needClickToDetail: true,
  needMainSalesAttr: true,
  needRelatedSubSalesAttributes: true,
  needPromotion: true,
  needUnSatisfiedTips: false,
  needQtySelect: true,
  needAutoGetCoupon: true,
  footerBar: {
    isNotSizeBuriedPoint: true,
    needFavoriteButton: true,
  },
  /* 到手价相关 */
  showNoSatisfied: false,
  showEstimatedPrice: false,
  closeEstimatedAndAbPrice: false,
  showNEstimatedPrice: false,
  isFromPromotion: false,
  customClass: '',
  benefitInfo: {
    show: true,
    hideRank: false,
  },
  fetchRankInfoSwitchConfig: true,
}

export const useQuickAddCart = (options?: Partial<DS_QuickAddCartStandard.Options>) => {

  Logger.log('loaded the new QuickAddCart component.')
  Logger.log('pkg version:', pkg.version)

  if(typeof window === 'undefined') return
  const appConfigs = useAppConfigs()
  let currentOptions = options
  const { plugins, excludePlugins, baseConfig, directives } = options || {}
  const component = ref<InstanceType<typeof QuickAddCart> | null>(null)
  const apis = getSource({ appConfigs, dataSource: baseConfig?.dataSource })
  const lang = ref<string | undefined>(appConfigs.$envs.lang)
  const { language } = useModules()

  function resetPlugins(plugins?: DS_QuickAddCartStandard.QuickAddCartPlugin[], excludePlugins?: string[]) {
    pluginManager.cleanPlugin()
    // 注册默认的内置插件
    pluginManager.registerPlugins([brandDealsPlugin(), CustomizationProductPlugin(), apolloConfigPlugin(appConfigs)])
    pluginManager.registerPlugins(plugins || [])
    pluginManager.excludePlugins(excludePlugins || [])
  }
  resetPlugins(plugins, excludePlugins)
  function close() {
    component.value?.closeQuickAddCart()
  }
  async function useComponent() {
    if (component.value) {
      return component.value as InstanceType<typeof QuickAddCart>
    } else {
      const el = await new Promise(resolve => {
        import(/* webpackChunkName: "quick-add-cart-standard" */ '../quick-add-cart-standard.vue').then(components => {
          const QuickAddCart = components.default
          const el = document.createElement('div')
          const app = createApp(QuickAddCart, {
            fsData: baseConfig?.fsData,
            dataSource: baseConfig?.dataSource,
            analysisSource: baseConfig?.analysisSource,
          })
          app.config.errorHandler = (err, vm, info) => {
            Logger.error('[bs-quick-add-cart-standard-mobile]', err, vm, info)
          }
           // 临时注册一下自定义指令
          if(directives) {
            Object.keys(directives).forEach(key => {
              app.directive(key, directives[key] as any)
            })
          }
          const vm = app.mount(el)
          document.body.appendChild(el)
          resolve(vm)
        })
      })
      component.value = el as any
      return el as InstanceType<typeof QuickAddCart>
    }
  }

  async function getLanguagesData() {
    apis.getPageLanguages(['quick_add_cart']).then(res => {
      if (res.info && res.info.result) {
        updateLanguage(res.info.result)
      }
    })
  }

  /**
   * 打开快速加车
   */
  async function open(params: DS_QuickAddCartStandard.GetGoodDetailDataQueryParams, targetOptions?: Partial<DS_QuickAddCartStandard.Options> & {
    plugins?: DS_QuickAddCartStandard.QuickAddCartPlugin[]
  }) {
    const openTime = new Date().getTime()
    const el = await useComponent()
    const currentLang = appConfigs.$envs.lang
    if (!language?.value || currentLang !== lang.value) {
      getLanguagesData().then(() => {
        lang.value = currentLang
      })
    }
    const { plugins, excludePlugins, ...otherOptions } = targetOptions || {}
    pluginManager.resetPlugins()
    pluginManager.registerPlugins(plugins || [])
    pluginManager.excludePlugins(excludePlugins || [])
    pluginManager.sortPluginsByPriority()
    const op = mergeOptions(
    {},
    {
      featureConfig: { ...DEFAULT_FEATURE_CONFIG },
      analysisConfig: { ...DEFAULT_ANALYSIS_CONFIG },
    },
    currentOptions,
    otherOptions) as DS_QuickAddCartStandard.Options

    // 更新主商品标识
    op.featureConfig.isMainProduct = op.featureConfig.isMainProduct || params.goods_id === op.featureConfig.mainPageGoodsId

    Logger.info('openQuickAddCart', op)
    Logger.info('plugins', pluginManager.plugins)

    // 校验参数 (由于中间层的历史原因，需要在这提前校验参数goods_id是否为空)
    if (!params.goods_id) {
      el?.handleGetGoodsDetailDataFail({ code: '-1', msg: 'goods_id is empty' }, op?.callbacks?.onOpenFail)
      return
    }

    // 完整的插件配置
    const completeOp = {
      ...op,
      plugins: pluginManager.plugins,
    }

    // 打开快速加车之前的钩子
    const isStops = await usePlugins().hooks('beforeOpen',
      { params, apis, op: completeOp,
        loadCartSuccess: el?.loadCartSuccess, loadCartFail: el?.loadCartFail,
        onMonitoringTrigger: (monitorInfo) => monitoring().onMonitoringTrigger(monitorInfo),
        analysisInstance: el?.analysisInstance,
      })
    // isStops 数组中有一个返回true就停止执行
    if (isStops.some(item => item)) {
      return
    }

    monitoring(op?.callbacks).onMonitoringTrigger({ type: 'open-start', time: openTime })

    // 初始化快速加车 (组件是单例，所以需要每次open的时候都进行一次初始化)
    el?.initQuickAddCart(op)

    const status = await el?.openQuickAddCart(params)
    monitoring().onMonitoringTrigger({ type: 'open-end', status })
  }

  // use的时候就开始加载多语言
  getLanguagesData()

  // 更新配置
  async function updateOptions(newOptions: Partial<DS_QuickAddCartStandard.Options>) {
    const { plugins, excludePlugins } = newOptions
    Logger.log('update options:', newOptions)
    // 重置插件
    resetPlugins(plugins, excludePlugins)
    // 更新配置
    currentOptions = newOptions
  }

  return {
    open: debounce({
      func: (params: DS_QuickAddCartStandard.GetGoodDetailDataQueryParams, targetOptions?: Partial<DS_QuickAddCartStandard.Options> & {
        plugins?: DS_QuickAddCartStandard.QuickAddCartPlugin[]
      }) => {
        open(params, targetOptions)
      },
      wait: 150,
    }) as typeof open,
    updateOptions,
    close,
  }
}
