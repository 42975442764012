<template>
  <div
    class="c-btn-save"
    :class="{ disabled }"
    @click.stop.prevent="toggleSaveStatus">
    <sui_icon_save_completed_34px
      v-if="isSaved"
      class="c-btn-save_save-icon bsc-save-icon_completed"
      size="32"
      :class="{
        'c-btn-save_anim-start': isSaveStartAnim && !isSaveEndAnim,
        'c-btn-save_anim-end': isSaveEndAnim
      }" />
    <sui_icon_save_34px
      v-else
      class="c-btn-save_save-icon"
      size="32"
      :class="{
        'c-btn-save_anim-start': isSaveStartAnim && !isSaveEndAnim,
        'c-btn-save_anim-end': isSaveEndAnim
      }" />

    <template v-if="addGroupBtnVisible">
      <div
        :class="['btn-save__board', 'board_3']"
        @click.stop="addToBoard"
      >
        <sui_icon_add_circle_18px
          size="18px"
          class="add_circle_icon" />
        {{ language.SHEIN_KEY_PWA_17403 }}
      </div>

    </template>
  </div>
</template>
<script name='FavoriteButton' setup lang='ts'>
import { ref, inject } from 'vue'
import { useAppConfigs } from '@shein-aidc/bs-sdk-libs-manager'
import { getSource } from '../../../../common/dataSource'
import type { DS_QuickAddCartStandard, AS_QuickAddCartStandard } from '../../../../types/index'
import { showToast } from '../../utils/toastQueue.ts'

// @ts-ignore 该库未提供类型定义
import { sui_icon_save_34px, sui_icon_add_circle_18px, sui_icon_save_completed_34px } from '@shein-aidc/icon-vue3'
const appConfigs = useAppConfigs()
const props = defineProps<{
    isSaved: boolean
    goodsId?: string
    mallCode?: string
    disabled?: boolean
    skuCode?: string
    language: DS_QuickAddCartStandard.Language
    dataSource: DS_QuickAddCartStandard.DataSource
}>()
const { dataSource } = props
const apis = getSource({ appConfigs, dataSource: dataSource })
const addGroupBtnVisible = ref(false)
const isSaveStartAnim = ref(false)
const isSaveEndAnim = ref(false)
const emit = defineEmits<{
    (event: 'onToggleLoginStatus'): void;
    (event: 'onClickAddToBoard' ): void;
    (event: 'onToggleWishStatus', data: { isSaved: boolean }): void;
    (event: 'onToggleWishFetchFinish', data: DS_QuickAddCartStandard.PostWhishlistResponse & { isNotSelected: boolean}): void
}>()
const analysisInstance = inject<AS_QuickAddCartStandard.AnalysisInstance>('analysisInstance')

async function doFetch(isNotSelected: boolean) {
  if (props.goodsId) {
    const res = await apis.postWishlist({
        goods_id: props.goodsId,
        mall_code: props.mallCode,
        sku_code: props.skuCode,
    }, isNotSelected)
    emit('onToggleWishFetchFinish',{
        ...res.info!,
        isNotSelected,
    })
    return res
  }

}

const addToBoard = () => {
    emit('onClickAddToBoard')
    analysisInstance?.triggerNotice({
        id: 'click_board_toast.comp_quick-add-cart-standard',
        data: {
            eventCategory: '列表页', // 不知道为什么写死了列表页~
            board_count: 1,
        },
    })
}

const toggleSaveStatus = async () => {
    if (props.disabled) return
    const isLogin = appConfigs.$getters?.isLogin
    if (!isLogin) {
        // @ts-ignore
        if (window.SHEIN_LOGIN) {
            // 未登录
            // @ts-ignore
            window.SHEIN_LOGIN.show({
                show: true,
                from: 'other',
                cb: () => {
                    toggleSaveStatus()
                    emit('onToggleLoginStatus')
                },
            })
        }
        return
    }
    const { isSaved } = props
    if (isSaveStartAnim.value) return
    const isNotSelected = isSaved === true
    isSaveEndAnim.value = false
    isSaveStartAnim.value = true
    const result = await doFetch(isNotSelected)
    isSaveStartAnim.value = false
    if (result && result.info?.result === 1) {
        isSaveEndAnim.value = true
        emit('onToggleWishStatus', {
            isSaved: !isSaved,
        })
        addGroupBtnVisible.value = result.info.has_group === 1
        if (addGroupBtnVisible.value) {
            analysisInstance?.triggerNotice({
                id: 'expose_board_toast.comp_quick-add-cart-standard',
                data: {
                   board_count: 1,
                },
            })
            setTimeout(() => {
                addGroupBtnVisible.value = false
          }, 5000)
        }
    } else {
        showToast(result?.msg || props.language.SHEIN_KEY_PWA_20051 || 'error', 1200)
    }

}

// 暴露变量，提供给e2e测试使用
defineExpose({
  addGroupBtnVisible,
})
</script>
<style lang="less" scoped>
@keyframes goodsli-like-anim-click {
    0% {
        transform: scale(1);
    }

    33% {
        transform: scale(1.2);
        opacity: 1;
    }

    100% {
        transform: scale(0.9);
        opacity: 0.6;
    }
}

@keyframes goodsli-like-anim-complete {
    0% {
        transform: scale(0);
    }

    100% {
        transform: scale(1);
    }
}

.c-btn-save {
    margin-right: 9px;
    width: 36px;
    height: 36px;
    display: inline-block;
    line-height: 36px;
    text-align: center;
    position: relative;
    flex-shrink: 0;

    &.disabled {
        pointer-events: none;

        .c-btn-save_save-icon {
            color: #e5e5e5;
        }
    }

    .sui_icon_save_completed_32px {
        color: @sui_color_brand;
    }

    &_anim-start {
        display: block;
        animation: goodsli-like-anim-click .3s cubic-bezier(.4, 0, .2, 1) forwards, goodsli-like-anim-loading .8s cubic-bezier(.4, 0, .2, 1) .3s infinite;
    }

    &_anim-end {
        display: block;
        animation: goodsli-like-anim-complete .2s ease both;
    }

    &_save-icon {
        font-size: 0.53rem;
    }
}



.btn-save__board {
  transition: transform 0.5s;
//   .left(0);
  left: 0;
  bottom: 0;
  height: 8.533vw;
  line-height: 8.533vw;
  --board-init-top: 0;
  animation: add-board-keyframes 500ms forwards ease;
  display: flex;
  align-items: center;
  position: absolute;
  height: 8.533vw;
  padding-left: 2.666vw;
  padding-right: 3.2vw;
  background: #000;
  color: #fff;
  font-size: 12px;
  white-space: nowrap;
  opacity: 0.8;
  &.board_3 {
    --board-top: -12vw;
    /*rw:begin*/
    border-radius: 2px;
  }
}
.add_circle_icon {
    margin-right: 1.067vw;
    vertical-align: middle;
    font-size: 18px;
  }
@keyframes add-board-keyframes {
  0% {
    opacity: 0;
    top: var(--board-init-top);
  }
  100% {
    opacity: .8;
    top: var(--board-top);
  }
}
</style>
