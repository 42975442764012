<script lang="jsx">
import { SButton as Button } from '@shein-aidc/sui-button/mobile'
import { Icon } from '@shein-aidc/icon-vue3'
export default {
  name: 'SizeFeedBackSelectPanel',
  functional: true,
  props: {
    language: {
      type: Object,
      default: () => ({}),
    },
    status: {
      type: String,
      default: '',
    },
    locationSelectShow: {
      type: Boolean,
      default: false,
    },
    // hasLocalSizeOptions: {
    //   type: Boolean,
    //   default: false,
    // },
    feedBackSizeList: {
      type: Array,
      default: () => [],
    },
    heightRangeSizeList: {
      type: Array,
      default: () => [],
    },
    selectedSize: {
      type: Object,
      default: () => ({}),
    },
    country: {
      type: String,
      default: '',
    },
  },
  emits: ['onOpenLocationBtn', 'selectSize', 'handleSubmit'],
  setup(props, { emit }) {
    return () => {
      const {
        language,
        status,
        locationSelectShow,
        // hasLocalSizeOptions,
        feedBackSizeList = [],
        heightRangeSizeList = [],
        selectedSize = {},
        country,
      } = props

      // TODO: hasLocalSizeOptions 感觉是bug, 先删掉了，后面再看
      // const locationSelectText = !country
      //   ? language.SHEIN_KEY_PWA_23344
      //   : `${country} ${language.SHEIN_KEY_PWA_15701}`

      // // 本地选择
      // const locationSelect = () =>
      //   locationSelectShow ? (
      //     <div
      //       class="region-select-box__local"
      //       onClick={() => emit('onOpenLocationBtn')}
      //     >
      //       <span>
      //         {locationSelectText}
      //         <Icon name="sui_icon_more_down_12px_2" size="12px" />
      //       </span>
      //     </div>
      //   ) : null

      // 本地化选择面板
    const localSizeSelectPanel = () =>
      status === 'ready' ? (
        <div>
          <div class="tips-band">* {language.SHEIN_KEY_PWA_21089}</div>
        </div>
      ) : null

    // 尺码选择面板
    const sizeSelectItem = (type) => {
      let list = []
      if(type === 'height'){
        list = heightRangeSizeList
      }else{
        list = feedBackSizeList
      }
      return (
      list?.map((size) => (
        <li class={[{ active: size.key == selectedSize.key }]}>
          <div
            class="ellipsis"
            onClick={() => emit('selectSize', size)}
          >
            {size.text}
          </div>
        </li>
      ))
      )
    }

    const sizeSelectPanel = (type) => (
      <div class="size-select-box">
        {
          type === 'size' && heightRangeSizeList.length > 0 || type === 'height' ?
            <div class="select-title">
              {
                type === 'height' ?
                  language.SHEIN_KEY_PWA_35979 || 'Petite/Tall Height Range' :
                  language.SHEIN_KEY_PWA_35981 || 'Regular Size'
              }
            </div>
            : null
        }
        <ul class="size-select-list">{sizeSelectItem(type)}</ul>
      </div>
    )

      // submit
      const submitContent = () => (
        <div class="submit-box">
          <Button
            type={['primary', 'H40PX']}
            width={'100%'}
            onClick={() => emit('handleSubmit')}
          >
            {language.SHEIN_KEY_PWA_21198}
          </Button>
        </div>
      )

      return (
        <div>
          {localSizeSelectPanel()}
          {sizeSelectPanel('size')}
          {heightRangeSizeList.length > 0 ? sizeSelectPanel('height') : null}
          {submitContent()}
        </div>
      )
    }
  },
}
</script>
