<template>
  <div
    class="quickEstimatedTagNewRetail"
    @click.stop="clickEstimatedTag">
    <p
      class="quickEstimatedTagNewRetail__retail"
      :style="estimatedInfo.retailPriceStyle">
      {{ retailPrice }}
    </p>
    <i
      v-if="hasLink"
      class="suiiconfont sui_icon_more_right_12px_2 price-new-estimated__sale-icon"
      :style="estimatedInfo.retailPriceStyle"></i>
  </div>
</template>

<script setup name="EstimatedTagNewRetail">
import { computed, inject } from 'vue'
import { ESTIMATED_STYLE_TYPES, ESTIMATED_DATA_TYPES } from '../../../../../../hook/utils'

const { triggerNotice } = inject('analysisInstance')
const emits = defineEmits(['clickEstimatedTag'])

const props = defineProps({
  estimatedInfo: {
    type: Object,
    default: () => ({}),
  },
  isWrap: {
    type: Number,
    default: -1,
  },
})

const retailPrice = computed(() => props.estimatedInfo.retailPrice?.amountWithSymbol)
const hasLink = computed(() => props.estimatedInfo.otherInfo.hasLink)

const isNew = computed(() => {
  let { NEW, NEW_AND_NO_THRESHOLD_FLOOR } = ESTIMATED_STYLE_TYPES
  return [NEW, NEW_AND_NO_THRESHOLD_FLOOR].includes(props.estimatedInfo.styleType)
})

const analysisData = computed(() => {
  let { estimatedInfo } = props || {}
  let { dataType, otherInfo, value, endTime, noThresholdFloor } = estimatedInfo || {}
  let { NO_SATISFY, AB_OVERLAP_NO_SATISFY } = ESTIMATED_DATA_TYPES
  let { isQuickAdd } = otherInfo
  let nothroshowtype = null
  if ([NO_SATISFY, AB_OVERLAP_NO_SATISFY].includes(dataType)) {
    nothroshowtype = noThresholdFloor ? 1 : 0
  }
  return {
    threshold: dataType,
    estimated_price: value.usdAmount,
    timelimit: noThresholdFloor?.endTime || endTime ? 1 : 0,
    showtype: isNew.value ? 1 : 0,
    clubprice: 0,
    location: isQuickAdd ? 'popup' : 'page', // 商详 page 加车弹窗 popup
    ...(nothroshowtype === null ? {} : { nothroshowtype }),
  }
})

const clickEstimatedTag = () => {
  if (!hasLink.value) return
  triggerNotice({
    id: 'click_estimated_price.comp_quick-add-cart-standard',
    extraData: analysisData.value,
  })
  emits('clickEstimatedTag')
}

</script>

<style lang="less">
.quickEstimatedTagNewRetail {
  display: flex;
  align-items: center;
  align-self: normal;
  flex-wrap: nowrap;

  &__retail {
    color: #767676;
    font-size: 10px;
    text-decoration: line-through;
  }

  .price-new-estimated__sale-icon {
    color: #767676;
  }

}
</style>
