<template>
  <div
    class="quickDiscountPercent"
    :class="{ 'countdownNewStyle': countdownNewStyle }"
    :style="styleMap"
  >
    {{ value }}
  </div>
</template>

<script setup name="DiscountPercent">
/**
 * @file 折扣百分比小方块
*/

defineProps({
  value: {
    type: String,
    default: '',
  },
  styleMap: {
    type: Object,
    default: () => ({}),
  },
  countdownNewStyle: {
    type: Boolean,
    default: false,
  },
})
</script>

<style lang="less">
.quickDiscountPercent {
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  line-height: 1;
  direction: ltr /*rtl:ignore*/;
  font-size: 10px;
  font-weight: 400;
  font-style: normal;
  padding: 1px 2px;
  min-width: 0.8533rem;
  &.countdownNewStyle{
    border: none !important;
    font-weight: bold;
    padding: 0 3px 0 3px;
    min-width: 0;
  }
}
</style>
