<template>
  <div class="bsc-placeholder-container">
    <!-- 商品主图占位 -->
    <div class="placeholder-image-view-group">
      <!-- 多图 -->
      <div
        v-if="imageList?.length > 1"
        class="scroll-list">
        <div
          v-for="(imageItem, index) in imageList"
          :key="`img_${imageItem}_${index}`"
          class="scroll-item">
          <img
            v-if="index < 2"
            class="image-item"
            :src="imageItem || lazyImage"
            alt="" />
          <img
            v-else
            :src="lazyImage"
            :data-src="imageItem || lazyImage"
            class="image-item lazyload"
            alt=""
          />
        </div>
      </div>
      <!-- 单图 -->
      <div
        v-else-if="imageList?.length === 1 && imageList[0]"
        class="single-image">
        <img
          class="image-item"
          :src="imageList[0]"
          alt="" />
      </div>
      <!-- 无图 -->
      <div
        v-else
        class="scroll-list">
        <div
          v-for="(imageItem, index) in [1, 2, 3]"
          :key="`img_${imageItem}_${index}`"
          class="scroll-item">
          <img
            :src="lazyImage"
            class="image-item lazyload"
            alt="" />
        </div>
      </div>
    </div>

    <div class="placeholder-text-view-group">
      <div class="placeholder-goods-prices bg-color"></div>
      <div class="placeholder-goods-name bg-color"></div>
      <div class="placeholder-goods-desc bg-color"></div>
      <ul class="placeholder-goods-colors">
        <li class="placeholder-goods-color-item bg-color"></li>
        <li class="placeholder-goods-color-item bg-color"></li>
        <li class="placeholder-goods-color-item bg-color"></li>
        <li class="placeholder-goods-color-item bg-color"></li>
        <li class="placeholder-goods-color-item bg-color"></li>
      </ul>
      <div class="placeholder-goods-country bg-color"></div>
      <ul class="placeholder-goods-size">
        <li class="placeholder-goods-size-item bg-color"></li>
        <li class="placeholder-goods-size-item bg-color"></li>
        <li class="placeholder-goods-size-item bg-color"></li>
        <li class="placeholder-goods-size-item bg-color"></li>
        <li class="placeholder-goods-size-item bg-color"></li>
      </ul>
      <div class="placeholder-handle-btn-container">
        <div class="placeholder-handle-btn"></div>
      </div>
    </div>
  </div>
</template>

<script name="PlaceholderGoodsInfo" setup lang="ts">

withDefaults(defineProps<{
  imageList: string[],
  lazyImage: string,
}>(),{})

</script>

<style lang="less">
.bsc-placeholder-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  padding-top: 2vw;
  padding-bottom: 3.3vw;
  opacity: 1;
  animation: 700ms loading ease-in-out infinite;

  .placeholder-image-view-group {
    position: relative;
    padding-bottom: 2vw;
    padding-left: 3.2vw;
    > .scroll-list {
      display: flex;
      width: 100%;
      height: 58.13vw;
      overflow-x: scroll;
      > .scroll-item {
        position: relative;
        height: 100%;
        margin-left: 2.13vw;
        &:first-child {
          margin-left: 0;
        }
        &:last-child {
          padding-right: 2.13vw;
        }
        > .image-item {
          width: 43.73vw;
          height: 58.13vw;
        }
      }
      &::-webkit-scrollbar {
        display: none;
      }
    }
    > .single-image {
      display: flex;
      width: 100%;
      height: 62.6667vw;
      > .image-item {
        position: relative;
        height: 100%;
        margin: 0 auto;
      }
    }
  }

  .placeholder-text-view-group {
    padding: 0 3.2vw;
  }
  .bg-color {
    background: #f4f4f4;
  }
  .placeholder-goods-prices {
    width: 20vw;
    height: 6vw;
    margin-bottom: 2vw;
  }
  .placeholder-goods-name {
    width: 100%;
    height: 5vw;
    margin-bottom: 2vw;
  }
  .placeholder-goods-desc {
    width: 20vw;
    height: 5vw;
    margin-bottom: 2vw;
  }
  .placeholder-goods-colors {
    display: flex;
    margin-bottom: 3vw;
  }
  .placeholder-goods-color-item {
    width: 7.466vw;
    height: 7.466vw;
    border-radius: 3.733vw;
    margin-right: 2.666vw;
  }
  .placeholder-goods-country {
    width: 20vw;
    height: 5vw;
    margin-bottom: 2vw;
  }
  .placeholder-goods-size {
    display: flex;
    margin-bottom: 3vw;
  }
  .placeholder-goods-size-item {
    width: 16vw;
    height: 7.466vw;
    border-radius: 3.733vw;
    margin-right: 2.666vw;
  }
  .placeholder-handle-btn-container {
    margin-top: 14.5vw;
    width: 100%;
    .placeholder-handle-btn {
      width: 100%;
      height: 9.6vw;
      background-color: #f4f4f4;
    }
  }
}

@keyframes loading {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    opacity: 1;
  }
}
</style>
