<template>
  <div
    v-expose="{ id: 'expose_s3_vipprice.comp_quick-add-cart-standard', data: { from: 1 }}"
    class="quickS3Tag"
  >
    <p class="quickS3Tag__currency-price">{{ exclusiveInfo.amountWithSymbol }}</p>
    <div class="quickS3Tag__line"></div>
    <img
      class="quickS3Tag__img"
      :src="exclusiveInfo.imgLink" />
    <p class="quickS3Tag__exclusive">{{ exclusiveInfo.text }}</p>
    <i
      class="suiiconfont sui_icon_doubt_12px_2 quickS3Tag__icon"
      @click="showModal"></i>

    <SDialog
      :visible="dialogShow"
      class="quickS3Tag__dialog">
      <div class="quickS3Tag__dialog-container">
        <h4 class="quickS3Tag__modal-title">{{ exclusiveInfo.text }}</h4>
        <div class="quickS3Tag__modal-desc">
          {{ exclusiveInfo.introText }}
        </div>
      </div>
      <SButton
        :type="['primary']"
        width="100%"
        @click="showModal"
      >
        {{ exclusiveInfo.btnText }}
      </SButton>
    </SDialog>
  </div>
</template>

<script setup name="S3Tag">
import { SButton } from '@shein-aidc/sui-button/mobile'
import { SDialog } from '@shein-aidc/sui-dialog/mobile'
import { ref, inject } from 'vue'

const { vExpose, triggerNotice } = inject('analysisInstance')

defineProps({
  exclusiveInfo: {
    type: Object,
    default: () => ({}),
  },
})

const dialogShow = ref(false)

const showModal = () => {
  if (!dialogShow.value) {
    triggerNotice({
      id: 'click_s3_vipprice.comp_quick-add-cart-standard',
      extraData: {
        from: 1,
      },
    })
  }
  dialogShow.value = !dialogShow.value
}

</script>

<style lang="less">
.quickS3Tag {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: flex-end;
  height: 0.48rem;
  padding: 0 0.1067rem;
  background: linear-gradient(0deg, rgba(224, 203, 158, 0.6), rgba(224, 203, 158, 0.6)), #ffffff;
  border-radius: 0.0533rem;
  line-height: 1;
  &__currency-price {
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    display: flex;
    align-items: center;
    color: #222222;
  }

  &__line {
    width: 1px;
    height: 0.32rem;
    opacity: 0.2;
    box-sizing: border-box;
    margin: 0 0.1067rem;
    background: #000;
  }

  &__img {
    width: 0.6933rem;
    height: 0.2667rem;
  }

  &__exclusive {
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 0.32rem;
    color: #222222;
    margin-left: 0.0533rem;
  }

  &__icon {
    display: block;
    color: #ae9866;
    margin-left: 0.08rem;
  }

  &__modal-title {
    line-height: 0.88rem;
    font-weight: bold;
    font-weight: 700;
  }

  &__modal-desc {
    color: #a78a45;
    line-height: 0.88rem;
    font-weight: 700;
  }
  .c-modal-wrap {
    .modal-footer {
      .modal-btn {
        border-top: none;
        border-radius: 0;
      }
    }
  }

  &__dialog {
    .S-dialog__normal {
      width: 5.87rem;
    }
    .S-dialog__top {
      display: none;
    }
    .S-dialog__body {
      padding: 0;
    }
    .S-button {
      line-height: 1.15;
      font-size: 12px;
      font-weight: normal;
    }

  }
  &__dialog-container {
    padding: .6rem .4rem .47rem;
    text-align: center;
    font-size: 12px;
  }
}
</style>
