<template>
  <div
    class="bsc-quick-add-cart__header"
    :class="{
      'bsc-quick-add-cart__with_benefit': showBenefitInfo
    }">

    <!-- 利益点轮播展示 -->
    <transition name="fade">
      <swiper-container
        v-if="showBenefitInfo"
        ref="swiperEl"
        init="false"
        class="bsc-quick-add-cart__benefit_swiper"
      >
        <swiper-slide
          v-for="(item, index) in benefitInfos"
          :key="item.sort"
          :index="index"
        >
          <div
            class="bsc-quick-add-cart__benefit_item"
            :style="{
              backgroundColor: item.bgColor,
              color: item.color
            }">
            <img
              v-if="item.icon"
              :src="item.icon"
            />
            <span class="bsc-quick-add-cart__benefit_text">{{ item.tips }}</span>
          </div>
        </swiper-slide>
      </swiper-container>
    </transition>

    <div
      ref="close"
      class="bsc-quick-add-cart__close-btn"
      tabindex="0"
      role="button"
      :aria-label="language?.SHEIN_KEY_PWA_15567"
      @click="closeQuickAddCart">
      <Icon
        name="sui_icon_nav_close_24px"
        width="4.28vw"
        height="4.28vw"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { BHeaderProps } from './types'
import { Icon } from '@shein-aidc/icon-vue3'
import { computed, watch, ref, nextTick, inject } from 'vue'
import { Autoplay } from 'swiper/modules'
import type { DS_QuickAddCartStandard, AS_QuickAddCartStandard } from 'packages/bs/quick-add-cart-standard/types/index.ts'
import { BENEFIT_INFO_MAP } from './helper'
import { LRUCache } from '../../utils/cache'

const props = defineProps(BHeaderProps)

const analysisInstance = inject<AS_QuickAddCartStandard.AnalysisInstance>('analysisInstance')

const rankCache = new LRUCache<any>(10, 1000 * 60 * 5)
// 利益点露出曝光埋点 cache
let exposeDisplayCache: Record<string | number, Record<number | string, boolean>> = {}
// 不受 abt 控制的利益点 cache
let exposeAbtCache: Record<string | number, boolean> = {}

const swiperEl = ref<HTMLElement | null>(null)

const benefitInfos = ref<DS_QuickAddCartStandard.BenefitInfoItem[]>([])

const PopupDetailBenefitParam = computed(() => props.fsAbt.PopupDetailBenefit?.p?.PopupDetailBenefit)

const showBenefitInfo = computed(() => props.featureConfig?.benefitInfo?.show && benefitInfos.value.length)

let lastCacheKey: string | undefined = ''

async function handleRankBenefit () {
  /**
   * hideRank 用户侧传入控制
   * fetchRankInfoSwitchConfig apollo 控制，降级使用
   */
  if (props.featureConfig?.benefitInfo?.hideRank || !props.featureConfig?.fetchRankInfoSwitchConfig) return

  const {
    productInfo,
    mallCode,
    apis,
  } = props

  let rankRes: any = null
  const cacheKey = props.mallCode ? `${productInfo?.goods_id}_${props.mallCode}` : undefined
  lastCacheKey = cacheKey

  if (cacheKey && rankCache.get(cacheKey)) {
    rankRes = rankCache.get(cacheKey)
  } else {
    const res = await apis.getRankTopInfo({
      queryType: 4,
      catId: productInfo?.cat_id,
      goodsId: productInfo?.goods_id,
      goodsSn: productInfo?.goods_sn,
      mallCode: mallCode,
      sort: 0,
      scene: 'detail',
    })

    if (cacheKey) {
      rankCache.set(cacheKey, res)
    }

    rankRes = lastCacheKey ? rankCache.get(lastCacheKey) : res
  }

  const rankInfo = rankRes?.info?.firstScreenRankInfo?.content?.[0]?.content?.props?.items?.[0]?.rank_of_goods?.list?.[0]
  if (rankInfo) {
    /**
     score: 排名
     rankingTypeText: 榜单类型
     composeIdText: 榜单名称
     mobileIdentifier: 榜单类型标志符（热销榜 15 好评榜 16）
    */
    const { score, rankingTypeText, composeIdText, mobileIdentifier } = rankInfo
    const rankTips = `#${score} ${rankingTypeText} ${composeIdText}`
    const rankToSortMap = {
      15: 19,
      16: 20,
      77: 21
    }

    benefitInfos.value.push({
      sort: rankToSortMap[mobileIdentifier],
      tips: rankTips,
    })
  }
}

async function handleBenefitInfos () {
  const {
    skuList,
    shipInfo,
  } = props

  const bffBenefitInfos = props.addCartInfo.popupDetailBenefitInfo?.benefitInfos || []

  // 商品榜单利益点 (端上处理)
  await handleRankBenefit()

  // 商品 qs 利益点 (端上处理)
  const isSupportQuickShip = !!skuList?.find?.(item => Number(item.isSupportQuickShip) === 1)
  const quickShipContent = shipInfo.quickShipContent
  if (isSupportQuickShip && quickShipContent) {
    benefitInfos.value.push({
      sort: 1, //  qs 利益点
      tips: quickShipContent,
    })
  }

  benefitInfos.value = [
    ...benefitInfos.value,
    ...bffBenefitInfos,
  ]

  handleAbtExposeBenefit(benefitInfos.value)

  // abt 利益点策略处理
  if (!PopupDetailBenefitParam.value || PopupDetailBenefitParam.value === 'off') {
    benefitInfos.value = []
    return
  }

  const sortToBenefitMap: Record<number | string, DS_QuickAddCartStandard.BenefitInfoItem> = benefitInfos.value.reduce((memo, item) => {
    const { sort } = item
    memo[sort] = item
    return memo
  }, {})

  const benefitsSortArr = PopupDetailBenefitParam.value.split(',')

  benefitInfos.value = benefitsSortArr.reduce((memo, sort) => {
    let BenefitItem = sortToBenefitMap[sort]
    if (BenefitItem) {
      BenefitItem = {
        ...BenefitItem,
        ...BENEFIT_INFO_MAP[sort],
      }
      memo.push(BenefitItem)
    }
    return memo
  }, [])

  // 最多只显示 5 个利益点
  benefitInfos.value = benefitInfos.value.slice(0, 5)
}

function handleAbtExposeBenefit(benefitInfos = []) {
  const benefitTypeArr = benefitInfos.map(item => item.sort)
  const currentGoodsId = props.productInfo?.goods_id as string
  if (benefitTypeArr.length && !exposeAbtCache[currentGoodsId]) {
    analysisInstance?.triggerNotice({
      id: 'expose_popup_benefit_abt.comp_quick-add-cart-standard',
      data: {
        benefit_type: benefitTypeArr.join(','),
        goods_id: currentGoodsId,
      },
    })
    exposeAbtCache[currentGoodsId] = true
  }
}

async function handleDisplayExposeBenefit(index) {
  await nextTick()

  const currentGoodsId = props.productInfo?.goods_id as string
  const currentSort = benefitInfos.value[index]?.sort

  if (!currentSort || exposeDisplayCache[currentGoodsId]?.[currentSort]) return

  analysisInstance?.triggerNotice({
    id: 'expose_popup_benefit_display.comp_quick-add-cart-standard',
    data: {
      benefit_type: currentSort,
      goods_id: currentGoodsId,
    },
  })

  if (!exposeDisplayCache[currentGoodsId]) exposeDisplayCache[currentGoodsId] = {}
  exposeDisplayCache[currentGoodsId][currentSort] = true
}

function initBenefitSwiper () {
  const swiperParams = {
    slidesPerView: 'auto',
    initialSlide: 0,
    observer: true,
    observeParents: true,
    direction: 'vertical',
    loop: true,
    modules: [Autoplay],
    autoplay: {
      delay: 3000,
      disableOnInteraction: false,
    },
    allowTouchMove: false,
    on: {
      slideChangeTransitionEnd: swiper => {
        // dom 取真实 realIndex
        const currentSlideEl = swiper.slidesEl.querySelector('.swiper-slide-active')
        const realIndex = currentSlideEl?.getAttribute('index')
        handleDisplayExposeBenefit(realIndex)
      },
      init: swiper => {
        handleDisplayExposeBenefit(swiper.realIndex)
      },
    },
  }
  Object.assign(swiperEl.value as HTMLElement, swiperParams)
  ;(swiperEl.value as any).initialize()
}

watch(() => props.isSwitchColor, val => val && (benefitInfos.value = []))

watch(() => props.mallCode, _ => benefitInfos.value = [])

watch(
  () => props.productInfo,
  val => {
    benefitInfos.value = []
    // 弹窗关闭，重置
    if (!val) {
      rankCache.clear()
      exposeDisplayCache = {}
      exposeAbtCache = {}
    } else {
      // 处理利益点
      handleBenefitInfos()
    }
  },
)

watch(() => showBenefitInfo.value, val => val && nextTick(initBenefitSwiper))

</script>

<style lang="less">
.bsc-quick-add-cart {
  &__header {
    height: 30px;
    padding: 2px;
    background: #fff;
    position: relative;
    flex-shrink: 0;
  }

  &__with_benefit {
    height: 32px;
    margin-bottom: 8px;
    padding: 0px;
  }

  &__benefit_swiper {
    height: 100%;
  }

  &__benefit_item {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: flex;
    justify-content: center;
    padding: 8px 28px 8px 30px;
    font-size: 12px;

    img {
      width: 16px;
      height: 16px;
      margin-top: 0.5px;
    }
  }

  &__benefit_text {
    margin-left: 4px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__close-btn {
    padding: 4px;
    position: absolute;
    right: 2px;
    top: 2px;
    z-index: 1;
  }

  .fade-enter-active {
    transition: opacity 0.5s ease;
  }

  .fade-enter-from {
    opacity: 0;
  }
}
</style>
