
<template>
  <div
    ref="sizeRecommendRef"
    class="recommendEntrance-size"
    :class="{'has-left-alone': position === 'alone'}"
  >
    <!-- 第三方size推荐 -->
    <div
      v-if="showCheckMySizeExternal"
      class="j-sa-check-size j-btn-check-my-size-wrapper"
    ></div>
    <a
      v-if="showCheckMySizeEntrance"
      class="link-check-my-size"
      @click="selfSizeRecommendClick"
    >
      <Icon
        v-if="position === 'alone'"
        name="sui_icon_check_my_size_14px"
        size="14px"
      />
      <p
        class="check-my-size-text"
        v-html="recommendSizeTextDom">
      </p>
      <Icon
        name="sui_icon_more_right_12px_2"
        size="12px" />
    </a>
    <!-- Sizeguide新入口 -->
    <a
      v-if="showSizeGuideEntry"
      v-expose="{
        id: 'expose_size_guide.comp_sub-sales-attr',
        prefix: 'comp_sub-sales-attr',
        data: { from: isFromPopup ? 2 : 1 }
      }"
      v-tap="{
        id: 'click_size_guide.comp_sub-sales-attr',
        prefix: 'comp_sub-sales-attr',
        data: { from: isFromPopup ? 2 : 1 }
      }"
      state="sizeGuideSizeEntry"
      class="link-check-my-size j-push-history-hash"
      @click="handleSizeGuide"
    >
      <p>
        {{ language.SHEIN_KEY_PWA_15005 }}
      </p>
      <Icon
        name="sui_icon_more_right_12px_2"
        size="12px" />
    </a>
  </div>
</template>

<script setup>
import { defineProps, watch, computed, ref, onMounted, onBeforeUnmount } from 'vue'

import analysisInstance from '../utils/useAnalysisSdk.js'
const { vTap, vExpose, triggerNotice } = analysisInstance || {}

import { Icon } from '@shein-aidc/icon-vue3'
const props = defineProps({
  sizeRecommendInfo: {
    type: Object,
    default: () => {},
  },
  sizeAttrInfo: {
    type: Object,
    default: () => ({}),
  },
  language: {
    type: Object,
    default: () => {},
  },
  goodsId: {
    type: String,
    default: '',
  },
  hasOneSize: {
    type: Boolean,
    default: false,
  },
  isFromPopup: {
    type: Boolean,
    default: false,
  },
  handleCalcSize: {
    type: Function,
    default: () => {},
  },
  handleOpenSizeGuideFn: {
    type: Function,
    default: () => {},
  },
  handleSizeRecommend: {
    type: Function,
    default: () => {},
  },
  position: {
    type: String,
    default: '',
  },
})
const sizeRecommendRef = ref(null)
const loadPdpError = ref(false)
const timer = ref('')
const domObserver = ref(null)
const sizeRecommendType = computed(() => {
  if (loadPdpError.value || props.hasOneSize) return 0
  return props.sizeRecommendInfo?.sizeRecommendType || 0
})

const sizeGuidAlone = computed(() => props.sizeRecommendInfo?.sizeGuidAlone || false)
const showSizeGuide = computed(() => props.sizeRecommendInfo?.showSizeGuide || false)

// sizeguide入口
const showSizeGuideEntry = computed(() => props.position !== 'alone' && showSizeGuide.value && (sizeGuidAlone.value || (!sizeGuidAlone.value && sizeRecommendType.value === 0)))
// 自有check my size
const showCheckMySizeEntrance = computed(() => sizeRecommendType.value === 1 && (!sizeGuidAlone.value || sizeGuidAlone.value && props.position === 'alone'))
// 第三方 check my size
const showCheckMySizeExternal = computed(() => sizeRecommendType.value === 2 && (!sizeGuidAlone.value || sizeGuidAlone.value && props.position === 'alone'))

const ruleType = computed(() => props.sizeRecommendInfo?.ruleType || '')
const recommendSizeTextDom = computed(() => {
  const { currentMySize, currentMyBraSize } = props.sizeRecommendInfo
  const recommendText = (ruleType.value == '0' ? currentMySize : currentMyBraSize) || ''
  const attrItem = props.sizeAttrInfo?.attr_value_list?.find?.(item => item?.attr_value_name == recommendText)
  let text = recommendText && attrItem ? (props.handleCalcSize?.(attrItem)?.renderText || recommendText) : ''
  if (text) {
    return `<b>“${text}”</b> ${props.language?.SHEIN_KEY_PWA_18535 || ''}`
  }
  return props.language?.SHEIN_KEY_PWA_18113 || ''
})
const exposeSizeRecommend = (method) => {
  if(!method) return
  triggerNotice({
    id: 'expose_sizerecommended.comp_sub-sales-attr',
    extraData: {
      goods_id: props.goodsId || '',
      method,
    },
  })
}
const reportSizeBi = () => {
  const method = sizeRecommendType.value == 1 ? 'self' : 'third_party'
  triggerNotice({
    id: 'click_goods_detail_check_size.comp_sub-sales-attr',
    extraData: {
      goods_id: props.goodsId || '',
      method,
    },
  })
}
const traceCheckSize = () => {
  const touchEleClass = '.fitanalytics__button'
  const touchstartHandler = () => timer = Date.now()
  const touchendHandler = () => {
    if (Date.now() - timer <= 300) {
      reportSizeBi()
    }
    timer = -1
  }
  // 无法监听到第三方的点击事件
  let timer = -1
  // $(document).on('touchstart', touchEleClass, touchstartHandler)
  // $(document).on('touchend', touchEleClass, touchendHandler)
  const handleTouchStart = (event) => {
    if ((event?.target)?.closest?.(touchEleClass || '')) {
      touchstartHandler()
    }
  }
  const handleTouchEnd = (event) => {
    if ((event?.target)?.closest?.(touchEleClass || '')) {
      touchendHandler()
    }
  }
  document.addEventListener('touchstart', handleTouchStart, false)
  document.addEventListener('touchend', handleTouchEnd, false)
  onBeforeUnmount(() => {
    clearTimeout(timer.value)
    document.removeEventListener('touchstart', handleTouchStart, false)
    document.removeEventListener('touchend', handleTouchEnd, false)
    domObserver.value?.disconnect()
    // $(document).off('touchstart', touchEleClass, touchstartHandler)
    // $(document).off('touchend', touchEleClass, touchendHandler)
  })
}
const handleSizeGuide = () => {
  props.handleOpenSizeGuideFn?.('sizeGuideSizeEntry', props.isFromPopup ? 'cart_title' : 'detail_title')
}
const selfSizeRecommendClick = () => {
  props.handleSizeRecommend?.()
  reportSizeBi()
}

watch(sizeRecommendType, async (val) => {
  if (typeof window === 'undefined') return
  if (val === 1) {
    exposeSizeRecommend('self')
  }
  if (val === 2) {
    try {
      // eslint-disable-next-line no-unsafe-optional-chaining
      const { message = '' } = await window.TPM?.runImmediate({
        marketing: 'Fitanalytics',
        method: '_loadPdp',
      })
      if (message == 'success') {
        clearTimeout(timer.value)
        timer.value = setTimeout(() => {
          const hasThirdDom = () => {
            if (typeof window === 'undefined') return false
            const fitanalyticsButton = document?.getElementsByClassName('fitanalytics__button')?.[0]
            return fitanalyticsButton && fitanalyticsButton?.style?.display !== 'none'
          }
          if (hasThirdDom()) {
            exposeSizeRecommend('third_party')
          }else if(showSizeGuide.value) {
            const config = { attributes: true, childList: true, subtree: true }
            domObserver.value?.disconnect?.()
            domObserver.value = new MutationObserver(() => {
              if(hasThirdDom()) {
                const itanalyticsBtnDom = document.getElementsByClassName('fitanalytics__button') ?? []
                itanalyticsBtnDom?.length && (itanalyticsBtnDom[0].style.display = 'none')
              }
            })
            const targetElement = sizeRecommendRef.value
            targetElement?.nodeType && domObserver.value?.observe?.(targetElement, config)
            loadPdpError.value = true
          }
        }, 3000)
      }else {
        loadPdpError.value = true
      }
    }catch(e) {
      loadPdpError.value = true
    }
  }
}, { immediate: true })

onMounted(() => {
  traceCheckSize()
})
</script>

<style lang="less">
.recommendEntrance-size{
  display: flex;
  flex:1;
  justify-content: flex-end;
  overflow: hidden;
}
/* stylelint-disable selector-class-pattern, selector-max-specificity, selector-max-type  */
.link-check-my-size {
  display: inline-flex;
  align-items: center;
  cursor: auto;
  width: auto;
  margin-left: 1.3vw;
  // TODO无效css吧
  // margin-bottom: 0.1.7rem;
  text-decoration-line: none;
  line-height: normal;
  overflow: hidden;
  p {
    max-width: 32.2667vw;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 12px;
    color: #000!important;
    .sui_icon_more_right_12px_2{
      color: #000;
    }
  }
}
.link-check-mysize__img {
  width: 4.54vw;
  height: 4.54vw;
  margin-right: 0.4vw;
  vertical-align: bottom;
  cursor: pointer;
  display: inline-block;
  background-repeat: no-repeat;
  background-size: contain;
}

/* 修复 ios 第三方尺码层级不生效 */
html.uclw_widget_open {
    #uclw_wrapper {
        transform: translate3d(0, 0, 999px);
    }
}
.has-left-alone{
  justify-content: flex-start !important;
  align-items: center;
  height: 3.7333vw;
  margin-bottom: 2.1vw;
  .link-check-my-size{
    margin-left: 0;
  }
  .check-my-size-text{
    margin: 0 0.5vw;
  }
  .fitanalytics__button{
    margin-left: 0!important;
  }
}
</style>
