<template>
  <div class="tenBillionSubsidy">
    <!-- 背景图 -->
    <img
      class="tenBillionSubsidy__bg-img"
      src="//img.ltwebstatic.com/images3_ccc/2025/02/10/a9/173917811304c504a3c651347aa917f775a6eac032.png"

    />
    <!-- 标题 -->
    <div class="tenBillionSubsidy__header">
      <img
        :src="flashBeltWraperInfo.brandDealsInfo.priceAreaIcon.imgUrl"
      />
    </div>

    <!-- 价格 -->
    <slot></slot>
  </div>
</template>
<script setup name="BrandDeals">

defineProps({
  flashBeltWraperInfo: {
    type: Object,
    default: () => ({}),
  },
})

</script>

<style lang="less" scoped>
.tenBillionSubsidy {
  width: 100%;
  padding: .2667rem /* 10/37.5 */ .32rem /* 12/37.5 */;
  border-radius: .1067rem /* 4/37.5 */;
  background: linear-gradient(71deg, #FE4D00 41.53%, #FF7000 100%);

  &__header {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: .16rem /* 6/37.5 */;

    img {
      width: auto;
      height: .4267rem /* 16/37.5 */;
    }
  }

  &__bg-img {
    width: 2.4rem /* 90/37.5 */;
    max-height: 100%;
    position: absolute;
    top: 0;
    right: .2133rem /* 8/37.5 */;
  }
}
</style>
