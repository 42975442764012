<template>
  <div
    class="quickEstimatedTagNew"
    @click.stop="emits('clickEstimatedTag')"
  >
    <div
      v-if="!config.hideDiscountPercent"
      class="quickEstimatedTagNew__main"
      :class="{ 'quickEstimatedTagNew_percentTypeStyleWrap': isWrap === 1 }"
      :style="{
        background: strategyStyle.mainBackground,
        color: strategyStyle.mainColor
      }"
    >
      <div class="quickEstimatedTagNew__container">
        <div class="quickEstimatedTagNew__estimated">
          {{ estimatedInfo.text }}
          <div
            v-show="isWrap !== 1"
            class="quickEstimatedTagNew__left-arrow"
            :class="{ 'quickEstimatedTagNew__left-arrow_cssRight': cssRight }"
          >
            <svg
              width="5px"
              height="5px"
              viewBox="0 0 5 5"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink">
              <g
                id="页面-1"
                stroke="none"
                stroke-width="1"
                fill="none"
                fill-rule="evenodd">
                <path
                  id="形状结合"
                  d="M5,5 L0,5 L0.216889622,4.99538049 C2.87769329,4.88181811 5,2.6887547 5,-2.22044605e-16 L5,5 Z"
                  :fill="strategyStyle.mainBackground" />
              </g>
            </svg>
          </div>
          <!-- 改新角标样式 -->
          <svg
            v-show="isWrap === 1"
            class="quickEstimatedTagNew__top-arrow-new" xmlns="http://www.w3.org/2000/svg" width="5" height="5" viewBox="0 0 5 5" fill="none">
            <path d="M5 5H0V0C0 2.76142 2.23858 5 5 5Z" :fill="strategyStyle.mainBackground"/>
          </svg>
        </div>
        <div
          class="quickEstimatedTagNew__percent"
          :class="{ 'countdownNewStyle': countdownInfo.countdownNewStyle }"
          :style="{
            background: !countdownInfo.countdownNewStyle && strategyStyle.percentBackground,
            color: countdownInfo.countdownNewStyle ? strategyStyle.mainColor  : strategyStyle.percentColor
          }"
        >
          {{ discountPercent }}
        </div>
        <EstimatedNewCountdown
          v-if="countdownInfo.countDownEndTime && countdownInfo.nowTime && countdownInfo.countdownNewStyle"
          :end-time="countdownInfo.countDownEndTime"
          :isShowCountDownMillSec="countdownInfo.isShowCountDownMillSec"
          :nowTime="countdownInfo.nowTime"
          :countdown-new-style="countdownInfo.countdownNewStyle"
          :color-style="strategyStyle.mainColor"
        />
      </div>
    </div>
  </div>
</template>

<script setup name="EstimatedTagNew">
import { defineAsyncComponent, computed } from 'vue'
const EstimatedNewCountdown = defineAsyncComponent(() => import('../EstimatedNewCountdown.vue'))

const emits = defineEmits(['clickEstimatedTag'])

const props = defineProps({
  estimatedInfo: {
    type: Object,
    default: () => ({}),
  },
  isWrap: {
    type: Number,
    default: -1,
  },
  countdownInfo: {
    type: Object,
    default: () => ({}),
  },
  config: {
    type: Object,
    default: () => ({}),
  },
})

const strategyStyle = computed(() => {
  // 四种样式
  // 常规(同特殊闪购)、付费会员、腰带Old、腰带New、品牌闪购腰带
  const ESTIMATED_TAG_NEW_STYLE = {
    normal: {
      mainColor: '#FA6338',
      mainBackground: '#FFECE9',
      percentColor: '#FA6338',
      percentBackground: '#FFD9CE',
    },
    paidVip: {
      mainColor: '#873C00',
      mainBackground: '#FFECE9',
      percentColor: '#873C00',
      percentBackground: '#FFD9CE',
    },
    flash: {
      mainColor: '#FA6338',
      mainBackground: '#FFECE9',
      percentColor: '#FFF',
      percentBackground: '#FA6338',
    },
    brandFlash: {
      mainColor: '#FF3D00',
      mainBackground: '#FFE3D9',
      percentColor: '#FF3D00',
      percentBackground: '#FFF',
    },
    quickAddNoramaBrandlFlash: {
      mainColor: '#FF3D00',
      mainBackground: '#FFF',
      percentColor: '#FFF',
      percentBackground: '#F54811',
    },
    s3Brown: {
      mainColor: '#806208',
      mainBackground: '#F1E7D4',
      percentColor: '#806208',
      percentBackground: '#E9DABA',
    },
  }
  const { normal, paidVip, flash, brandFlash, quickAddNoramaBrandlFlash, s3Brown } = ESTIMATED_TAG_NEW_STYLE

  const {
    sheinClubPromotionInfo,
    isPaidUser,
    isQuickAdd,
    isFlash,
    isNormalFlash,
    isBrandFlash,
    exclusiveInfo,
  } = props.estimatedInfo.otherInfo
  if (exclusiveInfo) return s3Brown
  if (isFlash) {
    if (isQuickAdd) {
      if (isBrandFlash) return quickAddNoramaBrandlFlash
      if (isNormalFlash) return quickAddNoramaBrandlFlash
    }
    if (isBrandFlash) return brandFlash
    return flash
  }
  if (!!sheinClubPromotionInfo && isPaidUser) return paidVip
  return normal
})

const cssRight = computed(() => props.estimatedInfo.otherInfo.cssRight)

const discountPercent = computed(() => props.estimatedInfo.discountPercent)

</script>

<style lang="less">
.quickEstimatedTagNew {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;

  &__main {
    font-size: 10px;
    line-height: 1;
    height: .4267rem;
    border-radius: 2px 2px 2px 0;
  }

  &__retail {
    color: #666;
    font-size: 10px;
    text-decoration: line-through;
  }

  &_percentTypeStyleWrap {
    border-radius: 0 2px 2px 2px !important;
  }

  &__container {
    display: flex;
  }

  &__estimated {
    height: .4267rem;
    line-height: .32rem;
    padding: 0 .08rem;
    position: relative;
    display: flex;
    align-items: center;
    border-radius: 2px;
  }

  &__percent {
    color: #fff;
    padding: 1px 2px 1px;
    height: .4267rem;
    display: flex;
    align-items: center;
    border-radius: 0px 2px 2px 0;
    font-weight: 700;
    direction: ltr;
    &.countdownNewStyle{
      padding: 0 3px 0 0;
    }
  }

  &__left-arrow {
    position: absolute;
    left: -0.1333rem;
    bottom: 0;
    width: .1333rem;
    height: .1333rem;

    &>svg {
      display: block;
    }
  }

  &__left-arrow_cssRight {
    transform: scalex(-1);
  }
  // <!-- 改新角标样式 -->
  &__top-arrow-new {
    position: absolute;
    left: 0;
    top: -0.1333rem;
  }
}
</style>
