<template>
  <div
    class="quickDiscountInfo"
    :style="{
      ...assignStyle,
      alignItems: isCountDownStyle ? 'center' : 'flex-end',
    }">
    <div
      v-if="isCountDownStyle"
      class="countdown-newstyle-wrap"
      :class="{ 'is-percent-wrap': isWrap }"
      :style="{
        background: mainPriceColor.bg_color,
        color: mainPriceColor.font_color
      }"
    >
      <DiscountPercent
        v-if="discountInfo.discountPercent"
        :value="discountInfo.discountPercent"
        :style-map="{...discountPercentAssignStyle, 'color': mainPriceColor.font_color, 'background': mainPriceColor.bg_color}"
        :countdown-new-style="countdownInfo.countdownNewStyle"
      />
      <!-- 倒计时新样式, 背景色要适配价格主题色 -->
      <EstimatedNewCountdown
        v-if="countdownInfo.countDownEndTime && countdownInfo.nowTime"
        :end-time="countdownInfo.countDownEndTime"
        :is-show-count-down-mill-sec="countdownInfo.isShowCountDownMillSec"
        :nowTime="countdownInfo.nowTime"
        :countdown-new-style="countdownInfo.countdownNewStyle"
        :color-style="mainPriceColor.font_color"
      />
      <div
        v-show="isWrap !== 1"
        class="productEstimatedTagNew__left-arrow"
        :class="{ 'productEstimatedTagNew__left-arrow_cssRight': cssRight }"
      >
        <svg
          width="5px"
          height="5px"
          viewBox="0 0 5 5"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink">
          <g
            id="页面-1"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd">
            <path
              id="形状结合"
              d="M5,5 L0,5 L0.216889622,4.99538049 C2.87769329,4.88181811 5,2.6887547 5,-2.22044605e-16 L5,5 Z"
              :fill="mainPriceColor.bg_color" />
          </g>
        </svg>
      </div>
      <svg
        v-show="isWrap === 1"
        class="quickDiscountInfo__top-arrow"
        xmlns="http://www.w3.org/2000/svg"
        width="5"
        height="5"
        viewBox="0 0 5 5"
        fill="none">
        <path
          d="M5 5H0V0C0 2.76142 2.23858 5 5 5Z"
          :fill="mainPriceColor.bg_color" />
      </svg>
    </div>
    <p
      class="quickDiscountInfo__retail"
      :style="assignRetailStyle"
    >
      {{ retailPrice }}
    </p>
    <DiscountPercent
      v-if="discountInfo.discountPercent && !countdownInfo.countdownNewStyle"
      :value="discountInfo.discountPercent"
      :style-map="discountPercentAssignStyle"
    />
  </div>
</template>

<script setup name="DiscountInfo">
/**
 * @file 主价格 原价 折扣率
*/
import { defineAsyncComponent, computed, onMounted, nextTick, toRefs } from 'vue'
const EstimatedNewCountdown = defineAsyncComponent(() => import('../../components/EstimatedTag/EstimatedNewCountdown.vue'))
import DiscountPercent from './DiscountPercent.vue'

const emits = defineEmits(['loaded'])
const props = defineProps({
  assignStyle: {
    type: Object,
    default: () => ({}),
  },
  retailPrice: {
    type: String,
    default: '',
  },
  assignRetailStyle: {
    type: Object,
    default: () => ({}),
  },
  discountPercent: {
    type: String,
    default: '',
  },
  discountPercentAssignStyle: {
    type: Object,
    default: () => ({}),
  },
  countdownInfo: {
    type: Object,
    default: () => ({}),
  },
  mainPriceColor: {
    type: Object,
    default: () => ({}),
  },
  isWrap: {
    type: Number,
    default: -1,
  },
  discountAmountInfo: {
    type: Object,
    default: () => ({}),
  },
  config: {
    type: Object,
    default: () => ({}),
  }
})
const { discountAmountInfo, discountPercent, config } = toRefs(props)

const { GB_cssRight } = gbCommonInfo || {}
const cssRight = computed(() => GB_cssRight)
onMounted(() => {
  nextTick(() => {
    emits('loaded')
  })
})

/**
 * 原价 / 法国合规价自定义数据/样式
*/
const discountInfo = computed(() => {
  const { discountValue = null, showDiscountPercent = true } = discountAmountInfo.value || {}

  const { priceStyleType = {} } = config.value || {}
  const showValue = showDiscountPercent ? discountPercent.value : ''

  return {
    discountPercent: discountPercent.value || '',
    // 百补数据
    ...(priceStyleType?.showBrandDeals ? {
      discountPercent: discountValue?.type === 'retailPriceSale' ? showValue : '',
    } : {}),
  }
})

const isCountDownStyle = computed(() => {
  let { countdownInfo } = props
  let { countdownNewStyle, countDownEndTime, nowTime } = countdownInfo
  return countdownNewStyle && (discountInfo.value.discountPercent || (countDownEndTime && nowTime))
})

</script>

<style lang="less">
.quickDiscountInfo {
  display: flex;
  align-items: flex-end;
  max-width: 100%;
  .countdown-newstyle-wrap{
    margin-right: 4px;
    display: flex;
    align-items: center;
    height: 16px;
    border-radius: 2px 2px 2px 0;
    position: relative;
  }
  &__retail {
    font-size: 10px;
    color: #767676;
    line-height: 1;
    text-decoration: line-through;
    margin-right: 4px;
  }
  // <!-- 改新角标样式 -->
  &__top-arrow {
    position: absolute;
    left: 0;
    top: -5px;
  }

  .productEstimatedTagNew {
    &__left-arrow {
      position: absolute;
      left: -5px;
      bottom: 0;
      width: 5px;
      height: 5px;

      &>svg {
        display: block;
      }
    }

    &__left-arrow_cssRight {
      transform: scalex(-1);
    }
  }
}
</style>
