<script lang="jsx">
import { Icon } from '@shein-aidc/icon-vue3'
/**
 * @props
 *  @param { Number } label 当前 label
 *  @param { Number } index 当前 label 索引
 *  @param { Number } attrId 当前主属性 ID
 *  @param { String } attrName 当前主属性名称
 */
export default {
  name: 'SizeSelectLabel',
  functional: true,
  props: {
    hideDisableAttrId: {
      type: String,
      default: '',
    },
    isLast: {
      type: Boolean,
      default: false,
    },
    label: {
      type: Object,
      default: () => ({}),
    },
    attrId: {
      type: String,
      default: '',
    },
    attrName: {
      type: String,
      default: '',
    },
    index: {
      type: Number,
      default: 0,
    },
    sizeInfoIndex: {
      type: Object,
      default: () => ({}),
    },
    isOnSale: {
      type: Boolean,
      default: true,
    },
    config: {
      type: Object,
      default: () => ({}),
    },
    sizeConstant: {
      type: Object,
      default: () => ({}),
    },
    highSaleAttr: {
      type: Object,
      default: () => ({}),
    },
    handleCalcSize: {
      type: Function,
      default: () => {},
    },
    handleChooseCheck: {
      type: Function,
      default: () => {},
    },
    handleSizeClick: {
      type: Function,
      default: () => {},
    },
  },
  render(props) {
    const {
      hideDisableAttrId,
      isLast,
      label,
      // index,
      attrId,
      attrName,
      sizeInfoIndex,
      isOnSale,
      sizeConstant,
      highSaleAttr,
      config,
      handleSizeClick,
      handleCalcSize,
      handleChooseCheck,
    } = props || {}
    const { cssRight = false } = sizeConstant || {}
    const {
      attr_std_value,
      attr_value_name,
      attr_value_id,
    } = label || {}

    const chooseCheckStatus = handleChooseCheck({
      attr_id: attrId,
      attr_name: attrName,
      attr_value_id,
    })
    // 用户是否选中
    const isActiveOption = sizeInfoIndex[attrId]?.attr_value_id == attr_value_id
    // 是否售罄
    const isSoldOut = !isOnSale || chooseCheckStatus === 0
    // 是否展示有货选中状态
    const isActive = isActiveOption && isOnSale && chooseCheckStatus > 0

    // 是否展示售罄选中状态
    const isSizeSoldOut = isActiveOption && isSoldOut

    // 无数据,禁用
    const disable = chooseCheckStatus === undefined

    const { hotAttrValueList, salesPreAttrValueList } = highSaleAttr || {}
    // hot 标签
    const isHotTag = hotAttrValueList?.includes(attr_value_id) && config?.otherAttrHotOn
    const hotTagSrc = cssRight ? '//img.ltwebstatic.com/images3_ccc/2024/10/10/6d/1728547129831115ea4fad4b79f1caec6ca4617c24.png' :
      '//img.ltwebstatic.com/images3_ccc/2024/09/26/36/1727353722d5ac6151867d1504299e8bd37f299251.png'
    // 排序
    let order
    if (salesPreAttrValueList && config?.otherAttrSortOn) {
      const sortIndex = salesPreAttrValueList.findIndex(item => item === attr_value_id)
      order = salesPreAttrValueList.includes(attr_value_id) ? sortIndex - 10 : undefined
    }

    const ariaItem = attr_std_value
      ? cssRight
        ? attr_value_name + ' / ' + attr_std_value
        : attr_std_value
      : attr_value_name

    const { lowStockTip, mainSize, subSize, isWeakSubDefaultSize } = handleCalcSize(label, {
      isCalcLowTip: !isSoldOut && isLast,
      attrParentInfo: {
        attrId,
        attrName,
      },
    }) || {}

    const renderSubSize = () => {
      if (!subSize || !mainSize) return null
      return isWeakSubDefaultSize ? <span style="color: #959595; font-size: 12px;">{` ${subSize}`}</span> : ` ${subSize}`
    }

    const renderLowTip = () => {
      if (!lowStockTip) return null
      return <div class="low-stock-tips">
        { lowStockTip === 'icon' ?
          <Icon
            name="sui_icon_hourglass_12px_2"
            size="12px"
            class="low-stock-tips-hourglass"
            color="#C44A01"
          /> :
          lowStockTip
        }
      </div>
    }

    return (
      <li
        v-show={!(hideDisableAttrId === attrId && disable)}
        class={[
          'goods-size__sizes-item',
          { 'size-active': isActive },
          { 'size-sold-out': isSizeSoldOut },
          { 'sold-out': isSoldOut },
          { 'size-disabled': disable },
          { 'size-relative-item': isHotTag},
          'twoline-text-box',
        ]}
        data-attr_value={attr_value_name}
        data-attr_value_id={attr_value_id}
        type="text"
        aria-item={ariaItem}
        onClick={() => {
          handleSizeClick?.({ attr_id: attrId, attr_name: attrName }, label, { highSaleAttr })
        }}
        style={{order}}
      >
        <p class={[
          'goods-size__sizes-item-text',
          'goods-size__sizes-item-text--two',
        ]}
        >
          { mainSize }
          { renderSubSize() }
          { renderLowTip() }
        </p>

        {isHotTag && (<div class="goods-size__sizes-tag-hot">
          <img src={hotTagSrc} />
        </div>)}
      </li>
    )
  },
}
</script>

<style lang="less" scoped>
.size-relative-item {
  position: relative;
}
.goods-size__sizes {
  &-tag-hot {
    width: .6667rem;
    height: .2667rem;
    top: -6px;
    right: -2px;
    position: absolute;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
