<template>
  <div class="size-feedback_title">
    <div @click="onSizeFeedbackBtnClick">
      {{ language.SHEIN_KEY_PWA_21097 }} {{ language.SHEIN_KEY_PWA_21088 }}
      <Icon
        name="sui_icon_more_right_12px_2"
        size="12px" />
    </div>
    <SizeFeedBack
      v-if="loadDrawer"
      ref="sizeFeedBackDrawer"
      :asyncContentReady="true"
      :notShowDefaultSizeText="notShowDefaultSizeText"
      :countrySizeDataList="countrySizeDataList"
      :language="language"
      :goodsId="goodsId"
      :sizeUnit="sizeUnit"
      :localClick="handleClickLocalTextSelect"
      :handleOpenFindSimilar="handleOpenFindSimilar"
    />
  </div>
</template>

<script setup>
import { defineProps, ref, defineAsyncComponent } from 'vue'
import { getSource } from '../../../common/dataSource'
import { useAppConfigs } from '@shein-aidc/bs-sdk-libs-manager'
import analysisInstance from '../utils/useAnalysisSdk.js'
const { triggerNotice } = analysisInstance

import { Icon } from '@shein-aidc/icon-vue3'
const SizeFeedBack = defineAsyncComponent(() => import('../drawer/SizeFeedBack/index.vue'))

const appConfigs = useAppConfigs()
const apis = getSource({ appConfigs })

const props = defineProps({
  language: {
    type: Object,
    default: () => ({}),
  },
  goodsId: {
    type: String,
    default: '',
  },
  mallCode: {
    type: String,
    default: '',
  },
  country: {
    type: String,
    default: '',
  },
  sizeUnit: {
    type: String,
    default: '',
  },
  notShowDefaultSizeText: {
    type: Boolean,
    default: false,
  },
  handleClickLocalTextSelect: {
    type: Function,
    default: () => {},
  },
  handleOpenFindSimilar: {
    type: Function,
    default: () => {},
  },
})
const loadDrawer = ref(false)
const countrySizeDataList = ref([])
const sizeFeedBackDrawer = ref(null)

const updateList = async ({ userSwitchLocalCountry = '' }) => {
  const data = await apis.getSizeFeedbackCountry({
    goods_id: props.goodsId || '',
    mall_code: props.mallCode || '',
    userSwitchLocalCountry,
  })
  countrySizeDataList.value = data?.info?.countrySizeDataList || []
}

const onSizeFeedbackBtnClick = async () => {
  loadDrawer.value = true
  triggerNotice({
    id: 'click_goods_detail_feedback.comp_sub-sales-attr',
    extraData: {
      goods_id: props.goodsId,
    },
  })
  await updateList({ userSwitchLocalCountry: props.country || '' })
  if (sizeFeedBackDrawer.value) {
    sizeFeedBackDrawer.value.onSizeFeedbackBtnClick?.()
  }
}
</script>

<style lang="less">
.size-feedback {
  &_title {
    color: #666;
    line-height: 1;
    margin-bottom: 1.6vw;
    display: flex;
    .more-icon {
      position: relative;
      top: 1px;
    }
  }
}
</style>
