<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="quick-discount-amount">
    <span v-html="discountAmountInfo.discountValue?.value"></span>
    <img
      src="//img.ltwebstatic.com/images3_ccc/2025/02/11/6d/17392419748a5de16265294fc2117159faa24b9861.png"
      :class="{'quick-discount-amount__img-scale': discountAmountInfo.GB_cssRight || false}"
    />
  </div>
</template>
<script setup name="DiscountAmount">

defineProps({
  discountAmountInfo: {
    type: Object,
    default: () => ({}),
  },
})

</script>

<style lang="less" scoped>
.quick-discount-amount {
	position: relative;
  display: flex;
  align-items: center;
  align-self: flex-end;
	height: .4267rem;
  line-height: 1;
	padding: .0533rem /* 2/37.5 */ .08rem /* 3/37.5 */;
  border-radius: 2px 2px 2px 0px;
	background: #fbded4;
	font-size: 10px;
	color: #FE4D00;
  transform: translateY(-0.04rem);

	img {
		width: 5px /* 5/37.5 */;
		height: 5px /* 5/37.5 */;
		position: absolute;
		left: -5px /* 5/37.5 */;
		bottom: 0;
	}

  &__img-scale {
		transform: scalex(-1);
	}
}
</style>
