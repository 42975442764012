// https://wiki.dotfashion.cn/pages/viewpage.action?pageId=1593774903 具体利益点的含义

export const BENEFIT_INFO_MAP = {
  // 商品保障类
  1: {
    color: '#198055',
    bgColor: '#F3FAF6',
    icon: 'https://shein.ltwebstatic.com/svgicons/2024/11/26/17326039141569076942.svg',
  },
  2: {
    color: '#198055',
    bgColor: '#F3FAF6',
    icon: 'https://shein.ltwebstatic.com/svgicons/2024/11/26/17326039404162677886.svg',
  },
  3: {
    color: '#198055',
    bgColor: '#F3FAF6',
    icon: 'https://shein.ltwebstatic.com/svgicons/2024/11/26/17326042503948681550.svg',
  },




  // 商品评价类
  4: {
    color: '#A86104',
    bgColor: '#FFF8EB',
    icon: 'https://shein.ltwebstatic.com/svgicons/2024/11/26/17326025651853624134.svg',
  },
  5: {
    color: '#A86104',
    bgColor: '#FFF8EB',
    icon: 'https://shein.ltwebstatic.com/svgicons/2024/11/26/17326025651853624134.svg',
  },
  6: {
    color: '#A86104',
    bgColor: '#FFF8EB',
    icon: 'https://shein.ltwebstatic.com/svgicons/2024/11/26/17326025651853624134.svg',
  },
  7: {
    color: '#A86104',
    bgColor: '#FFF8EB',
    icon: 'https://shein.ltwebstatic.com/svgicons/2024/11/26/17326025651853624134.svg',
  },



  // 商品价格类
  8: {
    color: '#FA6338',
    bgColor: '#FFF6F3',
    icon: 'https://shein.ltwebstatic.com/svgicons/2024/11/26/17326038572654332422.svg',
  },
  9: {
    color: '#FA6338',
    bgColor: '#FFF6F3',
    icon: 'https://shein.ltwebstatic.com/svgicons/2024/11/26/17326038572654332422.svg',
  },
  10: {
    color: '#FA6338',
    bgColor: '#FFF6F3',
    icon: 'https://shein.ltwebstatic.com/svgicons/2024/11/26/17326038572654332422.svg',
  },



  // 商品库存类
  11: {
    color: '#FA6338',
    bgColor: '#FFF6F3',
    icon: 'https://shein.ltwebstatic.com/svgicons/icons/sh_m_sui_icon_hourglass_12px.svg',
  },
  12: {
    color: '#FA6338',
    bgColor: '#FFF6F3',
    icon: 'https://shein.ltwebstatic.com/svgicons/icons/sh_m_sui_icon_hourglass_12px.svg',
  },



  // 用户行为类
  13: {
    color: '#FA6338',
    bgColor: '#FFF6F3',
    icon: 'https://shein.ltwebstatic.com/svgicons/2024/11/26/1732604309995172334.svg',
  },
  14: {
    color: '#198055',
    bgColor: '#F3FAF6',
    icon: 'https://shein.ltwebstatic.com/svgicons/2024/11/26/17326042503948681550.svg',
  },
  15: {
    color: '#FA6338',
    bgColor: '#FFF6F3',
    icon: 'https://shein.ltwebstatic.com/svgicons/2024/11/26/1732604309995172334.svg',
  },
  16: {
    color: '#FA6338',
    bgColor: '#FFF6F3',
    icon: 'https://shein.ltwebstatic.com/svgicons/2024/11/26/1732604309995172334.svg',
  },
  17: {
    color: '#FA6338',
    bgColor: '#FFF6F3',
    icon: 'https://shein.ltwebstatic.com/svgicons/2024/11/26/1732604309995172334.svg',
  },
  18: {
    color: '#FA6338',
    bgColor: '#FFF6F3',
    icon: 'https://shein.ltwebstatic.com/svgicons/2024/11/26/1732604309995172334.svg',
  },



  // 榜单类
  19: {
    color: '#D58700',
    bgColor: '#FFF7E2',
    icon: 'https://shein.ltwebstatic.com/svgicons/2025/02/07/17389192204114922969.svg',
  },

  20: {
    color: '#D58700',
    bgColor: '#FFF7E2',
    icon: 'https://shein.ltwebstatic.com/svgicons/2025/02/07/17389192204114922969.svg',
  },
  21: {
    color: '#D58700',
    bgColor: '#FFF7E2',
    icon: 'https://shein.ltwebstatic.com/svgicons/2025/02/07/17389192204114922969.svg',
  },
}
