<script lang="jsx">
import MallSelectContent from './MallSelectContent'
import analysisInstance from '../utils/useAnalysisSdk.js'
const { vTap, vExpose, triggerNotice } = analysisInstance
export default {
  name: 'MallEntry',
  directives: {
    tap: vTap,
    expose: vExpose,
  },
  props: {
    skuInfo: {
      type: Object,
      default: () => ({}),
    },
    mallList: {
      type: Array,
      default: () => [],
    },
    language: {
      type: Object,
      default: () => ({}),
    },
    mallCode: {
      type: String,
      default: '',
    },
    customerStyle: {
      type: Object,
      default: () => {},
    },
    isOnSale: {
      type: Boolean,
      default: false,
    },
    handleChooseCheck: {
      type: Function,
      default: () => new Function(),
    },
    from: {
      type: String,
      default: 'popup',
    },
  },
  emits: ['mallClick'],
  computed: {
    location() {
      return this.from == 'detailPage' ? 'detail' : 'popup'
    },
    exposeStr() {
      return this.mallList.map((_) => _.mall_code).join('`')
    },
    mallInfo() {
      return this.mallList?.find((_) => _.mall_code == this.mallCode) || {}
    },
    mallStocks() {
      return this.skuInfo?.mall_stock || []
    },
    mallStock() {
      return this.mallStocks.find((_) => _.mall_code == this.mallCode) || {}
    },
    currentSkuSoldOut() {
      if (!this.mallStocks?.length) return false
      return !!this.mallStocks.every(item => !item.stock)
    },
    mallTips() {
      const { mallDescription } = this.mallInfo || {}
      return mallDescription?.reduce?.((allStr, str, index) => {
        if (typeof str !== 'string' || !str) return allStr
        if (index % 2 === 0) {
          return allStr += `${str}`
        }
        return allStr += `<b>${str}</b>`
      }) || ''
    },
  },
  methods: {
    mallClick(mallCode) {
      const mallStock = this.handleChooseCheck(-1, '', '', mallCode)
      this.$emit('mallClick', {
        mallCode,
        mallStock,
      })
      triggerNotice({
        daId: 'click_switch_mall.comp_sub-sales-attr',
        extraData: {
          mall_code: mallCode,
          location: this.location,
        },
      })
    },
  },
  render() {
    const {
      customerStyle,
      exposeStr,
      location,
      language,
      mallList,
      mallCode,
      isOnSale,
      mallTips,
      currentSkuSoldOut,
    } = this
    const { bold = false } = customerStyle || {}
    const { handleChooseCheck, mallClick } = this

    const wrapExposeData = {
      mall_code: exposeStr,
      location: location,
    }

    return (
      <MallSelectContent
        bold={bold}
        mall-list={mallList}
        mall-tips={mallTips}
        mall-code={mallCode}
        is-on-sale={isOnSale}
        current-disable={currentSkuSoldOut}
        handle-choose-check={handleChooseCheck}
        wrap-expose-data={wrapExposeData}
        title={language.SHEIN_KEY_PWA_20779}
        onMallClick={mallClick}
      />
    )
  },
}
</script>

<style lang="less">
.goods-size {
  &__mall {
    margin: 3.733vw 0 3.2vw;
  }
  &__sizes-malltip {
    display: -webkit-box;
    display: -moz-box;
    display: flex;
    display: -ms-flexbox;
    -webkit-align-items: center;
    -webkit-box-align: center;
       -moz-box-align: center;
       -ms-flex-align: center;
          align-items: center;
    justify-content: space-between;
    padding: 2.13333vw 3.2vw;
    background: #f6f6f6;
    color: #666;
    font-size: 12px;
    line-height: 1.2;
    .icon-light {
      margin-right: 3.2vw;
    }
  }
}
</style>
