<script lang="jsx">
import SizeSelectItem from './SizeSelectItem'
import SizeAttrPicInfo from './SizeAttrPicInfo'
import SizeSlideTips from './SizeSlideTips'
import { ClientOnly } from '@sheinfe/vue-client-only'
import analysisInstance from '../utils/useAnalysisSdk.js'
const { vExpose, vTap } = analysisInstance || {}
export default {
  name: 'SizeSelectList',
  // functional: true,
  directives: {
    expose: vExpose,
    tap: vTap,
  },
  props: {
    hideDisableAttrId: {
      type: String,
      default: '',
    },
    skcSaleAttr: {
      type: Array,
      default: () => [],
    },
    sizeInfoIndex: {
      type: Object,
      default: () => ({}),
    },
    showLocalTextType: {
      type: Number,
      default: 0,
    },
    country: {
      type: String,
      default: '',
    },
    sizeUnit: {
      type: String,
      default: '',
    },
    goodsId: {
      type: String,
      default: '',
    },
    isBaseSize: {
      type: Boolean,
      default: false,
    },
    loadingAsyncComp: {
      type: Boolean,
      default: false,
    },
    hasBubblePicMap: {
      type: Object,
      default: () => ({}),
    },
    hasBubbleTextMap: {
      type: Object,
      default: () => ({}),
    },
    lowestStockTipInfo: {
      type: Object,
      default: () => ({}),
    },
    isOnSale: {
      type: Boolean,
      default: false,
    },
    hasOneSize: {
      type: Boolean,
      default: false,
    },
    sizeGroupPosition: {
      type: String,
      default: '',
    },
    defaultCountryCode: {
      type: String,
      default: '',
    },
    saleAttrGroups: {
      type: Object,
      default: () => ({}),
    },
    sizeRecommendInfo: {
      type: Object,
      default: () => ({}),
    },
    sizeAttrInfo: {
      type: Object,
      default: () => ({}),
    },
    customerStyle: {
      type: Object,
      default: () => ({}),
    },
    language: {
      type: Object,
      default: () => ({}),
    },
    config: {
      type: Object,
      default: () => ({}),
    },
    sizeConstant: {
      type: Object,
      default: () => ({}),
    },
    highSaleAttr: {
      type: Object,
      default: () => ({}),
    },
    handleClickLocalTextSelect: {
      type: Function,
      default: () => {},
    },
    handleSizeClick: {
      type: Function,
      default: () => {},
    },
    handleCalcSize: {
      type: Function,
      default: () => {},
    },
    oneLocalSizeDesc: {
      type: Function,
      default: () => {},
    },
    handleOpenSizeGuideFn: {
      type: Function,
      default: () => {},
    },
    handleSetSizeRefs: {
      type: Function,
      default: () => {},
    },
    handleChooseCheck: {
      type: Function,
      default: () => {},
    },
    handleSizeRecommend: {
      type: Function,
      default: () => {},
    },
    handlerOpenAdd: {
      type: Function,
      default: () => {},
    },
  },
  render() {
    const {
      hideDisableAttrId = '',
      skcSaleAttr = [],
      country = '',
      goodsId = '',
      sizeUnit = '',
      sizeGroupPosition = '',
      showLocalTextType = 0,
      isBaseSize = false,
      hasOneSize = false,
      loadingAsyncComp = false,
      defaultCountryCode = '',
      sizeInfoIndex,
      hasBubblePicMap = {},
      hasBubbleTextMap = {},
      lowestStockTipInfo = {},
      saleAttrGroups = {},
      sizeRecommendInfo = {},
      sizeAttrInfo = {},
      customerStyle = {},
      config = {},
      language = {},
      sizeConstant = {},
      isOnSale,
      oneLocalSizeDesc,
      highSaleAttr,
      handleClickLocalTextSelect,
      handleSizeClick,
      handleCalcSize,
      handleOpenSizeGuideFn,
      handleSetSizeRefs,
      handleChooseCheck,
      handleSizeRecommend,
      handlerOpenAdd,
    } = this || {}
    const count = skcSaleAttr.length

    const renderBubbleInfo = (item, index) => {
      const { isShowAttrPicInfo, showSizeAttributeEntrance } = config || {}
      const { attr_id } = item || {}
      const targetInfo = sizeInfoIndex?.[attr_id]
      const { attr_value_id, attr_value_name, attrImg, attrDescPopUp } = targetInfo || {}
      const attrValueImage = attrImg?.attr_image
      const { logInfo, location, tip } = lowestStockTipInfo || {}
      const soldOutTips = location === 'bubble' ? tip : ''
      if (hasBubblePicMap?.[attr_id] && isShowAttrPicInfo) {
        const analysisData = {
          location: config?.from === 'detailPage' ? 'page' : 'popup',
          goods_id: goodsId,
          main_attr: index + 2,
        }
        const showSlide = !!attr_value_id && attrImg
        return <SizeAttrPicInfo
          show={!!showSlide}
          title={`${language?.SHEIN_KEY_PWA_24732}: `}
          attrValueName={attr_value_name}
          attrValueImage={attrValueImage}
          soldOutTips={soldOutTips}
          analysisData={analysisData}
          style={ showSlide ? 'margin-bottom:12px;' : undefined }
        />
      }
      if (hasBubbleTextMap?.[attr_id]) {
        const analysisData = {
          stockLogInfo: logInfo,
        }
        const showDesc = !!attr_value_id && attrDescPopUp
        const localSizeDescText = oneLocalSizeDesc?.(attr_value_id, attr_value_name) || ''
        return <SizeSlideTips
          sizeConstant={sizeConstant}
          handleSetSizeRefs={handleSetSizeRefs}
          showDesc={!!showDesc}
          soldOutTips={soldOutTips}
          localSizeDescText={localSizeDescText}
          sizeInfoTitle={!isBaseSize ? language.SHEIN_KEY_PWA_18700 : language.SHEIN_KEY_PWA_18636}
          showSizeAttributeEntrance={showSizeAttributeEntrance}
          attrDescPopUp={attrDescPopUp || []}
          sizeUnit={sizeUnit}
          isFromPopup={config?.from === 'popup'}
          showNewBubbleStyle={config?.showNewBubbleStyle}
          analysisData={analysisData}
          onHandleOpenSizeGuideFn={handleOpenSizeGuideFn}
        />
      }
      return null
    }

    const itemContentEl = () =>
      skcSaleAttr.map((item, index) => {
        return (<div>
          <SizeSelectItem
            hideDisableAttrId={hideDisableAttrId}
            isLast={index === count - 1}
            loadingAsyncComp={loadingAsyncComp}
            defaultCountryCode={defaultCountryCode}
            hasOneSize={hasOneSize}
            sizeAttrInfo={sizeAttrInfo}
            sizeRecommendInfo={sizeRecommendInfo}
            item={item}
            sizeInfoIndex={sizeInfoIndex}
            country={country}
            showLocalTextType={showLocalTextType}
            isOnSale={isOnSale}
            goodsId={goodsId}
            sizeGroupPosition={sizeGroupPosition}
            saleAttrGroups={saleAttrGroups}
            customerStyle={customerStyle}
            config={config}
            language={language}
            sizeConstant={sizeConstant}
            highSaleAttr={highSaleAttr}
            handleClickLocalTextSelect={handleClickLocalTextSelect}
            handleSizeClick={handleSizeClick}
            handleCalcSize={handleCalcSize}
            handleChooseCheck={handleChooseCheck}
            handleOpenSizeGuideFn={handleOpenSizeGuideFn}
            handleSizeRecommend={handleSizeRecommend}
            handlerOpenAdd={handlerOpenAdd}
            key={`SizeSelectItem_${index}`}
          />
          <ClientOnly>
            {renderBubbleInfo(item, index)}
          </ClientOnly>
        </div>)
      })
    return (
      <div
        class="goods-size__wrapper"
        data-count={count}
      >
        {itemContentEl()}
      </div>
    )
  },
}
</script>
