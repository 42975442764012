<template>
  <div
    class="quickRecommendRetailPrice"
    :style="{
      'color': color,
      'width': width
    }"
  >
    <p class="RecommendRetailPrice__value">
      {{ recommendRetailPriceInfo.value }}
    </p>
    <div
      v-if="recommendRetailPriceInfo.discountPercent"
      class="RecommendRetailPrice__discountPercent"
      :style="{'border-color': discountPercentBorderColor}"
    >
      {{ recommendRetailPriceInfo.discountPercent }}
    </div>
  </div>
</template>

<script setup name="RecommendRetailPrice">
import { computed, watch, inject, toRefs } from 'vue'
import { template } from '@shein/common-function'

const { triggerNotice } = inject('analysisInstance')

const props = defineProps({
  recommendRetailPrice: {
    type: Object,
    default: () => ({}),
  },
  isFloorType: {
    type: Boolean,
    default: false,
  },
  isQuickAdd: {
    type: Boolean,
    default: false,
  },
  discountAmountInfo: {
    type: Object,
    default: () => ({}),
  },
  config: {
    type: Object,
    default: () => ({}),
  },
})

const { discountAmountInfo, recommendRetailPrice, config } = toRefs(props)

watch(() => props.recommendRetailPrice.skuCode, (val) => {
  if (!val) return
  if (props.isFloorType) return
  triggerNotice({
    id: 'expose_sku_recommend_retail_price.comp_quick-add-cart-standard',
    extraData: {
      recommend_retail_price: props.recommendRetailPrice.usdAmountWithSymbol,
      location: 'popup',
    },
  })
}, {
  immediate: true,
})

const color = computed(() => recommendRetailPriceInfo.value?.style?.color || '')
const width = computed(() => recommendRetailPriceInfo.value?.style?.width || '')
const discountPercentBorderColor = computed(() => recommendRetailPriceInfo.value.style?.discountPercentBorderColor || '')

/**
 * 波兰 RRP 数据/样式自定义
 */
 const recommendRetailPriceInfo = computed(() => {
  return {
    ...recommendRetailPrice.value,
    ...getRrpData(discountAmountInfo.value, recommendRetailPrice.value, config.value),
  }
})

/**
 * 波兰 RRP 真的折扣金额自定义数据/样式
 */
const getRrpData = (discountAmountInfo, recommendRetailPriceInfo, config) =>{
  const { discountValue = null, showDiscountPercent = true, saveLanguageKey } = discountAmountInfo || {}
  const { discountPercent, discountAmount } = recommendRetailPriceInfo || {}
  const { priceStyleType = {} } = config || {}

  const _discountAmount = discountAmount ? template(discountAmount, saveLanguageKey || 'Save {0}') : ''
  const showValue = showDiscountPercent ? discountPercent : _discountAmount

  return {
    ...(priceStyleType?.showBrandDeals ? {
      // 百补数据处理
      discountPercent: discountValue?.type === 'rrpRetailPrice' ? '' : showValue,
    } : {}),
  }
}

</script>

<style lang="less">
.quickRecommendRetailPrice {

  display: flex;
  align-items: center;
  transform: translateY(-0.0267rem);

  height: .48rem;

  white-space: nowrap;
  font-size: 10px;
  font-weight: 400;
  line-height: 11.93px;
  color: #959595;
  text-align: left;

  &__discountPercent {
    display: flex;
    align-items: center;
    justify-content: center;

    padding: 0 .0533rem;
    height: .3733rem;
    border: .0134rem solid #959595;
    margin-left: .0533rem;
    border-radius: .0533rem;
  }
}
</style>
