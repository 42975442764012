<template>
  <Drawer
    v-model:visible="state.showLocalSize"
    class="c-drawer-local-size-select"
    direction="btt"
    :append-to-body="appendToBody"
    @open="drawerChange(true)"
    @close="drawerChange(false)"
  >
    <div class="goodsd__local-title">
      {{ state?.language?.SHEIN_KEY_PWA_16223 }}
    </div>
    <div class="goodsd__local-sizeWrap">
      <div
        v-if="isNewSizeLocal"
        class="goodsd__local-size"
        :class="{ 'goodsd__local-select': !state.selected }"
        @click="clickHandle('')"
      >
        <div style="display: flex;gap: 2px">
          <span>{{ state?.language?.SHEIN_KEY_PWA_23345 }}</span>
          <span class="goodsd__local-sub">({{ state?.language?.SHEIN_KEY_PWA_23343 }})</span>
        </div>
        <Icon
          v-if="!state.selected"
          name="sui_icon_selected_12px"
          size="16px"
        />
      </div>
      <div
        v-for="item in localSizeSelect"
        :key="item"
        class="goodsd__local-size"
        :class="{ 'goodsd__local-select': state.selected === item }"
        :data-country="item"
        :da-event-click="reportSelect ? '1-6-1-23' : null"
        @click="clickHandle(item)"
      >
        {{ item }}
        <Icon
          v-if="state.selected === item"
          name="sui_icon_selected_12px"
          size="16px"
        />
      </div>
    </div>
    <div class="goodsd__local-margin"></div>
    <div
      class="goodsd__local-cancel"
      @click="state.showLocalSize = false">
      {{ state?.language?.SHEIN_KEY_PWA_15254 }}
    </div>
  </Drawer>
</template>
<script name="BSelectLocalSize" setup lang="ts">
import { SDrawer as Drawer } from '@shein-aidc/sui-drawer/mobile'
import { Icon } from '@shein-aidc/icon-vue3'
import { getSource } from '../../../../common/dataSource'
import { useAppConfigs } from '@shein-aidc/bs-sdk-libs-manager'
import { reactive, nextTick } from 'vue'
import { computed } from 'vue'

/**
 * @param { Object } callbacks 回调
 *   @param { Function } change 尺码选择更改
 *   @param { Function } opened 打开回调
 *   @param { Function } closed 关闭回调
 */

const state = reactive({
  id: '',
  config: {} as any,
  language: {} as Record<string, string | undefined>,
  list: [],
  selected: '',
  showLocalSize: false,
  callbacks: {} as any,
  localSize: [] as any,
})

const localSizeSelect = computed(() => {
  return state.localSize?.map?.(item => item?.country_code) || []
})
const storeSelected = computed(() => {
  return !!state.config.storeSelected || true
})
const reportSelect = computed(() => {
  return !!state.config.reportSelect
})
const appendToBody = computed(() => {
  return !!state.config.appendToBody
})
const isNewSizeLocal = computed(() => {
  return !!state.config.isNewSizeLocal
})

async function open({ id = '', localSize = [], value = '', options }) {
  if (!id) {
    console.warn('id is not empty!')
    return
  }
  if (!localSize?.length) {
    console.warn('localSize is not empty!')
    return
  }
  setPopBaseData(localSize, options)
  state.id = id
  state.selected = value
  await nextTick()
  state.showLocalSize = true
}

function close() {
  state.showLocalSize = false
}

function setPopBaseData(localSize, options = {}) {
  const { config = {}, callbacks = {}, language = {} } = options as any
  state.localSize = localSize
  state.config = config
  state.language = language
  state.callbacks = callbacks
}
function clickHandle(index) {
  console.log(index)
  state.selected = index
  state.callbacks?.change?.(index)
  if (storeSelected.value) {
    window.localStorage.setItem('last_select_country', index || 'default')
  }
  state.showLocalSize = false
  // TODO: 不知道后面中间层融合要不要迁移 + 下沉
  const appConfigs = useAppConfigs()
  const apis = getSource({ appConfigs })
  apis.updateBusinessCache({
    cacheData: index || 'default',
    scanType: 'detail_local_country',
  })
}
function drawerChange(val) {
  if (val) return state.callbacks?.opened?.()
  return closeHandle()
}
function closeHandle() {
  state.callbacks?.closed?.()
  unbind()
}
function unbind() {
  state.callbacks = {}
}

defineExpose({
  open,
  close,
})
</script>
<style lang="less">
.goodsd {
  &__local-sizeWrap {
    max-height: 3.5rem;
    overflow-y: auto;
  }

  &__local-size {
    font-size: 14px;
    padding: 0.32rem;
    position: relative;
    display: flex;
    justify-content: space-between;
  }

  &__local-sub {
    color: #959595;
    font-weight: 400;
  }

  &__local-margin {
    background: #f6f6f6;
    height: 1.52rem;
  }

  &__local-select {
    font-weight: 700;
  }

  &__local-cancel {
    padding: 0.413rem 0;
    text-align: center;
    position: absolute;
    bottom: 0;
    background: #fff;
    width: 100%;
    font-size: 14px;
  }

  &__local-title {
    color: #999;
    font-size: 14px;
    text-align: center;
    padding: 0.306rem 0;
    position: relative;
    border-bottom: 1px solid rgba(229, 229, 229, 0.83);

    .icon-close {
      position: absolute;
      top: 0.12rem;
      font-size: 12px;
      color: #999;
      right: 0.18rem;
    }
  }
}
</style>
