<template>
  <div class="app-discount">
    <div>
      <Icon
        name="sui_icon_phone_16px"
        size="17px"
        color="#FA6338"
      />
      <span class="app-discount_desc">{{ extraOrOriginDiscount }}</span>
    </div>
    <button v-call-app="{ data: { url_from: JOURNEY_NAME } }">
      {{ language?.SHEIN_KEY_PWA_18056 }}>
    </button>
  </div>
</template>

<script>
import { template } from '@shein/common-function'
import { Icon } from '@shein-aidc/icon-vue3'

const JOURNEY_NAME = 'app-discount-20210113'

export default {
  components: {
    Icon,
  },
  props: {
    appExclusiveDiscount: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      JOURNEY_NAME,
    }
  },
  computed: {
    data() {
      return this.appExclusiveDiscount.data
    },
    language() {
      return this.appExclusiveDiscount.language
    },
    discount() {
      return ((1 - this.data.discountValue) * 100).toFixed(0)
    },
    // 原价折
    originDiscount() {
      return this.data.ruleType == 'discount'
    },
    // 折上折
    extraDiscount() {
      return this.data.ruleType == 'sale_discount'
    },
    extraOrOriginDiscount() {
      return this.originDiscount
        ? template(this.discount, this.language?.SHEIN_KEY_PWA_18054 || '{0}% Discount For App Purchase')
        : this.extraDiscount
          ? template(this.discount, this.language?.SHEIN_KEY_PWA_18055 || 'Extra {0}% Discount For App Purchase')
          : ''
    },
  },
}
</script>

<style lang="less">
.app-discount {
  width: 100%;
  min-height: 1.067rem;
  padding: 0 .32rem;
  margin-bottom: .32rem;
  display: flex;
  justify-content: space-between;
  background-color: #fff6f3;
  color: #FA6338;

  [class*="iconfont"] {
    font-size: 16px;
  }

  .icon-app-only {
    display: inline-block;
    color: #FA6338;
  }

  >div {
    max-width: 80%;
    display: flex;
    align-items: center;
  }

  &_desc {
    line-height: 18px;
  }

  >button {
    background: inherit;
    color: inherit;
    border: none;
  }
}
</style>
