<script lang="jsx">
import { Icon } from '@shein-aidc/icon-vue3'
import analysisInstance from '../utils/useAnalysisSdk.js'
const { vTap, vExpose } = analysisInstance || {}
/**
 * @props
 *  @param index
 *  @param label
 */
export default {
  name: 'SizeSelectRelatedLabel',
  functional: true,
  directives: {
    expose: vExpose,
    tap: vTap,
  },
  props: {
    index: {
      type: Number,
      default: 0,
    },
    label: {
      type: Object,
      default: () => ({}),
    },
    config: {
      type: Object,
      default: () => ({}),
    },
    sizeConstant: {
      type: Object,
      default: () => ({}),
    },
    handleCalcSize: {
      type: Function,
      default: () => {},
    },
    handlerOpenAdd: {
      type: Function,
      default: () => {},
    },
  },
  render(props) {
    const {
      // index,
      label,
      config,
      sizeConstant,
      handleCalcSize,
    } = props
    const {
      goods_id,
      size_gather_tag,
      attr_value_name_end,
      attr_value_name_start,
    } = label || {}
    const { cssRight } = sizeConstant || {}
    const key = `${goods_id}_${size_gather_tag}`

    const leftLabel = handleCalcSize({ attr_value_id: `${key}_start`, attr_value_name: attr_value_name_start }, { isFromRelation: true })
    const rightLabel = handleCalcSize({ attr_value_id: `${key}_end`, attr_value_name: attr_value_name_end }, { isFromRelation: true }) || {}
    const textArr = [leftLabel?.renderText || '', rightLabel?.renderText || ''].filter(i => !!i)

    const renderLabelDom = (item) => {
      const { mainSize, subSize, isWeakSubDefaultSize } = item || {}
      const showSubSize = mainSize && subSize
      return <span>
        { mainSize }
        { showSubSize ? (isWeakSubDefaultSize ? <span style="color: #959595; font-size: 12px;">{` ${subSize}`}</span> : ` ${subSize}`) : '' }
      </span>
    }

    const renderTextDom = () => {
      const leftLabelDom = renderLabelDom(leftLabel)
      const rightLabelDom = renderLabelDom(rightLabel)
      return <span>
        { leftLabelDom }
        { (leftLabelDom && rightLabelDom) ? ' - ' : '' }
        { rightLabelDom }
      </span>
    }

    const iconStyle = {
      position: 'relative',
      top: '-1px',
      transform: config?.from != 'detailPage' ? (`rotate(${cssRight ? '90deg' : '-90deg'})`) : 'rotate(0deg)',
    }

    return (
      <li
        v-expose={{ id: 'expose_moresize.comp_sub-sales-attr', prefix: 'comp_sub-sales-attr' }}
        v-tap={{
          id: 'click_moresize.comp_sub-sales-attr',
        }}
        class={{
          'goods-size__relate-good': true,
          'goods-size__sizes-item': true,
          'goods-size__relate-gather-tag_1': size_gather_tag === '1',
          'goods-size__relate-gather-tag_2': size_gather_tag === '2',
        }}
        role="text"
        aria-label={textArr.join(' - ')}
        onClick={() => props.handlerOpenAdd(label)}
      >
        { renderTextDom() }
        <Icon
          name="sui_icon_more_s_triangle_down_12px"
          size="12px"
          style={iconStyle}
        />
      </li>
    )
  },
}
</script>
