import { DS_QuickAddCartStandard } from '@shein-aidc/bs-quick-add-cart-standard-mobile'
import { DefaultPrice } from './defaultPrice.tsx'

export const PricePlugin = (price: string): DS_QuickAddCartStandard.QuickAddCartPlugin => {
  return {
    name: 'PricePlugin',
    renderPrice: () => {
        return DefaultPrice({ price })
    },
  }
}
