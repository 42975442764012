<template>
  <div
    class="bs-main-sales-attr__tag"
    :class="tagInfo?.class"
  >
    <!-- 文本类型 -->
    <span
      v-if="tagInfo?.type ==='text'"
      :style="tagInfo?.tagStyle"
    >
      {{ tagInfo?.value }}
    </span>
    <!-- 图片类型 -->
    <img
      v-if="tagInfo?.type ==='image'"
      :style="tagInfo?.tagStyle"
      :src="tagInfo?.value"
    />
    <!-- Icon 组件类型 -->
    <Icon
      v-if="tagInfo?.type ==='icon'"
      :style="tagInfo?.tagStyle"
      :name="tagInfo?.value"
      :size="tagInfo?.size || '12px'"
      is-responsive-name
      :color="tagInfo?.color || '#fafafa'"
    />
  </div>
</template>

<script name="BMainSalesAttrRightTag" setup lang="ts">
import { Icon } from '@shein-aidc/icon-vue3'
import { type C_MainSalesAttr } from '../../types'

interface MainSalesAttrRightTagProps {
  tagInfo: C_MainSalesAttr.MainSaleAttrTag,
}
withDefaults(defineProps<MainSalesAttrRightTagProps>(), {})

</script>

<style lang="less" scoped>
@vw: 375/100vw;

.bs-main-sales-attr {
  &__tag {
    position: absolute;
    top: 0;
    right: 0;
    line-height: 1;
  }
}
</style>
