<template>
  <div
    id="quickPriceId"
    class="quickPrice">
    <template v-if="pricePrefixInfo.isShow">
      <PricePrefix
        :value="pricePrefixInfo.value"
        :style="{ color: mainPriceInfo.color.font_color }"
      />
      <div class="quickPrice__gap-4px"></div>
    </template>
    <div
      id="quickMainPriceId"
      class="quickPrice__main"
      :style="{ color: mainPriceInfo.color.font_color }"
      v-html="mainPriceInfo.valueHtml"
    >
    </div>

    <template v-if="unitPriceInfo">
      <div class="quickPrice__gap-4px"></div>
      <UnitPrice :unit-price-info="unitPriceInfo" />
    </template>

    <template v-if="exclusiveInfo && exclusiveInfo.isS3newpriceAbtNew">
      <div class="quickPrice__gap-2px"></div>
      <S3TagNew
        :style="calcS3TagNewStyle"
        data-graph-type />
    </template>

    <div class="quickPrice__gap-4px"></div>

    <template v-if="discountAmountInfo && !!discountAmountInfo.discountValue">
      <DiscountAmount
        data-graph-type
        :discount-amount-info="discountAmountInfo"
      />
      <div class="quickPrice__gap-4px"></div>
    </template>

    <!-- 合规站点 法国德国 RRP  -->
    <template v-if="recommendRetailPriceFrDe">
      <RecommendRetailPriceFrDe
        :assign-style="bigSmallWordSizeDiscountStyle"
        :recommend-retail-price-fr-de="recommendRetailPriceFrDe"
        :discount-amount-info="discountAmountInfo"
        :config="config"
      />
    </template>

    <!-- 非合规站点 discount info  -->
    <DiscountInfo
      v-if="discountInfo.show"
      id="quickDiscountInfoId"
      :assign-style="bigSmallWordSizeDiscountStyle"
      :retail-price="discountInfo.retailPrice"
      :discount-percent="discountInfo.discountPercent"
      :discount-amount-info="discountAmountInfo"
      :assign-retail-style="discountInfo.retailPriceStyle"
      :discount-percent-assign-style="discountInfo.discountPercentStyle"
      :countdown-info="countdownInfo"
      :main-price-color="mainPriceInfo.color"
      :is-wrap="discountInfoIsWrap"
      :style="{
        ...graphTypeBeforeStyle
      }"
      :config="config"
    />

    <!-- 合规站点 discount info  -->
    <template v-if="suggestedSalePriceInfo.show">

      <!-- 法国fr -->
      <DiscountInfo
        v-if="suggestedSalePriceInfo.fr.show"
        :assign-style="bigSmallWordSizeDiscountStyle"
        :assign-retail-style="showBrandDeals ? discountInfo.retailPriceStyle : {'font-size': '11px'}"
        :retail-price="suggestedSalePriceInfo.fr.value"
        :discount-percent="suggestedSalePriceInfo.fr.discountPercent"
        :discount-amount-info="discountAmountInfo"
        :discount-percent-assign-style="discountInfo.discountPercentStyle"
        :style="graphTypeBeforeStyle"
        :config="config"
      />
      <!-- 波兰pl -->
      <DiscountInfoPl
        v-if="suggestedSalePriceInfo.pl.show"
        :assign-style="bigSmallWordSizeDiscountStyle"
        :retail-price="suggestedSalePriceInfo.pl.value"
        :discount-percent="suggestedSalePriceInfo.pl.discountPercent"
        :discount-amount="suggestedSalePriceInfo.discountAmount"
        :discount-amount-info="discountAmountInfo"
        :discount-percent-assign-style="discountInfo.discountPercentStyle"
        :retail-price-tip="suggestedSalePriceInfo.pl.tip"
        :config="config"
      />
      <!-- 德国de -->
      <template v-if="suggestedSalePriceInfo.de.show">
        <DiscountInfoDe
          v-if="suggestedSalePriceInfo.de.retailPriceShow"
          :assign-style="bigSmallWordSizeDiscountStyle"
          :retail-price="suggestedSalePriceInfo.de.retailPrice"
          :discount-percent="suggestedSalePriceInfo.de.retailPriceDiscountPercent"
          :discount-amount="suggestedSalePriceInfo.discountAmount"
          :discount-amount-info="discountAmountInfo"
          :discount-percent-assign-style="discountInfo.discountPercentStyle"
          :retail-price-tip="suggestedSalePriceInfo.de.retailTip"
          :config="{
            ...config,
            priceType: 'retailPriceSale'
          }"
          :style="graphTypeBeforeStyle"
        />
        <DiscountInfoDe
          :assign-style="{
            width: suggestedSalePriceInfo.de.retailPriceShow ? '9rem' : '',
            color: config.priceStyleType.showBrandDeals ? '#fff' : '#666',
            'margin-top': suggestedSalePriceInfo.de.retailPriceShow ? '.1067rem' : '',
          }"
          :retail-price="suggestedSalePriceInfo.de.value"
          :discount-percent="suggestedSalePriceInfo.de.discountPercent "
          :discount-amount="suggestedSalePriceInfo.discountAmount"
          :discount-amount-info="discountAmountInfo"
          :discount-percent-assign-style="discountInfo.discountPercentStyle"
          :retail-price-tip="suggestedSalePriceInfo.de.valueTip"
          :config="config"
          data-no-insert="1"
        />
      </template>
    </template>

    <template v-if="recommendRetailPrice">
      <div class="quickPrice__gap-4px"></div>
      <RecommendRetailPrice
        :is-floor-type="isFloorType"
        :recommend-retail-price="recommendRetailPrice"
        :discount-amount-info="discountAmountInfo"
        :config="config"
      />
    </template>

    <div class="quickPrice__gap-4px"></div>

    <S3Tag
      v-if="exclusiveInfo && !exclusiveInfo.isS3newpriceAbtNew"
      data-graph-type
      :exclusive-info="exclusiveInfo" />

    <template v-if="estimatedInfo">
      <EstimatedTag
        id="quickEstimatedTagId"
        :estimated-info="estimatedInfo"
        :is-wrap="estiamtedTagIsWrap"
        :countdown-info="countdownInfo"
        data-graph-type
        :config="estimatedConfig"
        @clickEstimatedTag="emits('clickEstimatedTag')"
      />
      <template v-if="estimatedInfo.retailPrice">
        <div
          v-if="!(discountAmountInfo && !!discountAmountInfo.discountValue)"
          class="quickPrice__gap-4px"
        ></div>
        <EstimatedTagNewRetail
          v-if="estimatedInfo.retailPrice"
          :estimated-info="estimatedInfo"
          @clickEstimatedTag="emits('clickEstimatedTag')"
        />
      </template>
    </template>
  </div>
</template>

<script setup name="Price">
/**
 * @components Price 组件不引用 vuex，纯组件
*/
import { computed, onMounted, defineProps, defineAsyncComponent, inject, toRefs } from 'vue'
import useCalcDomFn from '../../../hook/calcDomFn'

const PricePrefix = defineAsyncComponent(() => import('./ui/PricePrefix.vue'))
const UnitPrice = defineAsyncComponent(() => import('./components/UnitPrice.vue'))
const S3Tag = defineAsyncComponent(() => import('./components/S3Tag.vue'))
const S3TagNew = defineAsyncComponent(() => import('./components/S3TagNew.vue'))
const EstimatedTag = defineAsyncComponent(() => import('./components/EstimatedTag/index.vue'))
const EstimatedTagNewRetail = defineAsyncComponent(() => import('./components/EstimatedTag/components/EstimatedTagNewRetail.vue'))
const DiscountInfo = defineAsyncComponent(() => import('./ui/discountInfo/DiscountInfo.vue'))
const DiscountInfoPl = defineAsyncComponent(() => import('./ui/discountInfo/DiscountInfoPl.vue'))
const DiscountInfoDe = defineAsyncComponent(() => import('./ui/discountInfo/DiscountInfoDe.vue'))
const RecommendRetailPrice = defineAsyncComponent(() => import('./ui/RecommendRetailPrice.vue'))
const RecommendRetailPriceFrDe = defineAsyncComponent(() => import('./ui/RecommendRetailPriceFrDe.vue'))
const DiscountAmount = defineAsyncComponent(() => import('./ui/DiscountAmount.vue'))

const { triggerNotice } = inject('analysisInstance')


const props = defineProps({
  pricePrefixInfo: {
    type: Object,
    default: () => ({}),
  },
  countdownInfo: {
    type: Object,
    default: () => ({}),
  },
  mainPriceInfo: {
    type: Object,
    default: () => ({}),
  },
  discountInfo: {
    type: Object,
    default: () => ({}),
  },
  discountAmountInfo: {
    type: Object,
    default: () => ({}),
  },
  estimatedInfo: {
    type: Object,
    default: () => ({}),
  },
  suggestedSalePriceInfo: {
    type: Object,
    default: () => ({}),
  },
  recommendRetailPrice: {
    type: Object,
    default: () => ({}),
  },
  recommendRetailPriceFrDe: {
    type: Object,
    default: () => ({}),
  },
  exclusiveInfo: {
    type: Object,
    default: () => ({}),
  },
  unitPriceInfo: {
    type: Object,
    default: () => ({}),
  },
  config: {
    type: Object,
    default: () => ({
      scene: 'MAIN',
      goodsSn: '',
      priceStyleType: {
        showBrandDeals: false,
      },
    }),
  },
})

const {
  discountAmountInfo,
  discountInfo,
  suggestedSalePriceInfo,
  recommendRetailPrice,
  recommendRetailPriceFrDe,
  config,
  estimatedInfo,
} = toRefs(props)

const emits = defineEmits(['clickEstimatedTag'])

const isSheinappwordsizeNew = computed(() => props.mainPriceInfo.isSheinappwordsizeNew)

const showBrandDeals = computed(() => config.value.priceStyleType.showBrandDeals)

const bigSmallWordSizeDiscountStyle = computed(() => {
  return {
    // 'align-items': isSheinappwordsizeNew.value ? 'flex-end' : 'center',
    'transform': isSheinappwordsizeNew.value ? 'translateY(-.0267rem)' : '',
    color: showBrandDeals.value ? 'rgba(255, 255, 255, 0.80)' : '#767676',
  }
})

const calcS3TagNewStyle = computed(() => {
  if (props.unitPriceInfo) {
    return {
      transform: 'translateY(.0533rem)',
    }
  }
  return {
    transform: 'translateY(-.0533rem)',
  }
})

const estimatedConfig = computed(() => {
  const { isHitComplianceMode, discountValue } = discountAmountInfo.value || {}
  // 到手价 && 百补活动 && 非合规站点屏蔽折扣率
  if (estimatedInfo.value && showBrandDeals.value && !isHitComplianceMode) {
    return {
      hideDiscountPercent: discountValue?.type === 'estimatedPrice',
    }
  }
  return {}
})


const graphTypeBeforeStyle = computed(() => {
  const scenes = [
    !!showBrandDeals.value,
    !!(props.exclusiveInfo && props.exclusiveInfo.isS3newpriceAbtNew),
  ]
  if (scenes.includes(true)) {
    return {
      'align-self': 'center',
    }
  }
  return null
})

const SCENE_MAP = {
  MAIN: 'MAIN', // 主价格
  FLOOR: 'FLOOR', // 悬浮
  SIMPLE: 'SIMPLE', // 简易
}

const isFloorType = computed(() => props.config.scene === SCENE_MAP.FLOOR)

onMounted(() => {
  if (props.suggestedSalePriceInfo.show && props.config.scene === SCENE_MAP.MAIN) {
    triggerNotice({ id: 'expose_legallowestprice.comp_quick-add-cart-standard' })
  }
})
const {
  discountInfoIsWrap,
  estiamtedTagIsWrap,
} = useCalcDomFn()

</script>

<style lang="less">
.quickPrice {
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  min-width: 0;
  align-items: baseline;

  &__from {
    line-height: 1;
  }

  &__main {
    font-weight: bold;
    direction: ltr /*rtl:ignore*/;
    color: #222;
    line-height: 1;
  }

  &__discount {
    display: flex;
    align-items: center;
    max-width: 100%;
  }

  &__estimated {
    display: flex;
  }

  &__gap-2px {
    height: .32rem;
    width: .0533rem;
  }

  &__gap-4px {
    height: .32rem;
    width: .1067rem;
  }
}
</style>
