<template>
  <div
    class="quickRecommendRetailPriceFrDe"
    :style="assignStyle"
  >
    <p
      class="quickRecommendRetailPriceFrDe__value"
      :style="{ color: recommendRetailPriceFrDeInfo.textColor}"
    >
      {{ recommendRetailPriceFrDeInfo.descText }} <span class="quickRecommendRetailPriceFrDe__value-delete">{{ recommendRetailPriceFrDeInfo.value }}</span>
    </p>
    <DiscountPercent
      v-if="recommendRetailPriceFrDeInfo.discountPercent"
      :value="recommendRetailPriceFrDeInfo.discountPercent"
      :style-map="recommendRetailPriceFrDeInfo.discountPercentStyle"
    />
  </div>
</template>

<script setup name="RecommendRetailPriceFrDe">
import { watch, inject, computed, toRefs } from 'vue'
import { template } from '@shein/common-function'
import DiscountPercent from './discountInfo/DiscountPercent.vue'
const { triggerNotice } = inject('analysisInstance')

const props = defineProps({
  recommendRetailPriceFrDe: {
    type: Object,
    default: () => ({}),
  },
  discountAmountInfo: {
    type: Object,
    default: () => ({}),
  },
  config: {
    type: Object,
    default: () => ({}),
  },
  assignStyle: {
    type: Object,
    default: () => ({}),
  },
})

const { discountAmountInfo, recommendRetailPriceFrDe, config } = toRefs(props)

/**
 * 法国&德国 RRP 数据/样式自定义
 */
 const recommendRetailPriceFrDeInfo = computed(() => {
  return {
    ...recommendRetailPriceFrDe.value,
    ...getRrpDataForFrDe(discountAmountInfo.value, recommendRetailPriceFrDe.value, config.value),
  }
})

/**
 * 法国&德国 RRP 真的折扣金额自定义数据/样式
 */
const getRrpDataForFrDe= (discountAmountInfo, recommendRetailPriceInfo, config) =>{
  const { discountValue = null, showDiscountPercent = true, saveLanguageKey } = discountAmountInfo || {}
  const { discountPercent, discountAmount } = recommendRetailPriceInfo || {}
  const { priceStyleType = {} } = config || {}

  const _discountAmount = discountAmount ? template(discountAmount, saveLanguageKey || 'Save {0}') : ''
  const showValue = showDiscountPercent ? discountPercent : _discountAmount

  return {
    ...(priceStyleType?.showBrandDeals ? {
      // 百补数据处理
      discountPercent: discountValue?.type === 'rrpRetailPrice' ? '' : showValue,
    } : {}),
  }
}

watch(() => props.recommendRetailPriceFrDe.skuCode, (val) => {
  if (!val) return
  triggerNotice({
    id: 'expose_sku_recommend_retail_price.comp_quick-add-cart-standard',
    extraData: {
      recommend_retail_price: props.recommendRetailPriceFrDe.usdAmountWithSymbol,
      location: 'popup',
    },
  })
}, {
  immediate: true,
})
</script>

<style lang="less">
.quickRecommendRetailPriceFrDe {
  display: flex;
  align-items: center;
  transform: translateY(-0.0267rem);
  height: .48rem;

  font-family: SF Pro;
  font-size: .2933rem;
  font-weight: 400;
  line-height: 1;

  &__value {
    margin-right: .0533rem;
  }

  &__value-delete {
    text-decoration: line-through;
  }

}
</style>
